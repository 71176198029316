@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;

  .message {
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .subMessage {
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }
}
