@import '/src/styles/mixins';

.noDataBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  padding: 60px 0;
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .subTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
