.investText {
  height: 32px;
  display: flex;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  padding-top: 30px;
}
.investDiv {
  max-width: 1020px;
  padding: 20px 60px;
  border-radius: 12px;
  background: white;
  @media screen and (max-width: 540px) {
    padding: 20px 34px;
  }
  @media screen and (max-width: 400px) {
    padding: 20px 20px;
  }
}
.investColumnLeft {
  width: 440px;
  height: 48px;
}
.investColumnRight {
  width: 440px;
  height: 176px;
}
