@import '/src/styles/mixins';

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  .subtitle {
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .modalButtons {
    display: flex;
    justify-content: space-between;

    .saveBtnEdit {
      @include for-mobile-max_620 {
        display: none;
      }
    }
  }

  .documentsServiceSelect {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 48px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
    color: #757575;
  }

  .area {
    resize: vertical;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat';
    height: 73px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #efefef;
    padding-top: 20px;
    padding-left: 10px;
  }
}

.textField {
  position: relative;
  width: 100%;

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 16px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
    }
  }
}
