@import '../../../../../styles/mixins';

.container,
.orderContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .deleteItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .deleteBtn {
      display: flex;
      gap: 2px;
      text-transform: none;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 0 !important;
      span {
        display: none;
      }
    }
  }
  .product {
    width: 100%;
    display: flex;
    gap: 20px;

    @include for-tablet-landscape-max_1024 {
      gap: 16px;
    }

    .productImage {
      border-radius: 12px;
      width: 90px;
      height: 90px;
      position: relative;
      cursor: pointer;

      @media only screen and (max-width: 460px) {
        max-width: 100%;
      }

      > img {
        object-fit: cover;
        background-size: cover;
        width: 148px;
        height: 148px;
        border-radius: 12px;
      }

      .favorites {
        position: absolute;
        top: 11.5px;
        right: 11.5px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        padding: 10px;
        max-height: 40px;
        cursor: pointer;
      }
    }

    .productInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;

      .productName {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-width: 115px;
        cursor: pointer;

        .mobileDelete {
          position: absolute;
          right: 20px;
        }

        .title {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          word-wrap: anywhere;
        }

        .parameter {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .productPriceOptions {
        display: flex;
        column-gap: 80px;
        row-gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        @include for-mobile-max_620 {
          column-gap: 20px;
        }
        .optionItem {
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          justify-content: center;

          .discountPrice {
            display: flex;
            align-items: center;
            gap: 5px;

            .optionValueOldPrice {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              color: red;
              text-decoration: line-through;
              text-decoration-color: red;
            }
          }

          @include for-mobile-max_620 {
            flex-direction: row;
            column-gap: 8px;
            align-items: center;
          }

          .optionName {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          .optionValue {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
}
.orderContainer {
  gap: 16px;
  background-color: #ffffff;
  border-radius: 12px;

  .product {
    align-items: center;
  }

  .companyInfo {
    display: flex;
    align-items: center;
    gap: 8px;

    .companyName {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
  }
}
