.cartItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  .cartItemInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
      width: 40px;
      height: 40px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 12px;
    }
    > p {
      font-size: 16px;
      font-weight: 500;
      color: #212121;
    }
    p:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 210px; 
      @media (max-width: 1580px) {
      max-width: 160px;
      }
    }
  }
  > p{
    font-size: 16px;
    font-weight: 500;
    color: #757575;
  }
 
}
