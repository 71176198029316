.cardNumber {
  padding: 14px 20px 10px 20px;
  border-radius: 12px;
  border: 1px solid #efefef;
  width: 100%;
  min-height: 48px;
  font-family: 'Montserrat' !important;
}

.inputsRow {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardNumber::placeholder {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: red !important;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
