.searchBlock {
  width: 100%;
  position: relative;
}
.searchFilter {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
}
.searchFilter {
  width: 100%;
  gap: 12px;
  padding: 7px 20px;

  div {
    border-radius: 0 !important;
    border: 0 !important;
  }
  div:hover {
    box-shadow: none !important;
  }
}
.shoppingCart {
  width: fit-content;
  cursor: pointer;
  padding: 11px 17.4px;
  gap: 4px;
}
.dropdownSearch {
  background-color: #fff;
  position: absolute;
  width: 100%;
  border-radius: 12px;
  top: 52px;
  padding: 12px 0px;
  z-index: 1001;
  gap: 12px;
  .loading {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .typeList {
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 12px;
    padding: 2px 10px;
    width: fit-content;
    margin-left: 20px;
    margin-bottom: 6px;
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .line {
    height: 1px;
    width: 93%;
    margin: 0px 20px;
    background-color: rgba(239, 239, 239, 1);
    margin-bottom: 12px;
    margin-top: 6px;
  }
  .searchRow {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 20px;
    &:hover {
      background-color: rgba(128, 202, 255, 0.1);
    }
    .searchItem {
      display: flex;
      gap: 12px;
      .searchTitle {
        font-size: 18px;
        font-weight: 500;
      }
      .searchSubtitle {
        font-size: 14px;
        font-weight: 400;
        color: rgba(117, 117, 117, 1);
      }
    }
    .arrowAsIcon {
      width: 22px;
      height: 22px;
      svg > path {
        fill: rgba(196, 196, 196, 1) !important;
        stroke: rgba(196, 196, 196, 1) !important;
      }
    }
    .active {
      background-color: rgba(128, 202, 255, 0.1);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.highlightedInput {
  position: relative;
  z-index: 1001;
}
