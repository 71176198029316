.pageContentBox {
  display: flex;
  flex-direction: column;
  max-width: 1020px;
  width: 100%;
  margin-bottom: 106px;
  position: relative;

  .productContent {
    background: #ffffff;
    border-radius: 12px;
    position: relative;

    .deletedUser {
      background: #fff;
      border-radius: 12px;
      padding: 24px 0px;
      display: flex;
      justify-content: center;
    }

    .deletedText {
      p {
        &:last-child {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          a {
            color: #143471;
            text-decoration: underline;
          }
        }
        &:first-child {
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }
}
