@import '../../../../../styles/mixins';

.deliveryContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .delivery {
    .deliveryTitle {
      display: flex;
      align-items: center;
      column-gap: 14.25px;

      .title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
      }
    }

    .deliveryItems {
      display: flex;
      flex-direction: column;
      column-gap: 8px;
      margin-top: 12px;
      padding-left: 36px;

      .deliveryItem {
        display: flex;
        align-items: flex-start;
        column-gap: 11.5px;

        img {
          filter: invert(31%) sepia(0%) saturate(1743%) hue-rotate(125deg) brightness(97%)
            contrast(86%);
        }
      }
      .pickup {
        border: 1px solid #efefef;
        border-radius: 12px;
        margin-bottom: 10px;
        padding: 12px;
        .pickupTitle {
          display: flex;
          align-items: center;
          column-gap: 14.25px;
          cursor: pointer;
          .title {
            font-family: 'Montserrat' !important;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #212121;

            @media (max-width: 800px) {
              font-size: 14px !important;
            }
          }
          .pickupIcon {
            @media (max-width: 600px) {
              display: none;
            }
          }
        }

        .pickupActions {
          display: flex;
          align-items: center;
          gap: 20px;
          cursor: pointer;
        }
        .pickupInfo {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          gap: 40px;

          .infoItem {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            .info {
              display: flex;
              column-gap: 8px;

              .contact {
                display: flex;
                column-gap: 12px;
                flex-wrap: wrap;
                word-break: break-all;
              }

              .shippingConst {
                display: flex;
                gap: 4px;

                p {
                  font-size: 14px;
                  font-family: 'Montserrat' !important;
                  line-height: 24px;
                  font-weight: 400;
                  white-space: nowrap;
                }
              }
            }
            .description {
              font-size: 14px;
              font-family: 'Montserrat' !important;
              line-height: 24px;
              font-weight: 400;
            }
            @media (max-width: 800px) {
              padding-left: 0;
            }
          }
          @media (max-width: 800px) {
            flex-direction: column;
            gap: 12px;
          }
        }
      }

      .deliveryItemText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #555555;
      }
    }
  }
}
