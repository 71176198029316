@import '../../../styles/_mixins.scss';

.middleContent {
  max-width: 1020px;
  display: flex;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;
  padding: 0;
  gap: 20px;
  margin-bottom: 20px;

  @media only screen and (max-width: 750px) {
    flex-wrap: wrap;
  }

  .marketPlaceItem {
    max-height: 312px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    gap: 77px;
    padding: 82px 0;
    width: 100%;
    position: relative;
    cursor: pointer;

    @media only screen and (max-width: 1668px) {
      max-height: 282px;
      gap: 10px;
    }
    @include for-tablet-landscape-max_1024 {
      gap: 57px;
    }
    @media only screen and (max-width: 545px) {
      min-width: 327px;
      gap: 4px;
    }

    .marketPlaceItemContent {
      min-width: 281px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .subTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #757575;
      }
      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #212121;
      }

      @media only screen and (max-width: 545px) {
        min-width: 230px;
        gap: 9px;
        .title {
          font-size: 20px;
        }
        .subTitle {
          font-size: 16px;
        }
      }
    }

    .arrow {
      position: absolute;
      right: 31.5px;
    }
  }
}
