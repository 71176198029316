@import '../../../../../styles/mixins';

.tabContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;
    &:last-of-type {
      border-bottom: none;
    }
    .parameters {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;

      span {
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-left: 12px;
      }
    }
    .url {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #18397a;
      text-decoration-line: underline;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 300px;
      white-space: nowrap;

      @media screen and (max-width: 410px) {
        width: 100%;
      }
    }

    .address {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #212121;
      margin-bottom: 12px;
    }

    .description {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      word-break: break-all;
    }

    .goalBlocks {
      display: flex;

      @media screen and (max-width: 630px) {
        display: block;
        .columnGroup {
          margin-bottom: 10px;
          width: 100%;
          .goalBlock {
            max-width: 100%;
          }
        }
      }
    }

    .columnGroup {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      column-gap: 20px;
      flex-wrap: wrap;
      overflow-x: auto;
      width: 350px;
    }
  }
}
