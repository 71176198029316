.row {
  display: flex;
  height: 30px;
  width: 900;
  border: 1px solid;
}
.leftColumn {
  width: 180px;
  height: 30px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat';
}
.rightColumn {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
  margin-bottom: 12px;
}
.rightColumnFirst {
  padding-top: 5px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
  margin-bottom: 12px;
}
.esg {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat';
  color: #1f4a9d;
  width: 180px;
  height: 30px;
  text-decoration: underline;
  text-underline-offset: 5px;
}
