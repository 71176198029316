@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }

  .textField {
    position: relative;
    width: 100%;
    @include for-mobile-max_767 {
      // width: 90% !important ;
    }
    &[data-type='location'] {
      cursor: pointer !important;
    }
    &[data-type='location'] > label[data-shrink='false'] {
      transform: translate(56px, 14px) scale(1) !important;
    }
    & > label {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      background-color: #ffffff;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }
    & > div {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }
    & > div > input {
      padding: 12.5px 16px;
    }
    & > div > fieldset {
      border-radius: 16px;
      border: 1px solid #efefef;
      & > legend {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .aboutTextField {
    margin-top: 10px;
  }
  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }

  .content,
  .serviceContent {
    margin-top: 12px;
  }
  .serviceContent {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include for-tablet-landscape-max_1024 {
      padding-right: 10px;
    }
  }
  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
    display: flex;

    .fullTitle {
      display: block;
      @include for-mobile-max_620 {
        display: none;
      }
    }
  }
  .titleWithBtn {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .subTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 12px;

    &::first-letter {
      text-transform: uppercase;
    }

    @include for-mobile-max_767 {
      font-size: 14px;
    }

    span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      margin-left: 20px;
    }
  }
  .videoSubtitle,
  .videoSubtitleEditing {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subTitle {
      margin-bottom: 0;
    }
  }
  .videoSubtitleEditing {
    border-bottom: 1px solid #efefef;
    padding-bottom: 12px;
    margin-top: 24px;
  }
  .description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    word-break: break-all;
  }

  .generalInfo {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    @include for-mobile-max_620 {
      gap: 12px;
    }

    .generalInfoItem {
      display: flex;
      align-items: center;
      gap: 20px;
      @include for-mobile-max_620 {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      .infoTitle,
      .infoValue {
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .infoValue {
        font-weight: 400;
      }
    }
  }

  .textarea {
    width: 100% !important;
    height: 72px !important;
    padding-left: 20px !important;
    padding-top: 12px !important;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }

  .goalDescription {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    overflow-wrap: anywhere;
    display: -webkit-box;

    @include for-mobile-max_620 {
      font-size: 14px;
    }
  }
  .editButton {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 33px;
    right: 65px;
    cursor: pointer;

    @include for-tablet-landscape-max_1024 {
      right: 45px;
    }
    .icon {
      svg {
        width: 24px;
        height: 24px;

        path {
          fill: #212121 !important;
        }
      }
    }

    @include for-mobile-max_620 {
      top: 25px;
      right: 25px;
    }
  }

  .editServiceButton {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .editServiceButtonBlock {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 20px;

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .editing {
    display: flex;
    align-items: center;
    column-gap: 16.8px;

    p {
      padding: 0;
      margin: 0;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: right;
      text-transform: uppercase;
      color: #18397a;
      cursor: pointer;
    }
  }
  .sdgsBlocks {
    &:not(:first-child) {
      border-top: 1px solid #efefef;
      padding-top: 12px;
    }

    @include for-mobile-max_620 {
      &:not(:first-child) {
        padding-top: 11px;
      }
    }
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      margin-right: 20px;

      @include for-mobile-max_620 {
        width: 93px !important;
        height: 93px !important;
      }
    }
  }
  .more {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #212121;
    cursor: pointer;
  }

  .tagsBlock {
    display: flex;
    align-items: center;
    gap: 12px;
    overflow-x: auto;
    padding-bottom: 10px;
  }
  .chip {
    background: #efefef !important;
    border-radius: 12px !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    border: none;
    color: #212121;
  }

  .certificateName {
    text-align: center;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    @include for-mobile-max_620 {
      font-size: 14px;
    }
  }

  .certificates {
    margin-top: 16px;
  }

  .certificatesEditing {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    .certificatesList {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .docItem {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .docNameBlock {
          display: flex;
          gap: 8px;
          align-items: center;

          .docImg {
            display: flex;
            align-items: center;
            svg > path {
              fill: #4caf50;
            }
          }

          .docName {
            font-family: 'Montserrat';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            max-width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @media (max-width: 900px) {
              width: 134px;
            }
          }
        }

        .docActions {
          display: flex;
          gap: 12px;
          align-items: center;

          .fileSize {
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: #757575;
          }
          .lockOpenIcon {
            margin-left: 12px;
            display: flex;
            align-items: center;
          }
          .removeFile {
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.addServiceButton {
  gap: 12px;
  position: absolute;
  right: 60px;
  @include for-tablet-landscape-max_1024 {
    right: 40px;
    padding: 10px !important;
  }
  @include for-mobile-max_620 {
    right: 20px;
    padding: 10px !important;
  }

  p {
    color: #18397a;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.documentService {
  width: 100%;
  padding-top: 30px;

  .documentsServiceRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
    }
  }

  .documentsServiceSelect {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 48px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
    color: #757575;
    padding-left: 10px;
  }
}

.area {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
  height: 73px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #efefef;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 10px;
}

.documentsAccountLeft {
  width: 100%;
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 48px;
    margin-top: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }

  .inputIcon {
    width: 100%;

    & > label {
      font-style: normal;
      font-weight: 400;
      background-color: #ffffff;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 16px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .errorText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #d32f2f;
    margin-top: 5px;
  }
}

.communityBlock {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 23px;
  margin-bottom: 40px;
  max-width: 44%;
  img {
    width: 128px;
    height: 128px;
  }

  @include for-mobile-max_480 {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.sdgItemInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 176px;

  @media only screen and (max-width: 430px) {
    min-width: auto;
  }
}

.communityTextarea {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
  resize: none;
  height: 72px;
  margin-top: 8px;
  padding: 12px;

  @include for-mobile-max_480 {
    width: 100% !important;
  }

  &::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
  }
}

.communityCheckbox {
  display: flex;
}

.communityContent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 0 110px;
}

.communityDialogTextarea {
  min-height: 72px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 20px 12px;
  width: 100%;
  &::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
  }
}

.errorText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d32f2f;
  margin-top: 5px;
}
.paginationBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  .pagination {
    display: flex;
    justify-content: center;
  }
  .paginationItem {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 15px !important;
    background: #ffffff !important;
    border: none !important;
    border-radius: 12px !important;
    color: #212121 !important;
  }

  .activePaginationItem {
    border: 1px solid #143471 !important;
    border-radius: 12px !important;
  }
  .paginationText {
    margin-top: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
}

.filesContainer {
  padding: 0 44px 20px 44px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 12px;

  &:last-child {
    border: none;
  }

  @include for-mobile-max_620 {
    padding: 0 24px 20px 24px;
  }
  .videosContainer,
  .presentationsContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    .videoItem,
    .presentationItem {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .videoName,
      .presentationName {
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .videoImage,
      .presentationImage {
        position: relative;
        border-radius: 4px;
        .videoPlayBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          path {
            fill: white;
          }
        }
      }
    }
  }
  .rightSlide,
  .leftSlide {
    height: 40px;
    width: 40px;
    position: absolute;
    z-index: 1;
    top: 45%;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .leftSlide {
    left: 0;
    display: none;
    @include for-mobile-max_620 {
      left: -12px;
    }
  }
  .rightSlide {
    right: 0;
    display: none;
    @include for-mobile-max_620 {
      right: -12px;
    }
  }
}

.noBorder {
  border: 0;
  margin-bottom: 0;
}

.editActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .cancelBtn {
    background-color: transparent !important;
  }
}
.addDocBtn {
  display: flex;
  align-items: center;
  gap: 12px;
  right: 60px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 20px;
  top: 20px;
  cursor: pointer;
  color: #18397a;
}

.addVideoBtn {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
