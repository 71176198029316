@import '/src/styles/mixins';

.modalContainer {
  max-width: 604px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .companyInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      flex-wrap: wrap;

      .ownerInfo {
        display: flex;
        align-items: center;
        gap: 8px;

        .ownerName {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }
      }
      .stars {
        display: flex;
        flex-direction: column;
        align-items: end;
        .errorText {
          color: red;
          font-size: 12px;
        }
      }
    }

    .textarea {
      position: relative;
      width: 100%;
      margin-top: 12px;

      @include for-mobile-max_767 {
        // width: 90% !important ;
      }

      &[data-type='location'] {
        cursor: pointer !important;
      }

      &[data-type='location'] > label[data-shrink='false'] {
        transform: translate(56px, 14px) scale(1) !important;
      }

      & > label {
        top: 0;
        font-style: normal;
        font-weight: 400;
        background-color: #ffffff;

        &[data-shrink='false'] {
          transform: translate(14px, 14px) scale(1);
        }
      }

      & > div {
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #212121;
      }

      & > div > input {
        padding: 12.5px 16px;
      }

      & > div > fieldset {
        border-radius: 12px;
        border: 1px solid #efefef;

        & > legend {
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
  .buttonsGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.modalContainer::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.modalContainer::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.modalContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}

.scrollContainer::-webkit-scrollbar {
  height: 5px;
}
.scrollContainer::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.scrollContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}
