@import '/src/styles/mixins';

.itemContainer {
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    cursor: pointer;

    .menuButton {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      padding: 0;
    }

    .orderNumberBold {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: #143471;
      word-wrap: break-word;
      overflow: hidden;
      margin-right: 15px;
    }

    .statusAndOptions {
      display: flex;
      align-items: center;
      gap: 20px;

      .status {
        padding: 5px 12px;
        border-radius: 12px;
        color: #fff;
        margin-right: 40px;
        line-height: 24px;
      }
    }
  }

  .orderOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;

    .optionColumn {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .optionRow {
        display: flex;
        align-items: center;
        gap: 8px;

        .optionTitle,
        .optionTitleBold {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .optionTitleBold,
        .optionValueBold {
          font-weight: 600 !important;
        }

        .optionValue,
        .optionValueBold {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  .scrollContainer {
    max-width: 420px;

    @media screen and (max-width: 1070px) {
      max-width: fit-content;
    }
  }

  .scrollContainer::-webkit-scrollbar {
    height: 5px;
  }
  .scrollContainer::-webkit-scrollbar-track {
    background-color: #d4d4d4;
  }
  .scrollContainer::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 8px;
  }

  .slider {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex-wrap: nowrap;
    padding-bottom: 12px;

    .sliderImage {
      width: 90px;
      height: 90px;
      min-width: 90px;
      border-radius: 12px;
      overflow: hidden;

      .image {
        height: 90px;
        width: 90px;
        border-radius: 12px;
        min-width: 90px;

        img {
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }
  }

  .productImages {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 110px;
    overflow-x: auto;
  }

  .productImages::-webkit-scrollbar {
    height: 5px;
  }
  .productImages::-webkit-scrollbar-track {
    background-color: #d4d4d4;
  }
  .productImages::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 8px;
  }

  .disputeBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 8px;
    column-gap: 8px;
    flex-wrap: wrap;

    .leftBlock {
      display: flex;
      align-items: center;
      row-gap: 8px;
      flex-wrap: wrap;
      column-gap: 20px;

      .orderNumber {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #143471;
      }

      .disputeBtn {
        border: 1px solid #d32f2f;
        border-radius: 12px;
        padding: 5px 12px;
        display: flex;
        align-items: center;
        gap: 10px;

        span:first-child {
          display: inline-grid !important;

          svg {
            height: 24px;
            width: 24px;

            g > path {
              fill: #d32f2f !important;
            }

            > path {
              fill: #d32f2f !important;
            }
          }
        }
        .text {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: #d32f2f;
        }
      }
    }

    .rightBlock {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .autoOrderButtons {
    display: flex;
    gap: 20px;
    padding-top: 20px;
    border-top: 1px solid #efefef;
  }
}

.optionsMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  ul {
    padding: 0;
  }
  .menuItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.timerBlock {
  flex-wrap: wrap;
  display: flex;
  gap: 12px;
  align-items: center;

  @include for-mobile-max_620 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
}

.warningInfoBlock {
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background-color: #efefef;
  color: #212121;
  margin: 6px 0;

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.title {
  color: #212121;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;

  @include for-mobile-max_620 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.16px;
  }
}
