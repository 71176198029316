.menuItemWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.delete {
  svg {
    path {
      fill: rgba(211, 47, 47, 1) !important;
      stroke: rgba(211, 47, 47, 1) !important;
    }
  }
}

.deleteText {
  color: rgba(211, 47, 47, 1);
}

.shareBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}
