@import '../../../../../styles/mixins';

.depositModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 4px 77px 0 rgba(0, 0, 0, 0.08);
  padding: 24px 40px;
  border-radius: 12px;
  backdrop-filter: blur(10px);

  @media screen and (max-width: 1800px) {
    width: 50%;
    overflow-y: auto;
  }

  @media screen and (max-width: 1600px) {
    width: 60%;
    overflow-y: auto;
  }

  @media screen and (max-width: 1200px) {
    width: 70%;
    overflow-y: auto;
  }

  @media screen and (max-width: 1024px) {
    width: 78%;
    overflow-y: auto;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow-y: auto;
  margin-top: 24px;

  @media screen and (max-width: 1040px) {
    max-height: 350px;
  }
}

.checkboxGroup {
  margin-top: 24px;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 32px;
  width: 100%;
}

.btnLoader {
  color: #f5f5f5 !important;
}
