@import '/src/styles/mixins';

.drawer {
  > div:nth-child(3) {
    border-radius: 12px 0 0 12px !important;
  }
}

.goals {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.goalFilterItem,
.goalFilterItemSelected {
  border-radius: 5px;
  cursor: pointer;
}

.goalFilterItemSelected {
  border: 2px solid rgba(20, 52, 113, 1);
}

.collapse {
  > div {
    border-bottom: none !important;
    padding: 0px !important;
    > div {
      padding: 12px 0px !important;
    }
  }
}

.CheckboxGroup {
  display: flex;
  flex-direction: column;
}

.filterModal {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @media only screen and (max-width: 475px) {
    width: 100%;
    border-radius: 0;
  }

  &:focus-visible {
    outline: none;
  }

  .modalHeader {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #212121;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
  }

  .modalBody {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-right: 8px;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #efefef;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 4px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 16px;

      .contentTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .filterSelect {
        border: 1px solid #efefef;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .textInputGroup {
        display: flex;
        gap: 20px;
        align-items: center;
      }

      .searchInputGroup {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
        padding: 7px 20px;
        gap: 12px;

        > div {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }

    .accordionItem {
      padding: 0;
      margin: 0;

      .contentTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .formGroup {
      max-height: 170px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #efefef;
      }

      &::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 4px;
      }

      .checkBoxGroup {
        max-height: 127px;
        overflow-x: hidden;
        overflow-y: auto;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background: #efefef;
        }

        &::-webkit-scrollbar-thumb {
          background: #c4c4c4;
          border-radius: 4px;
        }
      }
    }
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contentButton {
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;

      .contentButtonText {
        font-weight: 700;
        font-size: 16px;
        line-height: 48px;
        text-transform: capitalize;
      }
    }
  }
}

.textInput {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: unset !important;
    color: #212121 !important;
    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid;
    border-color: rgba(228, 219, 233, 0.25) !important;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.checkFilter {
  display: flex;
  justify-content: space-between;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}
