@import '/src/styles/mixins';

.mainInformation {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 35px 60px 24px 62px;
  width: 100%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 10px;

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }
  @include for-mobile-max_767 {
    padding: 20px;
  }
  @include for-mobile-max_620 {
    grid-template-columns: 1fr;
  }
}

.mainInformationSeller {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #ffffff;
  padding: 35px 60px 24px 62px;
  width: 100%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 10px;

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }
  @include for-mobile-max_767 {
    padding: 20px;
  }
  @include for-mobile-max_620 {
    grid-template-columns: 1fr;
  }
}
.leftContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  gap: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;

  @include for-mobile-max_767 {
    position: relative;
    padding-bottom: 15px;
    width: 100%;
  }
}
.rightBlock {
  display: flex;
  justify-content: flex-end;

  @include for-mobile-max_620 {
    justify-content: center;
  }
}
.rightContent {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include for-mobile-max_620 {
      flex-direction: row;
    }
  }
  .iconButtons {
    padding: 0 11px;
    display: flex;
    align-items: center;
    gap: 25px;
    @include for-mobile-max_620 {
      gap: 40px;
    }
    .messageIcon {
      display: none;
      @include for-mobile-max_620 {
        display: inline-block;
        padding-bottom: 0;
      }
    }
  }
}

.rightContentSeller {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  height: 100%;

  @include for-mobile-max_620 {
    align-items: center;
  }

  .sellerStoreActions {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-mobile-max_620 {
      width: 100%;
    }
    gap: 8px;
  }
}

.editBtn,
.messageBtn {
  white-space: nowrap;
  border: 1px solid #18397a;
  border-radius: 4px;
  padding-right: 14.5px;
  padding-left: 14.5px;
  background-color: #ffffff;
  height: fit-content;
  cursor: pointer;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #18397a;
}
.messageBtn {
  @include for-mobile-max_620 {
    display: none;
  }
}
.addProposalBtn,
.joinMarketBtn,
.myCompanyBtn {
  border: 1px solid #18397a;
  border-radius: 4px;
  padding-right: 14.5px;
  padding-left: 14.5px;
  background-color: #ffffff;
  height: fit-content;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #18397a;

  @include for-mobile-max_620 {
    width: 100%;
  }
}
.joinMarketBtn {
  background-color: #18397a;
  color: #ffffff;
}
.myCompanyBtn {
  border: none;
}
