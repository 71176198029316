@import '/src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 60px;
  background-color: #ffffff;
  border-radius: 12px;
  margin-top: 20px;

  @media (max-width: 1024px) {
    padding: 24px 40px;
    margin-top: 16px;
  }

  @include for-mobile-max_767 {
    margin-top: 12px;
    padding: 24px 20px;
  }

  .datePickerContainer {
    width: 47%;
    max-width: 370px;

    > div {
      width: 100% !important;
      padding: 0 !important;
    }

    @media (max-width: 900px) {
      max-width: 100%;
      width: 100%;
      & > div > div {
        width: 100%;
      }
    }

    @media (max-width: 400px) {
      max-width: 320px;
      width: 100%;
    }
  }

  .startDate {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    .dateOfPayment {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  .title,
  .titleCost {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #212121;
  }

  .titleCost {
    font-weight: 600;
  }

  .perfomanceOfService {
    display: flex;
    gap: 20px;
    align-items: baseline;

    .periodValue {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
    }

    .dateItems {
      font-size: 12px !important;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  .inputSelectHours {
    max-width: 426px !important;
    width: 100%;

    @include for-mobile-max_767 {
      max-width: unset !important;
    }
  }

  .inputSelect,
  .datePicker {
    max-width: 285px;

    @media (max-width: 900px) {
      max-width: 100%;
      width: 100%;
    }

    &[data-type='cost'] {
      cursor: pointer !important;
    }

    &[data-type='cost'] > label[data-shrink='false'] {
      transform: translate(20px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
        max-width: calc(100% - 100px);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .inputUnitText {
    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #212121;
  }

  .radioLabel {
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .radioLabelActive {
    color: #143471;
  }

  .collapsedInputs {
    display: flex;
    align-items: stretch;
    gap: 20px;
    margin-left: 32px;
    //
    //@include for-desktop-up-max_1440 {
    //  flex-wrap: wrap;
    //}

    @include for-mobile-max_767 {
      flex-direction: column;
      gap: 12px;
    }

    @include for-mobile-max_480 {
      margin-left: 0px;
    }
  }

  .priceCollapsedInputs {
    @include for-desktop-up-max_1300 {
      flex-wrap: wrap;
      .inputSelect {
        width: 100%;
        max-width: unset;
      }
    }

    @include for-tablet-landscape-max_1024 {
      flex-wrap: nowrap;
    }
    @include for-tablet-landscape-max_900 {
      flex-wrap: wrap;
    }

    .priceInputs {
      display: flex;
      gap: 20px;
      align-items: stretch;
      @include for-desktop-up-max_1300 {
        width: 100%;
        .inputSelect {
          width: 100%;
          max-width: unset;
        }
      }
      @include for-tablet-landscape-max_900 {
        flex-direction: column;
      }
    }
  }

  .hourlyPricesContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .hourlyPrices {
      margin-left: 32px;
      display: flex;
      gap: 20px;

      @include for-mobile-max_767 {
        flex-direction: column;
        gap: 12px;
      }

      @include for-mobile-max_480 {
        margin-left: 0px;
      }
    }
  }

  .bottomCheckboxes {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .loyaltyProgramLabel {
      display: flex;
      gap: 12px;
      align-items: center;

      .checkboxLabel {
        @include for-mobile-max_767 {
          font-size: 14px !important;
          font-weight: 400;
          line-height: 24px;
        }
      }

      @include for-mobile-max_767 {
        font-size: 14px !important;
        gap: 8px;
      }
    }
  }

  .paymentControlBlock {
    display: flex;
    align-items: flex-start !important;

    span svg {
      @include for-mobile-max_480 {
        width: 20px;
        height: 20px;
      }
    }

    span {
      padding-top: 0;
      padding-bottom: 0;

      @include for-mobile-max_480 {
        line-height: 20px;
        padding-right: 3px;
      }
    }

    &:disabled {
      color: #212121;
    }

    @include for-mobile-max_767 {
      font-size: 16px !important;
      font-weight: 400;
      line-height: 24px;
    }

    @include for-mobile-max_480 {
      font-size: 14px !important;
    }
  }

  .milestonesBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #424242;

      @include for-mobile-max_767 {
        font-size: 14px !important;
      }
    }

    .addInfoBtn {
      display: flex;
      gap: 12px;
      padding: 0 !important;
      border: none !important;

      span {
        display: none;
      }

      .addInfoText {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
      }
    }

    .milestonesTopBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .milestoneItem {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .titleBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .deleteBtn {
        padding: 0;
      }
      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  .specificTerms {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .textarea {
    width: 100%;
    min-height: 72px;
    margin: 0 !important;

    textarea {
      min-height: 72px !important;
    }
  }

  .costsContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-direction: column;

    .costBlock {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      column-gap: 48px;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .costBlockItem {
        display: flex;
        gap: 16px;
        flex-direction: column;

        @media (max-width: 1600px) and (min-width: 1025px) {
          width: 100%;
        }

        @media (max-width: 950px) {
          width: 100%;
        }

        .costItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;

          @media (max-width: 1600px) and (min-width: 1025px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            row-gap: 8px;
          }

          @media (max-width: 950px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            row-gap: 8px;
          }

          .costLabel {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #212121;
            white-space: nowrap;
          }

          .costInput {
            max-width: 193px;
            min-width: 168px;
            @media (max-width: 1600px) and (min-width: 1025px) {
              max-width: 100%;
              width: 100%;
            }

            @media (max-width: 950px) {
              max-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
