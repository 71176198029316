@import '/src/styles/mixins';

.cardContainerStyle {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  cursor: pointer;
  .imageContainer {
    height: 100%;
    max-height: 200px;
    @include for-mobile-max_620 {
      height: unset;
    }
  }
  .serviceImgContainer {
    max-height: 220px;
  }
  .cardImage {
    object-fit: cover;
    width: 229px;
    @include for-mobile-max_620 {
      width: 153px;
    }
    height: 100%;
    max-height: 200px;
    @include for-mobile-max_620 {
      width: 153px;
    }
    @media only screen and (max-width: 570px) {
      width: 153px !important;
    }
  }
  .serviceCardImage {
    max-height: 220px;
  }
}

.priceBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.inProgressText {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
.price,
.discountPrice {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  @include for-mobile-max_620 {
    word-wrap: anywhere;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.discountPrice {
  color: #d32f2f;
}

.oldPrice {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
  text-decoration: line-through;
  text-decoration-color: #d32f2f;
}

.availableBlock {
  font-size: 12px;
  font-weight: 400;

  > span {
    color: #4caf50;
  }
}

.cardContainer {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 24px;
  @include for-mobile-max_620 {
    padding: 12px;
  }

  .rightBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    .btnFavorite,
    .btnEdit {
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      position: absolute;
      top: 20px;
      right: 36px;
      @include for-mobile-max_620 {
        top: 4px;
        right: 4px;
      }
      border-radius: 12px;
      color: #212121;
      :hover {
        color: #d32f2f;
      }
      font-size: 16px;
      border: none;
      cursor: pointer;
    }

    .btnEdit {
      :hover {
        color: #212121;
      }
    }

    .btn {
      padding: 0 20px !important;
      line-height: 40px !important;
      font-size: 12px !important;
    }
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .text {
    flex-direction: column;
    display: flex;
    gap: 8px;

    .productName {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      @include for-mobile-max_480 {
        word-wrap: anywhere;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .companyName {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #757575;
      @include for-mobile-max_480 {
        word-wrap: anywhere;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .availability {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
  }
  .productRatingBar {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .ratingBarItems {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }
}
