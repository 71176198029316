@mixin for-mobile-max_480 {
  @media only screen and (max-width: 480px) {
    @content;
  }
}
@mixin for-mobile-max_620 {
  @media only screen and (max-width: 620px) {
    @content;
  }
}
@mixin for-mobile-max_767 {
  @media only screen and (max-width: 767px) {
    @content;
  }
}
@mixin for-tablet-landscape-max_1024 {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@mixin for-tablet-landscape-max_1100 {
  @media only screen and (max-width: 1100px) {
    @content;
  }
}
@mixin for-tablet-landscape-max_900 {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin for-tablet-landscape-min_1100 {
  @media only screen and (min-width: 1100px) {
    @content;
  }
}
@mixin for-tablet-landscape-min_1024 {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
@mixin for-desktop-up-min_1200 {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}
@mixin for-desktop-up-max_1200 {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}
@mixin for-desktop-up-max_1440 {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}
@mixin for-desktop-up-max_1500 {
  @media only screen and (max-width: 1500px) {
    @content;
  }
}
@mixin for-desktop-up-max_1300 {
  @media only screen and (max-width: 1300px) {
    @content;
  }
}
@mixin for-desktop-up-max_1660 {
  @media only screen and (max-width: 1660px) {
    @content;
  }
}
@mixin for-desktop-up-max_1860 {
  @media only screen and (max-width: 1860px) {
    @content;
  }
}
@mixin for-desktop-up-min_1860 {
  @media only screen and (min-width: 1860px) {
    @content;
  }
}
@mixin for-narrow {
  @media only screen and (min-aspect-ratio: 11/5) {
    @content;
  }
}
@mixin for-screen_3_4 {
  @media only screen and (min-aspect-ratio: 1/1) and (max-aspect-ratio: 11/5) {
    @content;
  }
}
@mixin for-orientation-landscape {
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    @content;
  }
}

@mixin for-404-page-mobile {
  @media screen and (max-width: 900px), screen and (max-height: 900px) {
    @content;
  }
}

@mixin for-mobile-s {
  @media only screen and (max-height: 640px) and (max-width: 480px) {
    @content;
  }
}

@mixin text-selection-enabled {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}
