@import '/src/styles/mixins';

.requestCustomizationModal {
  max-width: 604px;

  @include for-mobile-max_620 {
    padding: 24px 20px !important;
    font-size: 16px !important;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    .errorText {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: red;
    }
    .note {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    @include for-mobile-max_620 {
      gap: 20px;
      margin-top: 20px;
    }
  }

  .formButtons {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include for-mobile-max_620 {
      .formButton {
        font-size: 12px !important;
        font-weight: 600 !important;
      }
    }
  }
}

.textarea {
  width: 100%;
  min-height: 72px;
  margin: 0 !important;

  textarea {
    min-height: 72px !important;
  }
}
