@import '../../../../../styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .deleteItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .deleteBtn {
      display: flex;
      gap: 2px;
      text-transform: none;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 0 !important;
      span {
        display: none;
      }
    }
  }
  .product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @include for-tablet-landscape-max_900 {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .productTitleBlock {
      display: flex;
      align-items: center;
      gap: 12px;

      .productImage {
        border-radius: 2px;
        position: relative;

        @media only screen and (max-width: 460px) {
          max-width: 100%;
        }

        > img {
          object-fit: cover;
          background-size: cover;
          border-radius: 2px;
        }

        .favorites {
          position: absolute;
          top: 11.5px;
          right: 11.5px;
          background: rgba(255, 255, 255, 0.7);
          backdrop-filter: blur(10px);
          border-radius: 12px;
          padding: 10px;
          max-height: 40px;
          cursor: pointer;
        }
      }

      .productName {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 289px;

        .mobileDelete {
          position: absolute;
          right: 20px;
        }

        .title {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          word-wrap: anywhere;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .parameter {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .productPriceOptions {
      max-width: 260px;

      @include for-tablet-landscape-max_900 {
        min-width: 100%;

        div {
          min-width: 100%;
        }
      }

      .fullSelect {
        min-width: fit-content;
        z-index: 9;
        background-color: #ffffff;
      }
    }

    .endAdornment {
      min-width: fit-content !important;
      z-index: 9;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #212121;
      padding-right: 5px;
      right: 5px;
      position: absolute;
    }
    .productCountOptions {
      display: flex;
      gap: 20px;
      align-items: center;

      @include for-tablet-landscape-max_900 {
        width: 100%;
        justify-content: space-between;
      }

      .productCounts {
        display: flex;
        gap: 3px;
        .productCountInput {
          max-width: 100px;
        }
      }
    }
  }
}
