@import '../../../styles/mixins';

.productImageSwiper {
  width: 100%;
  padding-bottom: 18px !important;
  display: none !important;

  @media screen and (max-width: 800px) {
    display: block !important;
  }

  @media screen and (max-width: 650px) {
    height: 284px;
  }

  .productImageSwiperSlide {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
  }
}

.swiperWrapper {
  position: relative;

  &:hover .controlButton {
    opacity: 1;
    pointer-events: all;
  }
}

.controlButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-50%) scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  }

  svg {
    width: 20px;
    height: 20px;
    fill: rgba(0, 0, 0, 0.6);

    &:hover {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
}

.prevButton {
  left: -20px;
}

.nextButton {
  right: -20px;
}

.customSwiper {
  position: relative;
  width: 100%;
  max-width: 900px;

  :global(.swiper-button-next),
  :global(.swiper-button-prev) {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    transition: all 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
      transform: scale(1.1);
    }

    &::after {
      font-size: 16px;
      font-weight: bold;
    }
  }

  :global(.swiper-button-disabled) {
    display: none;
  }

  :global(.swiper-button-next) {
    right: 0px;
  }

  :global(.swiper-button-prev) {
    left: 0px;
  }

  .slide {
    transition: border 0.3s ease;
    cursor: pointer;

    &:hover {
      img {
        border: 1px solid #1f4a9d;
      }
    }
  }

  .activeSlide {
    img {
      border: 2px solid #1f4a9d;
      box-shadow: 0px 0px 10px rgba(31, 74, 157, 0.5);
    }
  }
}

.inCartText {
  font-weight: 400;
  font-size: 16px;
  color: #4caf50;
  white-space: nowrap;
  margin: 20px 0;
}

.pageContentBox {
  display: flex;
  flex-direction: column;
  max-width: 1020px;
  width: 100%;
  margin-bottom: 106px;
  position: relative;

  .backBtn {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #212121;
    padding: 11px 18px 11px 14px;
    border-radius: 12px;
    background-color: #fff;
    @media screen and (max-width: 460px) {
      width: 100%;
      margin-right: 8px;
      display: none;
    }
  }

  .filterContent {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 4px;

    .filterBar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 16px;
      @media screen and (max-width: 460px) {
        flex-direction: column;
      }

      .mobileFilterBar {
        display: none;
        @media screen and (max-width: 460px) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          .backBtn {
            display: flex !important;
          }
          .shoppingCart {
            display: flex !important;
          }
        }
      }

      .searchFilter,
      .shoppingCart {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
      }
      .searchFilter {
        width: 100%;
        gap: 12px;
        padding: 7px 20px;

        div {
          border-radius: 0 !important;
          border: 0 !important;
        }
        div:hover {
          box-shadow: none !important;
        }
      }
      .shoppingCart {
        width: fit-content;
        cursor: pointer;
        padding: 11px 17.4px;
        gap: 4px;
        @media screen and (max-width: 460px) {
          display: none;
        }
      }
    }
  }

  .statusBlock {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    margin-top: 4px;
  }
  .productStatus {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
    border-radius: 12px;
    background-color: #ffffff;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-transform: capitalize;
  }
  .productContent {
    padding: 24px 60px;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    position: relative;

    @media screen and (max-width: 1024px) {
      padding: 24px 40px;
    }

    @media screen and (max-width: 899px) {
      border-radius: 12px;
    }

    @media screen and (max-width: 500px) {
      padding: 24px 20px;
    }
    .productHeader {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;

      .companyInfo {
        display: flex;
        align-items: center;
        column-gap: 12px;

        .companyName {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #212121;
        }
      }

      .headerActions {
        display: flex;
        align-items: center;
        gap: 20px;

        .actionBtn {
          padding: 12px 20px !important;
          width: 64px;
          height: 48px;
        }
      }
    }
    .productInfoContainer {
      display: flex;
      column-gap: 20px;

      @media screen and (max-width: 800px) {
        flex-direction: column;
        row-gap: 20px;
      }
      .notExist {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Montserrat';
        font-weight: 400;
      }
      .productImages {
        display: flex !important;
        flex-direction: column;
        row-gap: 12px;
        max-width: 428px;
        width: 100%;

        //@media screen and (max-width: 1490px) {
        //  width: 360px;
        //}
        //
        //@media screen and (max-width: 1430px) {
        //  width: 300px;
        //}
        //
        //@media screen and (max-width: 1380px) {
        //  width: 100%;
        //  max-width: 100%;
        //}
        //
        //@media screen and (max-width: 1024px) {
        //  max-width: 428px;
        //  width: 428px;
        //}
        //
        //@media screen and (max-width: 800px) {
        //  width: 100%;
        //  max-width: 100%;
        //}

        @media screen and (max-width: 800px) {
          display: none !important;
        }

        .selectImage {
          width: 100%;
          min-height: 426px;
          border-radius: 12px;
          position: relative;

          @media screen and (max-width: 800px) {
            max-height: none;
          }

          .favorites {
            position: absolute;
            top: 20px;
            right: 20px;
            background: rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(10px);
            border-radius: 12px;
            padding: 10px;
            max-height: 40px;
            cursor: pointer;
          }
        }

        .slider {
          display: flex;
          align-items: center;
          column-gap: 8px;
          flex-wrap: nowrap;

          .sliderImage {
            width: 90px;
            height: 90px;
            min-width: 90px;
            border-radius: 12px;
            overflow: hidden;
          }
        }

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .productInfo {
        .pairCount {
          margin-top: 24px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #4caf50;
        }
        .productName {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #000000;
        }

        .productReviewContainer {
          padding-bottom: 20px;

          .productReviewBlock {
            display: flex;
            align-items: center;
            margin-top: 16.54px;
            flex-wrap: wrap;
            row-gap: 12px;

            .productReview {
              display: flex;
              align-items: center;
              column-gap: 3px;
              margin-right: 8px;
            }

            .productReviewCount,
            .productSoldCount {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #212121;
              margin-right: 22px;
              white-space: nowrap;
            }

            .productSoldCount {
              margin-left: 10px;
            }

            .productSoldCountBlock {
              display: flex;
              align-items: center;
            }
          }

          .productPatronage {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-decoration-line: underline;
            color: #143471;
            margin-top: 16px;
            cursor: pointer;
          }
        }

        .companyBlock {
          display: flex;
          align-items: center;
          width: 100%;
          padding-bottom: 20px;
          border-bottom: 1px solid #efefef;
          .companyBlockTitle {
            font-size: 15px;
            font-weight: 500;
          }
          .companyInfo {
            display: flex;
            align-items: center;
            margin-left: 12px;
            a {
              margin-left: 4px;
            }
          }
        }

        .productSettingsContainer {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          .label {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #757575;
          }

          .productSettingsItemList {
            display: flex;
            align-items: center;
            column-gap: 12px;
            row-gap: 12px;
            flex-wrap: wrap;

            .productSettingsColorContainer {
              padding: 4px;
              border-radius: 50%;
              cursor: pointer;

              .productSettingsColor {
                width: 32px;
                height: 32px;
                border-radius: 16px;
              }
            }

            .productSettingsSizeContainer {
              padding: 8px 12px;
              border-radius: 12px;
              cursor: pointer;

              .productSettingsSize,
              .productSettingsSizeDisabled {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #212121;
                white-space: nowrap;
              }
              .productSettingsSizeDisabled {
                color: #c4c4c4 !important;
              }
            }
          }
        }

        .discountPriceBlock {
          margin: 24px 0;

          .oldPriceBlock {
            margin-left: 19px;
            position: relative;
            width: fit-content;

            .oldPrice {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #757575;
            }

            .oldPriceLine {
              width: 100%;
              height: 2px;
              background-color: #d32f2f;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }

          .discountPrice {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #d32f2f;

            span {
              color: #757575;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }

        .productPrice {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          color: #212121;
          margin-top: 24px;
          margin-bottom: 24px;

          span {
            color: #757575;
            font-weight: 600;
            font-size: 16px;
          }
        }

        .productCardCount {
          display: flex;
          align-items: center;
          column-gap: 29px;
          row-gap: 20px;
          flex-wrap: wrap;
          margin: 24px 0;
          .container {
            display: flex;
            align-items: center;
            column-gap: 13px;
            gap: 8px;
          }
          .button {
            height: 39px;
            display: flex;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          input {
            text-align: center;
            padding: 7px 12px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
            max-width: 52px;
            border-radius: 12px !important;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          .productAvailableCount {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #4caf50;
            white-space: nowrap;
          }
        }

        .productCountContainer {
          display: flex;
          align-items: center;
          column-gap: 29px;
          row-gap: 20px;
          flex-wrap: wrap;
          margin: 24px 0;

          .productChangeCountBlock {
            display: flex;
            align-items: center;
            column-gap: 13px;

            .changeCountButtons {
              display: flex;
              justify-content: center;
              padding: 10px;
              cursor: pointer;
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }

            .productCountInput input {
              text-align: center;
              padding: 8px 12px;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #212121;
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }

          .productAvailableCount {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #4caf50;
            white-space: nowrap;
          }
          .productOutStockText {
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #c4c4c4;
          }
        }

        .productButtonGroups {
          display: flex;
          align-items: center;
          column-gap: 12px;
          row-gap: 12px;
          padding-bottom: 20px;
          border-bottom: 1px solid #efefef;
          flex-wrap: wrap;

          .productButton {
            display: flex;
            padding: 0 20px;
            background: #18397a;
            border-radius: 4px;

            .productButtonText {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 48px;
              text-transform: uppercase;
              color: #ffffff;
              white-space: nowrap;
            }
          }

          .productButtonOutline,
          .productButtonDisabled {
            display: flex;
            align-items: center;
            column-gap: 15px;
            padding: 0 20px;
            border: 1px solid #18397a;
            border-radius: 4px;

            p {
              font-size: 16px;
              font-weight: 600;
            }

            @media screen and (max-width: 800px) {
              padding: 14px 20px;
            }

            .productButtonOutlineText {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 48px;
              text-transform: uppercase;
              color: #18397a;
              white-space: nowrap;

              @media screen and (max-width: 800px) {
                display: none;
              }
            }
          }

          .productButtonDisabled {
            opacity: 0.5;
          }
        }

        .productTypeContainer {
          //margin-top: 20px;
          display: flex;
          align-items: center;
          gap: 20px;

          .productTypeTitle {
            font-family: 'Montserrat';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }
  }
  .tabsContainer {
    width: 100%;
    background: #ffffff;
    padding: 12px 60px;

    @media screen and (max-width: 899px) {
      border-radius: 16px;
      margin: 12px 0;
      padding: 0;
    }

    .topMenuItem {
      font-family: 'Montserrat' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      text-transform: capitalize;
    }

    .topSelect {
      line-height: 24px !important;
      margin-top: 0 !important;
      background: #ffffff !important;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
      border-radius: 16px !important;
      width: 100% !important;
      height: 48px !important;
      border: none !important;
      span {
        font-family: 'Montserrat' !important;
        color: #18397a !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
      }
    }

    .tabs {
      width: 100%;
      border-bottom: 1px solid #c4c4c4;
      justify-content: space-between;

      .tabItem {
        @media screen and (max-width: 1340px) {
          margin: 0 10px;
        }

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
        text-transform: none;
        padding: 0;
      }
      .activeItem {
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: #143471 !important;
        text-transform: none;
      }
    }
  }

  .productInformationContainer {
    width: 100%;
    background: #ffffff;
    padding: 8px 60px 24px 60px;
    border-radius: 0 0 12px 12px;

    @media screen and (max-width: 1024px) {
      padding: 8px 40px 24px 40px;
    }

    @media screen and (max-width: 899px) {
      padding: 24px 40px;
      border-radius: 12px;
    }

    @media screen and (max-width: 500px) {
      padding: 24px 20px;
    }

    .comingSoonBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 60px;
    }
  }
}
