@import '/src/styles/mixins';

.imagesBlock {
  border: 1px solid #efefef;
  border-radius: 12px;
  padding: 12px 20px;

  .imagesDropContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    @include for-mobile-max_620 {
      flex-direction: column;
      align-items: flex-start;
    }

    .iconContainer {
      padding: 20px 28px 20px 20px;
      border-right: 1px solid #efefef;

      @include for-mobile-max_620 {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px 20px 28px 20px;
        border-right: none;
        border-bottom: 1px solid #efefef;
      }
    }

    .imagesBlockText {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      margin-left: 8px;

      @include for-mobile-max_620 {
        margin-left: 0;
        margin-top: 8px;
      }

      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;

      .imagesBlockTitle {
        font-weight: 600;
      }

      .imagesBlockDescription {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}