.modalWrapper {
  max-width: 600px !important;
}

.modalContainer {
  max-width: 604px;

  .label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 8px;
    margin-top: 20px;

    .labelTitle {
      color: #212121;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .activeLabelTitle {
      color: #143471;
    }

    .labelDescription {
      color: #555;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.radioContainer :global {
  .ant-radio-wrapper {
  .ant-radio {
    align-self: flex-start;
    margin-top: 21px;
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border: 2px solid black;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: white;
  }

  .ant-radio-checked .ant-radio-inner::after {
    position: absolute;
    left: 6px;
    top: 5.5px;
    width: 23px;
    height: 23px;
    background: black;
    border-radius: 50%;
  }
}
}