@import '/src/styles/mixins';

.container {
  background: #ffffff;
  border-radius: 12px;
  margin-top: 20px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
  }

  .accountLogoRow {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;

    @media (max-width: 900px) {
      padding-left: 0px;
      //margin-bottom: 20px;
    }
  }

  .accountLogoOverlay {
    width: 128px;
    height: 128px;
    background: #757575;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    img {
      width: 25px;
      height: 23px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;

      @media (max-width: 900px) {
        width: 18px;
        height: 16px;
      }
    }

    @media (max-width: 900px) {
      margin: 0 auto;
      width: 80px;
      height: 80px;
    }
  }

  .accountLogo {
    position: relative;
    overflow: hidden;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    cursor: pointer;

    @media (max-width: 900px) {
      width: 80px;
      height: 80px;
    }

    @media (max-width: 1024px) {
      margin: 0 auto;
    }

    &:hover {
      .accountLogoHover {
        transition: opacity 0.3s ease-out;
        opacity: 1;
      }
    }

    .accountLogoHover {
      background-color: #05050598;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 0;
      width: 100%;
      opacity: 0;
      display: flex;
      justify-content: center;

      img {
        width: 25px;
        height: 23px;

        @media (max-width: 1024px) {
          width: 18px;
          height: 16px;
        }
      }

      @media (max-width: 1024px) {
        padding: 2px;
      }
    }
  }

  .accountLogoImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .supplierForm {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 56px;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      row-gap: 12px;
    }

    .inputGroup {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }
  }
}

.input {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  padding-left: 12px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
}

.modal_input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 12px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.formInputGroup {
  display: flex;
  column-gap: 20px;
  row-gap: 12px;
  margin-top: 12px;
  flex-wrap: wrap;

  .selectBlock {
    width: calc(50% - 10px);
  }

  .input,
  .modal_input {
    width: calc(50% - 10px);
  }

  .phone {
    width: calc(50% - 10px);

    .phoneInput {
      height: 48px !important;
      background: #ffffff !important;
      border: 1px solid #efefef;
      border-radius: 12px;
      padding-left: 120px !important;
      width: 100% !important;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    .selectBlock,
    .input,
    .phone,
    .modal_input {
      width: 100%;
    }
  }
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  button {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 48px !important;
    padding: 0 20px !important;
  }

  button:first-of-type {
    padding: 0 !important;
  }
}

.errorMessage {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d32f2f;
  margin-top: -12px;
  margin-left: 5px;
}

.styledInput {
  width: calc(50% - 10px);
  @media screen and (max-width: 900px) {
    width: 100% !important;
  }
}
