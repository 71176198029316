@import '/src/styles/mixins';

.documentAccountLeft {
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 440px;
    height: 48px;
    margin-top: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.documentAccountRight {
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 440px;
    height: 48px;
    margin-top: 20px;

    margin-right: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.documentAccountSelect {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 440px;
  height: 48px;
  margin-top: 20px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  color: #757575;
}

.underline {
  text-decoration: underline;
  //border-bottom: 1px solid #84b432 rgba(0, 0, 0, 5);
}

.birthday {
  > div {
    > div {
      > label {
        font-family: 'Montserrat';
      }
    }
  }
}

.socialPhone {
  > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 440px;
    height: 48px;
    margin-top: 20px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
    color: #757575;
  }
}

.documentsMedia {
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 380px;
    height: 48px;
    margin-top: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.documentsAccountLeft {
  width: 100%;

  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    max-width: 900px;
    width: 100%;
    height: 48px;
    margin-top: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }

  .inputIcon {
    width: 100%;
    margin-top: 20px;

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 16px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .errorText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #d32f2f;
    margin-top: 5px;
  }
}

.documentsAccountRight {
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 356px;
    height: 48px;
    margin-top: 20px;
    margin-left: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.underline {
  text-decoration: underline;
}

.documentsMedia {
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 380px;
    height: 48px;
    margin-top: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.documentsAccountSelect {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 440px;
  height: 48px;
  margin-top: 20px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  color: #757575;
}

.documentsAccountText {
  display: flex;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 400;
  padding-top: 20px;
}

.documentsAccountAdd {
  > input {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    width: 271px;
    height: 48px;
    margin-top: 20px;
    margin-left: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.documentsTextUpload {
  display: flex;
  justify-content: center;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
}

.documentsAccountTextUpload {
  display: flex;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  padding-top: 20px;
}

.input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}
.textarea {
  width: 100%;
  min-height: 72px;

  textarea {
    min-height: 72px !important;
  }
}
.documentsInput::-webkit-scrollbar {
  width: 5px;
}

.documentsInput::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}

.documentsInput::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}

.drugText {
  width: 180px;
  height: 48px;
  margin-left: 40px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
}

.drugImg {
  width: 27px;
  height: 34px;
  margin-top: 16px;
  margin-left: 40px;
}

.drugNdrop {
  //margin-left: 286px;
  margin-top: 30px;
  width: 328px;
  height: 72px;
  border: 1px solid #efefef;
  border-radius: 12px;
}

.drugBody {
  height: 358px;
  max-width: 900px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #efefef;
  margin-top: 20px;
}

.documentService {
  width: 100%;

  .documentsServiceRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 15px;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
    }
  }

  .documentsServiceSelect {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    max-width: 460px;
    width: 100%;
    height: 48px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
    color: #757575;
    padding-left: 10px;
  }
}

.menuItem {
  margin: 18px 0px 18px 0px;
}

.documentsTitle {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0px 8px 0px;
  line-height: 32px;
}

.documentsFile {
  h3 {
    margin-bottom: 16px;
  }
}

.documentsVideo {
  border-top: 1px solid #efefef;
  h3 {
    margin-bottom: 16px;
  }
}

.documentsTextRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addBtn {
  display: flex;
  align-items: center;
  column-gap: 17px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
  }
}

.dropzone {
}

.addMServiceButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #18397a;
  margin-top: 16px;
  border: 1px solid #18397a;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 20px 0 24px;

  p {
    margin: 0;
  }

  @media (max-width: 500px) {
    margin-top: 12px;
  }
}

.plusIcon {
  margin-right: 17px;
  height: 14px;
  width: 14px;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #18397a;
  margin-top: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 20px 0 24px;

  p {
    margin: 0;
  }

  @media (max-width: 500px) {
    margin-top: 12px;
  }
}

.deleteButtonArea {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #efefef;
  margin-bottom: 16px;
}
