.selectCompanyAccount {
  display: flex;
  width: 1020px;
  align-items: center;
  justify-content: center;
  height: 62px;
  font-family: 'Montserrat';
  color: #18397a;
  font-size: 16px;
  font-weight: 500;
  background: white;
  border-radius: 0 0 12px 12px;
}
.mapSelect {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 145px;
  height: 48px;
  //margin-top: 20px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  color: #757575;
}
.filterSelect {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 178px;
  height: 48px;
  // margin-top: 20px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  color: #757575;
}
.btn {
  width: 20px;
  height: 20px;
  color: #555555;
  margin-right: 10px;
  cursor: pointer;
  // margin-top: 20px;
}
.btn:hover {
  color: #3d5afe;
}
