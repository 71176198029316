.radioContainer {
  margin-top: 16px;
}

.body {
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}
