@import '/src/styles/mixins';

.itemWrapper {
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: flex-start;
  @include for-mobile-max_767 {
    max-width: 100%;
  }
}

.iconWrapper {
  height: 20px;
  width: 16px;
  margin-top: 0;
  padding-top: 8px;
}

.location {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  padding-top: 3px;
  transition: 0.3s;
  transition-timing-function: linear;
  @include for-mobile-max_620 {
    text-decoration: underline;
  }

  &:hover {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
  }
}
