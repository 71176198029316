.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  position: relative;
  height: 41px;
}

.input {
  color: #212121;
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 5px;
  max-width: 34px;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
}

.button {
  padding: 2px !important;
}

@media screen and (max-width: 400px) {
  .input {
    max-width: 26px;
  }
}
