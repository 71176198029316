.container {
  padding-bottom: 75px;
}

.tabs {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 60px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 0 0 12px 12px;
  margin-top: 1px;
}
.tabsItem {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  color: #212121;
}
.tabsItemActive {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #143471 !important;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 800px) {
    align-items: flex-end;
  }

  .carbonBtnGroup {
    display: flex;
    align-items: center;
    column-gap: 20px;
    flex-wrap: wrap;
    margin-left: 10px;
    justify-content: flex-end;

    @media (max-width: 800px) {
      gap: 12px;
    }

    @media (max-width: 554px) {
      button:nth-child(1) {
        order: 2;
      }

      button:nth-child(2) {
        order: 1;
      }

      button:nth-child(3) {
        order: 3;
      }
    }
  }

  .buttonCancel {
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #18397a;
    border-radius: 4px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;

    img {
      display: none;
    }

    @media (max-width: 800px) {
      div {
        display: none;
      }

      img {
        padding: 14px 0;
        display: block;
      }
    }
  }

  .buttonNext {
    background: #18397a;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #18397a;
    padding: 0 20px;
    display: flex;
    align-items: center;
    column-gap: 17.2px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.modal {
  padding: 24px 40px;
  gap: 20px;
  position: absolute;
  width: 42%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 77px 0px #00000014;
  @media (max-width: 1024px) {
    padding: 24px 20px;
    width: 79%;
  }
  @media (max-width: 850px) {
    padding: 24px 20px;
    width: 85%;
  }

  .closeIcon {
    position: absolute !important;
    top: 20px;
    right: 11px;
    color: grey;

    @media (max-width: 1024px) {
      right: 18px;
    }
  }

  .modalTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #212121;
  }

  .subTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-top: 16px;
  }
  .rightBtn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    color: #ffffff;
    transition: opacity 0.1s ease-in;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    padding: 0px 20px;
    gap: 12px;
    background: #18397a;
    border-radius: 4px;
    text-transform: uppercase;

    &:hover {
      opacity: 0.9;
    }
  }
  .leftBtn {
    font-family: 'Montserrat';
    background-color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    color: #18397a;
    transition: opacity 0.1s ease-in;
    cursor: pointer;
    margin-top: 20px;
    padding: 0px 20px;
    gap: 12px;
    border: 1px solid #18397a;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .modalActions {
    display: flex;
    justify-content: space-between;
  }
}
