@import '../../../../../styles/mixins';

.tabContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .companyAbout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 12px;

    .companyInfo {
      display: flex;
      align-items: center;
      column-gap: 12px;
      cursor: pointer;

      .companyName {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #212121;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .buttonOutline {
        padding: 0 20px;
        border: 1px solid #18397a;
        border-radius: 4px;

        .buttonOutlineText {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 48px;
          text-transform: uppercase;
          color: #18397a;
          white-space: nowrap;

          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
      }

      .buttonOutlineMessage {
        padding: 0 20px;
        border: 1px solid #18397a;
        border-radius: 4px;

        .buttonOutlineMessageText {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 48px;
          text-transform: uppercase;
          color: #18397a;
          white-space: nowrap;

          @media screen and (max-width: 600px) {
            display: none;
          }
        }

        img {
          display: none;

          @media screen and (max-width: 600px) {
            display: block;
            padding: 13.5px 0;
          }
        }
      }
    }
  }

  .description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }

  .companyContactBlock {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .companyContact {
      display: flex;
      align-items: center;
      column-gap: 10.25px;

      .companyContactText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
      }
    }
  }

  .socialMediaBlock {
    display: flex;
    align-items: center;
    column-gap: 20px;

    img {
      cursor: pointer;
    }
  }

  .documentsBlock {
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;

    .label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #212121;
      margin-bottom: 12px;
    }

    .filesContainer {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .file {
        padding-top: 13px;
        border-top: 1px solid #efefef;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name,
        .size,
        .type {
          font-family: 'Montserrat';
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
        }

        .name {
          font-weight: 500;
          color: #212121;
        }

        .size,
        .type {
          font-weight: 400;
          color: #555555;
        }

        .button {
          padding: 0 20px;

          .buttonText {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 48px;
            text-transform: uppercase;
            color: #18397a;

            @media screen and (max-width: 600px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
