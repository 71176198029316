@import '/src/styles/mixins';

.container {
  background: #ffffff;
  padding: 24px 60px;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }

  @include for-mobile-max_480 {
    padding: 24px 20px;
  }
}

.orderViewItemSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .orderViewItemSelectText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.noData {
  width: 100%;
  color: #555;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding: 24px 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  button {
    padding: 0 !important;

    &:hover {
      background-color: transparent;
    }
  }

  p {
    color: #212121;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  svg > path {
    fill: #757575;
  }
}

.filterContainer {
  display: flex;
  justify-content: flex-end;

  > div {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
}

.row,
.topRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.topRow {
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
  gap: 23px;

  @include for-tablet-landscape-max_1024 {
    gap: 64px;
  }

  @media screen and (max-width: 834px) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
  }
}

.row {
  gap: 20px;

  .fullInput {
    width: calc(50% - 95.5px);

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .fullSelect {
    width: 171px;
  }

  @media screen and (max-width: 834px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .fullInput {
      width: 100%;
    }

    .fullSelect {
      width: 100%;
    }
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.listDate {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
}

.listItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.listItemTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: calc(100% - 190px);

  @media screen and (max-width: 600px) {
    width: calc(100% - 155px);
  }

  p {
    color: #212121;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.listItemExtra {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.status {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;

  @media screen and (max-width: 640px) {
    font-size: 8px;
  }
}

.menuContainer {
  margin-left: -5px;
}

.menuButton {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px !important;
}

.paginationBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pagination {
    display: flex;
    justify-content: center;
  }

  .paginationItem {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 15px !important;
    background: #ffffff !important;
    border: 1px solid #efefef;
    border-radius: 12px !important;
    color: #212121 !important;
  }

  .activePaginationItem {
    border: 1px solid #143471 !important;
    border-radius: 12px !important;
  }

  .paginationText {
    margin-top: 4px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
}

.menuItemText {
  color: #212121;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
