@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
    margin-bottom: 12px;
  }
  .collectionBlock {
    display: flex;
    column-gap: 20px;

    .collectionItem {
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      padding: 20px 32px 20px 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include for-mobile-max_620 {
        flex-direction: column;
      }

      .collectionItemLeftContent {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .itemTitle {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 32px;
          color: #212121;
        }

        .description {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #757575;
        }

        .infoBlock {
          display: flex;
          align-items: center;
        }
        .info {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #757575;
          margin-left: 3.33px;

          span {
            color: #4caf50;
          }
        }
      }
      .collectionItemRightContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include for-mobile-max_620 {
          flex-direction: row;
          margin-top: 8px;
          justify-content: space-between;
        }
        .btnOutline {
          border: 1px solid #18397a;
          border-radius: 4px;
          padding-right: 14.5px;
          padding-left: 14.5px;
          background-color: #ffffff;
          height: fit-content;
          cursor: pointer;

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 40px;
          text-transform: uppercase;
          color: #18397a;
        }
        .btn {
          border: none;
          background-color: #ffffff;
          height: fit-content;
          cursor: pointer;

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 40px;
          text-transform: uppercase;
          color: #18397a;
        }
      }
    }
  }
  .viewAllBlock {
    display: flex;
    align-items: center;
    column-gap: 8.15px;
    justify-content: flex-end;
    margin-top: 16px;

    p {
      margin: 0;
      padding: 0;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #18397a;
    }
  }
}

.adminContainer {
  margin-top: 20px;

  @include for-mobile-max_620 {
    margin-top: 12px;
  }

  .adminBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .adminBlockTitle {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;
      color: #212121;

      @include for-mobile-max_620 {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .addButton {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #18397a;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      padding-left: 24.8px;
      padding-right: 20px;
      column-gap: 17.2px;

      p {
        margin: 0;
        padding: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 48px;
        text-transform: uppercase;
        color: #ffffff;

        @include for-mobile-max_620 {
          font-size: 12px;
        }
      }
    }
  }
}

.paginationBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pagination {
    display: flex;
    justify-content: center;
  }
  .paginationItem {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 15px !important;
    background: #ffffff !important;
    border: none !important;
    border-radius: 12px !important;
    color: #212121 !important;
  }

  .activePaginationItem {
    border: 1px solid #143471 !important;
    border-radius: 12px !important;
  }
  .paginationText {
    margin-top: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
}
