@import '/src/styles/mixins';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.08);
  padding: 24px 40px;
  border-radius: 12px;
  min-width: 327px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .modalTitle {
    width: 100%;
    justify-content: space-between;
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #424242;
    gap: 14px;

    @include for-mobile-max_767 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    .closeIcon {
      height: fit-content;
      padding: 0;
    }
  }

  .changesItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .statusChanges {
      display: flex;
      gap: 20px;
      align-items: center;

      .orderStatus {
        padding: 8px 12px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }

  .changedBlock {
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 20px !important;
  }

  .oldValue {
    color: #757575;
    font-weight: 400;
    font-size: 12px !important;
  }

  .changedValue {
    font-size: 14px !important;
    font-weight: 500;
    color: #000000;
  }

  .changesProduct {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;

    .productDesc {
      display: flex;
      align-items: center;
      gap: 20px;

      .productInfo {
        display: flex;
        align-items: center;
        gap: 12px;

        .productTitle {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
      }
    }
  }

  .changedInfoBlock {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .changedName {
      font-weight: 400;
      line-height: 20px;
      font-size: 12px !important;
    }
  }

  .changedTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
    flex-wrap: wrap;
  }
}
