.deliveryText {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
}

.deliveryDiv {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}

.deliveryColumnLeft {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.pickupItems {
  padding: 12px 0;
  gap: 12px;
  display: flex;
  flex-direction: column;
}

.formControl {
  display: flex;
  color: #212121;
  margin: 0;
  width: 100%;
  flex: 1;
}
.rowItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .checkboxItems {
    display: flex;
    gap: 8px;
    align-items: center;

    label {
      margin: 0;
    }
  }
}
.addPickupButton {
  position: absolute;
  top: -12px;
  right: 0;
  gap: 12px;
  padding: 12px 20px !important;
  .btnText {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #18397a !important;
    margin: 0 !important;
  }

  @media (max-width: 530px) {
    .plusIcon {
      margin: 0;
    }
    .btnText {
      display: none;
    }
  }
}

.checkBoxLabel {
  color: #143471;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  @media (max-width: 1200px) {
    color: #212121;
    font-weight: 500;
  }
}

.accordionContainer {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-radius: 12px !important;
  border: 1px solid #efefef;
  margin: 12px 0 !important;
  padding: 12px 12px 12px 12px;
  box-shadow: none;
  min-height: 76px;
  @media (max-width: 500px) {
    min-height: auto;
  }
  @media (max-width: 899px) {
    padding: 12px 56px 12px 12px !important;
  }
}

.optionsIcon {
  position: absolute !important;
  right: -88px !important;
}

.menuItemWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 142px;
}
.pickupInfo {
  margin-top: 10px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .infoItem {
    padding-left: 37px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .info {
      display: flex;
      column-gap: 8px;

      .contact {
        display: flex;
        column-gap: 12px;
        flex-wrap: wrap;
        word-break: break-all;
      }

      .shippingConst {
        display: flex;
        gap: 4px;

        p {
          font-size: 14px;
          font-family: 'Montserrat' !important;
          line-height: 24px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
    @media (max-width: 800px) {
      padding-left: 0;
    }
  }
  .bodyAutoOrder {
    position: absolute;
    right: 0;
    bottom: 12px;
  }
  .description {
    font-size: 14px;
    font-family: 'Montserrat' !important;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 45%;
    padding-bottom: 12px;
    margin-bottom: 10px;
    @media (max-width: 800px) {
      max-width: 100%;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 12px;
  }
}

.accordionIcons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.platformPickup {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .titleAutoOrder {
    position: absolute;
    right: -62px;
    top: 30px;
    @media (max-width: 899px) {
      right: -105px !important;
    }
    @media (max-width: 500px) {
      display: none !important;
    }
  }
  .pickupTitle {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;

    .title {
      font-family: 'Montserrat' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      overflow-wrap: anywhere;

      @media (max-width: 800px) {
        font-size: 14px !important;
      }
    }
    .pickupIcon {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  .pickupTitleRight {
    display: flex;
    flex-direction: column;
  }
  .pickupActions {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
  }
}

.deliveryColumnRight {
  margin-left: 20px;
}

.subMenu {
  width: 100%;
  margin-left: 30px;

  @media (max-width: 900px) {
    margin-left: 0;
  }
}

.byPost {
  width: 100%;
  display: flex;
  gap: 20px;
  .searchInput {
    width: 100%;
    gap: 12px;
    padding: 7px 20px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #efefef;
    border-radius: 12px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
    align-items: flex-start;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

.checkInput {
  display: flex;
  color: #212121;
  margin-left: 23px;

  @media (max-width: 900px) {
    margin-left: 0;
  }
}

.locationInput {
  position: relative;
  width: 100%;

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(20px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.locationInputSeller {
  position: relative;
  width: 100%;

  @media (max-width: 900px) {
    margin-right: 0;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(20px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.costInput {
  max-width: 240px;

  @media (max-width: 900px) {
    max-width: 100%;
    width: 100%;
  }

  &[data-type='cost'] {
    cursor: pointer !important;
  }

  &[data-type='cost'] > label[data-shrink='false'] {
    transform: translate(20px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.subItem {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.place {
  display: flex;
  align-items: center;
}

.marketIcon {
  height: 18px;
  width: 19px;
}

.infoIcon {
  height: 19px;
  width: 19px;
  margin: 0px 12px;
}

.locationName {
  margin-left: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.costBlock {
  display: flex;
  justify-content: space-around;
}

.deleteButton {
  margin: 0;
  padding: 15px 24px;
  border: 1px solid #18397a;
  border-radius: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabledLabel {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #c4c4c4 !important;
}
.errorText {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
}
.autoOrderCheckbox {
  display: flex;
  gap: 12px;
  @media (max-width: 899px) {
    gap: 8px !important;
  }

  .autoOrderText {
    margin-left: 12px !important;
    @media (max-width: 899px) {
      margin-left: 8px !important;
    }
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #143471 !important;
  }
}
