@import '/src/styles/mixins';

.middleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  @include for-mobile-max_620 {
    padding-bottom: 15px;
    order: -1;
    width: 100%;
  }
}

.status {
  background: #2d8342;
  border-radius: 16px;
  padding: 9px 11.95px 9px 16.05px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  white-space: nowrap;

  @media (max-width: 700px) {
    font-size: 14px;
  }
}

.name {
  width: 100%;
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #212121;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;

  .companyName {
    word-break: break-word;
    width: fit-content;
    max-width: 100%;
    text-align: center;

    @include for-mobile-max_620 {
      max-width: calc(100% - 70px);
    }
  }
  .kybFlag {
    border-radius: 8px;
    background-color: #4caf50;
    padding: 2.5px;
    font-family: 'Montserrat';
    font-size: 6px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #ffffff;
  }
}

.successKyc,
.pendingKyc {
  position: absolute;
  top: -4px;
  right: -22px;
  border-radius: 8px;

  font-weight: 700;
  font-size: 7px;
  line-height: 9px;
  color: #ffffff;
  padding: 2px;
}

.successKyc {
  background: #4caf50;
}

.pendingKyc {
  background: #757575;
}

.managedBy {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  min-height: 20px;
  line-height: 20px;
  text-align: center;
  color: #212121;
  align-items: center;
  display: flex;
  gap: 12px;
  .managedAvatar {
    display: flex;
    & > div {
      cursor: pointer;
    }
    & > div:not(:first-child) {
      margin-left: -10px;
    }
  }
}

.managedName {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #212121;
  cursor: pointer;
}

.countryFlag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
}

.flagWrapper {
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}
