@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }

  .certificateName {
    text-align: center;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    @include for-mobile-max_620 {
      font-size: 14px;
    }
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
    margin-bottom: 12px;
  }
  .subTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 12px;

    &::first-letter {
      text-transform: uppercase;
    }

    @include for-mobile-max_767 {
      font-size: 14px;
    }

    span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      margin-left: 20px;
    }
  }
  .description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    word-break: break-all;
  }

  .goalDescription {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @include for-mobile-max_620 {
      font-size: 14px;
    }
  }

  .sdgsBlocks {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    img {
      margin-right: 20px;

      @include for-mobile-max_620 {
        width: 93px !important;
        height: 93px !important;
      }
    }
  }
  .more {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #212121;
    cursor: pointer;
  }
  .chip {
    background: #efefef !important;
    border-radius: 12px !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    border: none;
    color: #212121;
    margin-left: 12px;
    margin-bottom: 12px;
  }
}

.paginationBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  .pagination {
    display: flex;
    justify-content: center;
  }
  .paginationItem {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 15px !important;
    background: #ffffff !important;
    border: none !important;
    border-radius: 12px !important;
    color: #212121 !important;
  }

  .activePaginationItem {
    border: 1px solid #143471 !important;
    border-radius: 12px !important;
  }
  .paginationText {
    margin-top: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
}

.scrollContainer {
  padding: 0 44px 20px 44px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;

  @include for-mobile-max_620 {
    padding: 0 24px 20px 24px;
  }
  .videosContainer,
  .presentationsContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    .videoItem,
    .presentationItem {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .videoName,
      .presentationName {
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .videoImage,
      .presentationImage {
        position: relative;
        border-radius: 4px;
        .videoPlayBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          path {
            fill: white;
          }
        }
      }
    }
  }
  .rightSlide,
  .leftSlide {
    height: 40px;
    width: 40px;
    position: absolute;
    z-index: 1;
    top: 45%;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .leftSlide {
    left: 0;
    display: none;
    @include for-mobile-max_620 {
      left: -12px;
    }
  }
  .rightSlide {
    right: 0;
    display: none;
    @include for-mobile-max_620 {
      right: -12px;
    }
  }
}
.scrollContainer:last-child {
  border: none;
}
