.deleteChips {
  max-height: 100%;
  overflow: auto;
  width: 100%;
  max-width: 900px;
  margin-top: 10px;
  padding-bottom: 10px;
}
.deleteChips::-webkit-scrollbar {
  height: 5px;
}
.deleteChips::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.deleteChips::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}

.chip {
  background: #efefef !important;
  border-radius: 12px !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  border: none;
  color: #212121;
}
