@import '/src/styles/mixins';

.fieldWrapper {
  width: 100%;
  max-width: 1020px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 60px;
}
.mainInformation {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  background-color: #ffffff;
  padding: 24px 60px;
  width: 100%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 20px;
  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }
  @include for-mobile-max_767 {
    padding: 20px;
  }
  @include for-mobile-max_620 {
    grid-template-columns: 1fr;
  }
}
.leftContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  gap: 12px;

  @include for-mobile-max_767 {
    position: relative;
    padding-bottom: 15px;
    width: 100%;
  }
}
.middleContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-mobile-max_767 {
    padding-bottom: 15px;
  }
}
.modal_buttonSend {
  display: flex;
  height: max-content;
  justify-content: space-around;
  width: 140px;
  background: none;
  border: 1px solid #18397a;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  color: #18397a;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;

  @include for-mobile-max_480 {
    width: 80px;
    font-size: 12px;
    line-height: 40px;
  }
}

.password {
  padding-bottom: 12px;
}

.headerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include for-mobile-max_767 {
    width: 100vw !important ;
    display: flex;
    justify-content: space-around;
    align-items: center !important;
  }
}
.language {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.midContent {
  display: flex;
  width: 98%;
  padding-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include for-mobile-max_767 {
    width: 100vw !important ;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  @include for-mobile-max_620 {
    align-items: center;
  }

  .connectStripeButton {
    white-space: nowrap;
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #5e56f2;
    border: 1px solid #5e56f2;
    border-radius: 4px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: transparent;
    cursor: pointer;

    @media (max-width: 700px) {
      margin-top: 12px;
    }

    svg {
      fill: currentColor;
      color: #5e56f2;
    }

    &:disabled {
      border: 1px solid #999999;
      color: #666666;
      cursor: no-drop;

      .iconWrapper {
        svg {
          color: #666666;
        }
      }
    }
  }

  .connectedStripeButton {
    white-space: nowrap;
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #4caf50;
    border: 1px solid #4caf50;
    border-radius: 4px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: transparent;
    cursor: pointer;

    @media (max-width: 700px) {
      margin-top: 12px;
    }

    svg {
      fill: currentColor;
      color: #4caf50;
    }

    &:disabled {
      border: 1px solid #999999;
      color: #666666;
      cursor: no-drop;

      .iconWrapper {
        svg {
          color: #666666;
        }
      }
    }
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proposalsButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.addProposalBtn,
.joinMarketBtn,
.allProposalsBtn {
  white-space: nowrap;
  border: 1px solid #18397a;
  border-radius: 4px;
  padding-right: 14.5px;
  padding-left: 14.5px;
  background-color: #ffffff;
  height: fit-content;
  cursor: pointer;
  width: fit-content;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #18397a;

  @include for-mobile-max_620 {
    width: 100%;
  }
}
.allProposalsBtn {
  border: none;
}
.joinMarketBtn {
  background-color: #18397a;
  color: #ffffff;
}
.comingSoonBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  margin-top: 20px;
  border-radius: 12px;
  background-color: #ffffff;
}

.container {
  width: 100%;
  max-width: 1020px;
  border-radius: 12px;
  display: flex;
  align-content: center;
  flex-direction: column;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include for-mobile-max_767 {
    font-size: 14px !important;
  }
  @include for-desktop-up-max_1200 {
    max-width: 1020px !important;
  }
}

.deletedCompany {
  background: #fff;
  border-radius: 12px;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
}

.deletedText {
  p {
    &:last-child {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      a {
        cursor: pointer;
        color: #143471;
        text-decoration: underline;
      }
    }
    &:first-child {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
