@import '../../../../../styles/mixins';

.modal > div > div {
  border-radius: 12px !important;
  max-width: 812px !important;
  box-shadow: 0 4px 77px rgba(0, 0, 0, 0.08) !important;
}

.modalDialog {
  max-width: 812px;
  height: max-content;
  background: white;
  position: relative;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 24px 40px !important;

  @include for-mobile-max_620 {
    padding: 20px !important;
  }
  font-family: 'Montserrat', sans-serif;
}

.modalTitle {
  margin-bottom: 17px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include for-mobile-max_620 {
    font-size: 16px;
  }
  .iconTitle {
    display: flex;
    align-items: center;
    gap: 8px;

    svg > path {
      fill: rgba(211, 47, 47, 1);
    }
  }
}

.modalCloseBtn {
  padding: 10px !important;
  border-radius: 50% !important;
}

.modalActions {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.btn {
  margin-top: 32px;
  width: -webkit-fill-available;
  max-width: -webkit-fill-available;
}
