@import '/src/styles/mixins';

.socialsContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 8px;
  margin-top: 8px;

  .socialItem {
    display: flex;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 836px) {
    display: flex;
    flex-direction: column;
  }
}

.socialMediaAccountRight {
  position: relative;
  max-width: 380px;
  width: 100%;
  margin: 10px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 48px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.errorText {
  position: absolute;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d32f2f;
  margin-top: 5px;
  left: 0;
  bottom: -20px;
}
.input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}
