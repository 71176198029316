.cardProductChips {
  max-height: 100%;
  overflow: auto;
  width: 940px;
  //margin-left: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
}
.cardProductChips::-webkit-scrollbar {
  height: 5px;
}
.cardProductChips::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.cardProductChips::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}
