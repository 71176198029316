.avaDiv {
  position: relative;
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #757575;
}

.avaButton {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: visible;
}
