@import '../../../../styles/mixins';

.loader {
  border-radius: 12px !important;

  svg {
    display: none !important;
  }
}

.wrapperCheckout {
  width: 292px;
  max-width: 292px;
  min-width: 292px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

.container {
  width: 292px;
  max-width: 292px;
  min-width: 292px;
  height: fit-content;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  position: fixed;

  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);

  @media only screen and (max-width: 1170px) {
    position: relative;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }

  .button {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }

  .disableButton {
    background-color: #8c9dbd;
  }

  .checkout {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .yourCart,
    .totalPrice {
      width: 100%;

      .title,
      .price {
        color: #555555;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.16px;
      }
    }

    .totalPrice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      border-top: 1px solid #efefef;
    }

    .yourCart {
      .cartTotalBlock {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .subtotal,
        .discount,
        .carbonFootprint {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            color: #555555;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .price,
          .discountPrice,
          .footprintPrice {
            color: #212121;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .discountPrice {
            color: #d32f2f;
          }

          .footprintPrice {
            color: #4caf50;
          }

          .carbonFootprintTitleBlock {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5.6px;
          }
        }
      }
    }

    .description {
      color: #555555;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .emptyMessage {
    color: #555555;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.dataForm {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  gap: 16px;
}

.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .addInfoBtn {
    display: flex;
    gap: 12px;
    padding: 0 !important;

    span {
      display: none;
    }
    .addInfoText {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      text-transform: uppercase;
    }
  }

  .contactTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  > h2 {
    font-size: 16px;
    font-weight: 600;
  }
}

.dataFormFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dataFormRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;

  @include for-mobile-max_480 {
    flex-direction: column;
  }
  .input {
    width: 100%;
  }
}
.phoneInput {
  width: calc(50% - 5px);
  @include for-mobile-max_480 {
    width: 100% !important;
  }
}

.textarea {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-style: normal;
    font-weight: 400;
    background-color: #ffffff;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 12px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.infos {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  .infoText {
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
