@import '../../../styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px 40px;
  margin: 20px 0;

  @media screen and (max-width: 400px) {
    padding: 24px;
  }
}

.tableLoading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.pageContentBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  min-height: 400px;

  .noDataBlock {
    width: 100%;
    margin-top: 20px !important;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }

  @include for-tablet-landscape-max_1024 {
    padding: 0;
  }

  .productList {
    width: 100%;
    display: grid;
    gap: 10px;
    flex-wrap: wrap;
    margin: 16px 0;
    grid-template-columns: calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(
        20% - 8px
      );

    @media screen and (max-width: 1580px) {
      gap: 10px;
      grid-template-columns: calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(
          20% - 8px
        );
    }

    @media screen and (max-width: 1300px) {
      gap: 16px;
      grid-template-columns: calc(25% - 12px) calc(25% - 12px) calc(25% - 12px) calc(25% - 12px);
    }

    @media screen and (max-width: 1100px) {
      gap: 10px;
      grid-template-columns: calc(25% - 10px) calc(25% - 10px) calc(25% - 10px) calc(25% - 10px);
    }

    @media screen and (max-width: 1024px) {
      gap: 8px;
      grid-template-columns: calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(
          20% - 8px
        );
    }

    @media screen and (max-width: 950px) {
      gap: 12px;
      grid-template-columns: calc(25% - 10px) calc(25% - 10px) calc(25% - 10px) calc(25% - 10px);
    }

    @include for-mobile-max_767 {
      gap: 15px;
      grid-template-columns: calc((100% / 3) - 10px) calc((100% / 3) - 10px) calc((100% / 3) - 10px);
    }

    @media screen and (max-width: 570px) {
      gap: 13px;
      grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    }

    @media screen and (max-width: 400px) {
      gap: 8px;
    }

    @media screen and (max-width: 374px) {
      > div {
        width: 157px !important;
      }
    }

    @media screen and (max-width: 340px) {
      > div {
        width: 150px !important;
      }
    }

    > .noDataBlock {
      margin-top: 0 !important;
    }
  }

  .listMode {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 20px 0;

    > .noDataBlock {
      margin-top: 0 !important;
    }
  }

  .mapContainer {
    height: 672px;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);

    .map {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
    }
  }

  .walletBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 24px 40px;
    border-radius: 16px;
    border: 1px solid #efefef;
    background-color: #fff;
    margin-top: 16px;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      padding: 24px 20px;
      gap: 20px;
    }

    .description {
      color: #212121;
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      @media screen and (max-width: 630px) {
        font-size: 14px;
      }
    }

    .connectWallet {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0 20px !important;
      width: fit-content;
      min-width: fit-content;

      @media screen and (max-width: 630px) {
        width: 100%;
        min-width: 100%;
      }

      p {
        color: #fff;
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }
}

.tabsContainer {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.tabsDouble {
  > div:nth-child(1) {
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid #c4c4c4;
  }

  > div:nth-child(2) {
    border-radius: 0 0 12px 12px;
  }
}

.secondTabs {
  height: 57px !important;

  > div > span {
    display: none !important;
  }
}

.tabs {
  width: 100%;
  height: 56px;

  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  .tabItem {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    margin-top: 5px;
    padding: 0;

    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }
  }

  .activeItem {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;
  }
}

.topMenuItem {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-transform: capitalize;
}

.topSelect {
  line-height: 24px !important;
  margin-top: 12px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px !important;
  width: 100% !important;
  height: 48px !important;
  border: none !important;
  span {
    font-family: 'Montserrat' !important;
    color: #18397a !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
  }
}
.productContent {
  width: 100%;
  margin-top: 16px;
}

.paginationBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pagination {
    display: flex;
    justify-content: center;
  }
  .paginationItem {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 15px !important;
    background: #ffffff !important;
    border: none !important;
    border-radius: 12px !important;
    color: #212121 !important;
  }

  .activePaginationItem {
    border: 1px solid #143471 !important;
    border-radius: 12px !important;
  }
  .paginationText {
    margin-top: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
}

.subButtonsBlock {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;

  .subButton,
  .subButtonActive {
    font-size: 16px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 12px;
    padding: 6px 16px;
    background-color: #ffffff;
  }
  .subButtonActive {
    background-color: #ceeaff;
  }
  .btnTextMobile {
    display: inline-block;
    @include for-mobile-max_767 {
      display: none;
    }
  }
}

link,
.link {
  text-decoration: underline;
  cursor: pointer;
  color: #18397a;
}

.Wallet {
  width: 100%;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //flex-wrap: wrap;

  @media screen and (max-width: 890px) {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
  }
}

.secondRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 15px;
  }

  @media screen and (max-width: 395px) {
    align-items: flex-start;
  }
}

.modalText {
  padding: 20px 0;
}

.balanceBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 260px;

  border: none !important;
  margin: 0 !important;

  > div {
    width: fit-content;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.balance {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #212121;
}

.balanceWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;

  > div {
    margin: 0 !important;
  }

  @media screen and (max-width: 480px) {
    gap: 10px;
  }
}

.accountBox {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 10px;

  span {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 865px) {
  }
}

.box {
  width: 100%;
}

.oneBlock {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  grid-template-areas: 'accountBox balanceBox buttonBox';
}

.oneRow {
  display: grid;
  grid-template-rows: 1fr;

  grid-template-areas: 'accountBox balanceBox buttonBox';
}
