@import '/src/styles/mixins';

.modalContainer {
  max-width: 604px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .changesContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .changesItem {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    .changedValue {
      display: flex;
      align-items: center;
      gap: 2px;

      .oldValue {
        background-color: #efefef;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #757575;
        padding: 2px;
        border-radius: 2px;
      }

      .newValue {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  .changedBlock {
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 20px !important;
  }

  .changedValue {
    font-size: 14px !important;
    font-weight: 500;
    color: #000000;
  }

  .changesProduct {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;

    .productDesc {
      display: flex;
      align-items: center;
      gap: 20px;

      .productInfo {
        display: flex;
        align-items: center;
        gap: 12px;

        .productTitle {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
      }
    }
  }

  .changedInfoBlock {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .changedName {
      font-weight: 400;
      line-height: 20px;
      font-size: 12px !important;
    }
  }

  .changedTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
    flex-wrap: wrap;
  }
}
