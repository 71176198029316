@import '../../../../styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding: 20px 40px;
  margin-top: 3px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

  .backBtn {
    cursor: pointer;
  }

  @include for-mobile-max_620 {
    padding: 20px;
  }

  .titleBlock {
    display: flex;
    gap: 8px;
    align-items: center;

    .title {
      color: #212121;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;

      @include for-mobile-max_620 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }
  }

}
.accordionContainer, .errorAccordion {
  box-shadow: none;
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px !important;
  border: 1px solid rgba(196, 196, 196, 1);
  overflow: hidden !important;
  padding: 20px;
  position: relative;
  .expand {
    position: absolute;
    top: -20px;
    right: 0;

    @include for-mobile-max_480 {
      top: -12px;
    }

    .expandItems  {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 24px;

      button {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 40px !important;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding-left: 0 !important;
        padding-right: 0 !important;

      }
    }
  }

  .accordionDetails {
    padding: 0;

    .content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .selectInputMui {
        width: 100%;
        height: 49px;
        position: relative;
        @media (max-width: 800px) {
          width: 100%;
        }
        max-width: -webkit-fill-available;;
        white-space: normal;
        .menuItem {
          width: 100% !important;
          white-space: normal !important;
          max-width: -webkit-fill-available;;
        }
      }
    }
  }
}

.errorAccordion {
  border: 1px solid red ;
}
.inputSelect {
  width: 100%;
}
.helperText {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
}
.textarea {
  position: relative;
  width: 100%;
  &[data-type='location'] {
    cursor: pointer !important;
  }
  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }
  & > label {
    font-style: normal;
    font-weight: 400;
    background-color: #ffffff;
    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }
  & > div > input {
    padding: 12.5px 16px;
  }
  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;
    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}

.scrollContainer::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.scrollContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}
.slider {
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: nowrap;
  padding-bottom: 12px;
  .sliderImage,
  .sliderImageUploaded {
    width: 90px;
    height: 90px;
    min-width: 90px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .imageLoader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(6px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      .reloadBtn {
        width: 30px;
        height: 30px;
        cursor: pointer;
        svg > path {
          fill: #ffffff;
        }
      }
      .lineProgress {
        border-radius: 2px;
        width: 80px;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        span {
          background-color: #ffffff;
        }
      }
    }
    .removeImage {
      position: absolute;
      display: flex;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: 6px;
      width: 24px;
      height: 24px;
      right: 6px;
      border-radius: 2px;
      z-index: 1;
    }
    .image,
    .imageUploaded {
      height: 90px;
      width: 90px;
      border-radius: 12px;
      min-width: 90px;
      img {
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }
  .sliderImageUploaded {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px;
    position: relative;
    .playerIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      .videoPlayBtn {
        path {
          fill: white;
        }
      }
    }
    .imageUploaded {
      width: 100px !important;
      height: 100px !important;
      object-fit: cover;
    }
  }
}
.video-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Задайте максимальную ширину вашего видео */
}
.video {
  width: 100%;
  height: auto;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.buttonsGroup {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}