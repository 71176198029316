@import '/src/styles/mixins';

.itemWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;

  @include for-mobile-max_767 {
    max-width: 100%;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  overflow-wrap: anywhere;

  @include for-mobile-max_767 {
    font-size: 12px;
  }
}
