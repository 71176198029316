@import '/src/styles/mixins';

.managerRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 12px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.managerInput {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  width: 100%;
  padding-left: 12px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
}

.errorsText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  max-width: 440px;
  color: #d32f2f;
  margin-top: 10px;
}

.managerAccountSelect {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 48px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  color: #757575;

  @media (max-width: 786px) {
    margin-bottom: 20px;
  }
}

.underline {
  text-decoration: underline;
  //border-bottom: 1px solid #84b432 rgba(0, 0, 0, 5);
}
.birthday {
  margin-top: 4px;
  width: 100%;
  border-color: white;
  > div {
    > div {
      > label {
        font-family: 'Montserrat';
      }
    }
  }
}
.managerButton {
  padding-top: 5px;
  margin-right: 15px;
  margin-left: 5px;
  margin-top: 10px;
}
.buttonText {
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  font-family: 'Montserrat';
}
.formError {
  color: red;
  text-align: left;
  font-size: 12px;
  display: block;
  margin-top: 3px;
  display: none;
}

.input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
    border-radius: 12px;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}
