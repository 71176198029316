@import '../../../../../styles/_mixins.scss';

.accordionContainer {
  width: 100%;
  padding: 24px 40px;
  border-radius: 12px !important;
  background: #ffffff;
  border: none;
  box-shadow: none;
  margin: 0 !important;

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 12px;
    text-align: left;
  }

  .textarea {
    position: relative;
    width: 100%;
    margin-top: 12px;

    @include for-mobile-max_767 {
      // width: 90% !important ;
    }

    &[data-type='location'] {
      cursor: pointer !important;
    }

    &[data-type='location'] > label[data-shrink='false'] {
      transform: translate(56px, 14px) scale(1) !important;
    }

    & > label {
      font-style: normal;
      font-weight: 400;
      background-color: #ffffff;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }

    & > div {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #efefef;

      & > legend {
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .accordionSummary {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .accordionTitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #212121;
    }

    .headerText {
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-align: right;
    }
  }
  .orderActionGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @include for-mobile-max_620 {
      flex-direction: column;
    }

    .orderAction {
      margin-top: 16px;
      display: flex;
      gap: 20px;
      @include for-mobile-max_620 {
        flex-direction: column;
      }

      .buttonItem {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        @include for-mobile-max_620 {
          max-width: unset !important;
        }
        white-space: nowrap;
        img {
          filter: brightness(0) saturate(100%) invert(15%) sepia(64%) saturate(2517%)
            hue-rotate(210deg) brightness(90%) contrast(91%);
        }
      }

      .payButton {
        padding: 10px 110px !important;
        @include for-mobile-max_620 {
          padding: 10px 43px !important;
        }
      }

      @include for-mobile-max_620 {
        width: 100%;
        justify-content: space-between;
      }

      .transparentButton {
        width: 100%;
        @include for-mobile-max_620 {
          max-width: unset !important;
        }
        background-color: transparent !important;
      }
    }
  }

  .fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;

      .icon {
        cursor: pointer;
      }
    }
  }

  &:before {
    opacity: 0;
  }

  .tableCell {
    border: none;
    padding: 6px;

    .date {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    .ownerInfo {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 !important;
      position: relative;

      .ownerName {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .kybFlag {
        border-radius: 8px;
        background-color: #4caf50;
        padding: 2.5px;
        font-family: 'Montserrat';
        font-size: 6px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #ffffff;
        width: fit-content;
        margin-bottom: 26px;
        margin-top: 0;
      }
    }

    .viewButton {
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-decoration-line: underline;
      color: #143471 !important;
      white-space: nowrap;
      padding: 0 !important;
      text-transform: none;
    }
  }
}
.milestonesContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.warningNote {
  display: flex;
  align-items: center;
  svg > path {
    fill: #eb793f;
  }

  .note {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: right !important;
    .warningText {
      color: #eb793f;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
    }
  }
}
