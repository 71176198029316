@import '/src/styles/mixins';

.customerReviewContainer {
  display: flex;
  padding: 24px 60px;
  background: #fff;
  border-radius: 16px;
  margin-top: 20px;
  flex-direction: column;
  row-gap: 20px;

  .tabsContainer {
    width: 100%;

    .topMenuItem {
      font-family: 'Montserrat' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      text-transform: capitalize;
    }

    .topSelect {
      line-height: 24px !important;
      margin-top: 0 !important;
      border-radius: 16px !important;
      width: 100% !important;
      height: 48px !important;
      border: none !important;
      span {
        font-family: 'Montserrat' !important;
        color: #18397a !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
      }
    }

    .tabs {
      width: 100%;
      border-bottom: 1px solid #c4c4c4;
      justify-content: space-between;

      .tabItem {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #555555;
        text-transform: none;
        padding: 0;
        margin-left: 60px;

        &:first-of-type {
          margin-left: 0;
        }

        @media screen and (max-width: 1340px) {
          margin: 0 10px;
        }
      }
      .activeItem {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #18397a !important;
        text-transform: none;
      }
    }

    .reviewContainer {
      padding-bottom: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 20px;

      .review {
        display: flex;
        align-items: center;
        column-gap: 18px;
        margin-top: 12.54px;
        margin-bottom: 12.54px;

        .stars {
          display: flex;
          align-items: center;
          column-gap: 3px;
        }

        .reviewInfo {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #212121;
        }
      }

      .reviewCount {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #757575;
      }

      .reviewProcentContainer {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .reviewProcent {
          display: flex;
          align-items: center;
          column-gap: 20px;

          @media screen and (max-width: 600px) {
            column-gap: 8px;
          }

          .reviewText {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #212121;

            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
          }

          .procentBlock {
            height: 8px;
            width: 308px;
            background: #efefef;
            border-radius: 4px;

            @media screen and (max-width: 600px) {
              height: 6px;
              width: 190px;
            }

            .procent {
              height: 8px;
              background: #fdd835;
              border-radius: 4px;

              @media screen and (max-width: 600px) {
                height: 6px;
              }
            }
          }
        }
      }

      .buttonOutline {
        display: flex;
        align-items: center;
        column-gap: 16.41px;
        padding: 0 20px;
        border: 1px solid #18397a;
        border-radius: 4px;

        .buttonOutlineText {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 48px;
          text-transform: uppercase;
          color: #18397a;
        }
      }
    }
    .commentsContainer {
      display: flex;
      flex-direction: column;
      column-gap: 20px;

      .commentBlock {
        padding: 20px 0;
        border-top: 1px solid #efefef;
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .commentContent {
          display: flex;
          column-gap: 32px;
          row-gap: 10.45px;

          @media screen and (max-width: 780px) {
            flex-wrap: wrap;
          }

          .commentInfo {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            .commentCustomer {
              display: flex;
              align-items: center;
              column-gap: 12px;

              .customerName {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 32px;
                color: #212121;
              }
            }
            .commentDate {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #757575;
            }

            .purchaseStatusBlock {
              display: flex;
              align-items: center;
              column-gap: 5px;

              .purchaseStatus {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #2d8342;
                white-space: nowrap;
              }
            }
          }

          .comment {
            display: flex;
            flex-direction: column;
            row-gap: 12px;

            .review {
              display: flex;
              align-items: center;
              column-gap: 12px;

              .stars {
                display: flex;
                align-items: center;
                column-gap: 2px;
              }

              .productName {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #212121;
              }
            }

            .description {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #212121;
            }

            .commentImages {
              display: flex;
              align-items: center;
              column-gap: 8px;
              row-gap: 8px;
              flex-wrap: wrap;

              .image {
                width: 45px;
                height: 45px;
                border-radius: 6px;
              }
            }
          }
        }

        .commentAnswers {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          margin-left: 80px;

          @media screen and (max-width: 760px) {
            margin-left: 0;
          }

          .commentAnswer {
            display: flex;
            align-items: center;
            column-gap: 80px;

            @media screen and (max-width: 760px) {
              flex-direction: column;
              align-items: flex-start;
              row-gap: 8px;
            }

            .companyInfo {
              display: flex;
              align-items: center;
              column-gap: 20px;

              .companyName {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 32px;
                color: #212121;
              }
            }

            .answerText {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #212121;
            }
          }
        }

        .commentLike {
          display: flex;
          align-items: center;
          column-gap: 22.19px;
          position: absolute;
          top: 22px;
          right: 0;

          .like,
          .dislike {
            display: flex;
            align-items: center;
            column-gap: 10.19px;

            .count {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: #757575;
            }
          }
        }
      }
    }
  }
}
