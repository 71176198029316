@import '../../../../styles/mixins';

.container {
  display: flex;
  align-items: flex-start;

  .checkbox {
    background-color: transparent !important;
    padding-left: 0 !important;

    span {
      display: none !important;
    }
  }

  .product {
    width: 100%;
    display: flex;
    gap: 20px;

    @include for-tablet-landscape-max_1024 {
      gap: 16px;
    }

    @include for-mobile-max_620 {
      flex-direction: column;
    }

    .productImage {
      border-radius: 12px;
      width: 148px;
      height: 148px;
      position: relative;
      cursor: pointer;

      @media only screen and (max-width: 620px) {
        max-width: 100%;
        width: 100%;
        height: 100%;
      }

      > img {
        object-fit: cover;
        background-size: cover;
        width: 148px;
        height: 148px;
        border-radius: 12px;
        @media only screen and (max-width: 620px) {
          max-width: 100%;
          width: 100%;
          height: 100%;
        }
      }

      .btnFavorite,
      .btnEdit {
        background: rgba(255, 255, 255, 0.7);
        position: absolute;
        backdrop-filter: blur(10px);
        border-radius: 12px;
        top: 12px;
        right: 12px;
        color: #212121;

        :hover {
          color: #d32f2f;
        }

        font-size: 16px;
        border: none;
        cursor: pointer;
      }
    }

    .productInfo {
      width: 100%;

      .titleBlock,
      .priceBlock {
        width: 100%;
      }

      .disabledMessage {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        color: #c4c4c4;
      }

      .carbonBlock,
      .titleBlock {
        .title {
          color: #212121;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
          max-width: 350px;
          word-wrap: anywhere;

          @include for-mobile-max_620 {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.16px;
          }
        }
      }

      .titleBlock {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 20px;
        border-bottom: 1px solid #efefef;
        position: relative;
        cursor: pointer;

        @include for-mobile-max_620 {
          font-size: 14px;
          padding-bottom: 12px;
        }

        .description {
          color: #212121;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
        }

        .delete {
          position: absolute;
          top: 5px;
          right: 2px;
          cursor: pointer;
        }
      }

      .priceBlock {
        display: flex;
        align-items: center;
        padding: 20px 0;

        @include for-mobile-max_620 {
          gap: 0;
          justify-content: space-between;
          padding: 12px 0;
        }

        .priceDiscount {
          color: #d32f2f;
          font-size: 16px;
          font-weight: 500;
          text-decoration: line-through;

          @include for-mobile-max_620 {
            font-size: 12px;
          }
        }

        .price {
          color: #212121;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          width: 200px;

          @include for-mobile-max_620 {
            font-size: 20px;
            line-height: 32px;
          }

          @media screen and (max-width: 405px) {
            width: 100%;
          }
        }

        .productChangeCountBlock {
          display: flex;
          align-items: center;
          column-gap: 13px;

          .productCountInput input {
            width: 42px;
            text-align: center;
            padding: 8.5px 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }

      .carbonBlock {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        gap: 12px;

        @include for-mobile-max_620 {
          padding-top: 12px;
        }

        .title {
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;

          @include for-mobile-max_620 {
            font-weight: 500;
            line-height: 24px;
          }
        }

        .footprintBlock {
          display: flex;
          align-items: center;
          gap: 40px;

          @include for-mobile-max_620 {
            gap: 0;
            justify-content: space-between;
          }

          .footprint {
            display: flex;
            align-items: center;
            gap: 8px;

            .name {
              color: #212121;
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.container:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
