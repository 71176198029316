@import '../../../../styles/mixins';

.loader {
  border-radius: 12px !important;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .listContainer {
    padding: 20px;
    border-radius: 12px;
    background: #ffffff;

    .listHeader,
    .productList > .productListHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5px;
      border-bottom: 1px solid #efefef;

      .checkBoxLabel {
        display: flex;
        align-items: center;
        color: #212121;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .deleteButton,
      .deleteButtonMobile {
        border: none;
        background-color: transparent;
        color: #d32f2f;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        text-align: left;
        margin-left: 30px;
      }
    }
    .listHeaderCheckout {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .productList {
      .productListHeader {
        border-bottom: none;
        padding-top: 18px;

        .productListCheckboxLabel {
          display: flex;
          align-items: center;
          gap: 8px;

          .image {
            border-radius: 16px;
          }

          .text {
            color: #212121;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 18px;

        .companyAndProducts {
          display: flex;
          flex-direction: column;
          gap: 20px;
          position: relative;

          .deleteSelected,
          .deleteSelectedMobile {
            position: absolute;
            top: 10px;
            right: 0;
            cursor: pointer;
            color: #d32f2f;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .deleteSelected {
            @media screen and (max-width: 620px) {
              display: none;
            }
          }

          .deleteSelectedMobile {
            display: none;

            svg > path {
              fill: #d32f2f;
            }

            @media screen and (max-width: 620px) {
              display: block;
            }
          }

          .companyInfo {
            display: flex;
            align-items: center;
            column-gap: 12px;
            .companyName {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              color: #212121;
            }
          }
        }
      }
    }
  }
}

.checkoutBlock {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h2 {
    font-size: 16px;
    font-weight: 600;
  }
}

.checkoutBlockRadioGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkoutBlockRadio {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
