.mapModal {
  padding: 24px 40px;
  gap: 20px;
  position: absolute;
  width: 90%;
  height: 90%;
  background: #ffffff;
  border-radius: 12px;
  outline: none;
  @media (max-width: 1024px) {
    padding: 24px 20px;
    width: 79%;
  }
  @media (max-width: 850px) {
    padding: 24px 20px;
    width: 85%;
  }
}

.closeIcon {
  position: absolute !important;
  top: 20px;
  right: 11px;
  color: grey;
  @media (max-width: 1024px) {
    right: 18px;
  }
}
.modalTitle {
  margin-bottom: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  @media screen and (max-width: 388px) {
    font-size: 22px;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalRoot {
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(14px);
}

.homeMap {
  width: 100%;
  height: 20rem;
}

.mapWrapper {
  width: 100%;
  height: 82%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    height: 100%;
  }

  @media screen and (max-height: 800px) {
    height: 78%;
  }

  @media screen and (max-height: 650px) {
    height: 75%;
  }
}

.title {
  align-self: flex-start;
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
}

.buttonsWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.firstBtn {
  border: 2px solid #18397a;
  color: #18397a;
}

.secondBtn {
  background: #18397a;
  color: #ffffff;
}

.firstBtn,
.secondBtn {
  padding: 13px 28px;
  gap: 28px;
  min-width: 146px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 2px solid #ffffff;

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
  }

  &:disabled {
    background: lightgray;
    color: grey;
    border: none;
  }

  @media screen and (max-width: 395px) {
    min-width: 120px;
    padding: 11px 14px;
    font-size: 14px;
  }
}
