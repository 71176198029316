@import '/src/styles/mixins';

.container {
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }
  .topBlock {
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;
  }
  .mainTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #212121;
    @include for-mobile-max_767 {
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
  }
  .mainDescrition {
    margin-top: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #212121;

    @include for-mobile-max_767 {
      font-size: 14px;
    }
  }
  .searchArea {
    margin-top: 20px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    @include for-mobile-max_767 {
      flex-direction: column;
      row-gap: 16px;
      align-items: flex-start;
    }
  }
  .searchInput {
    position: relative;
    width: 100%;

    .mapIcon {
      height: 22px !important;
      width: 26px !important;
    }

    &[data-type='search'] {
      cursor: pointer !important;
    }

    &[data-type='search'] > label[data-shrink='false'] {
      transform: translate(20px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .outlineButton {
    display: flex;
    background-color: transparent;
    border: 1px solid #18397a;
    border-radius: 4px;
    padding: 0 20px;
    cursor: pointer;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
    white-space: nowrap;
    @media (max-width: 900px) {
      font-size: 12px;
    }
  }

  .dropableBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .groupItem {
    padding: 20px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background-color: #ffffff;
    .groupHeader {
      display: flex;
      justify-content: space-between;

      @include for-mobile-max_620 {
        flex-direction: column;
        row-gap: 12px;
      }

      .nameContainer {
        display: flex;
        align-items: center;
        column-gap: 12px;
        width: calc(100% - 240px);
        @media screen and (max-width: 620px) {
          width: 100%;
        }

        .avatar {
          border-radius: 24px;
          overflow: hidden;
        }
      }

      .groupName {
        width: calc(100% - 56px);

        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 32px;
          color: #212121;
          width: 100%;
          word-wrap: break-word;
        }
      }
      .buttonsBlock {
        display: flex;
        align-items: center;
        column-gap: 20px;

        .editButton {
          padding: 16.77px 24.77px 17.57px 25.56px;
          border: 1px solid #18397a;
          border-radius: 4px;
        }
        .deleteButton {
          padding: 13.5px 23px 15px 23px;
          border: 1px solid #18397a;
          border-radius: 4px;
        }
      }
    }
    .groupMain {
      display: flex;
      gap: 24px;
      margin-top: 13px;
      margin-bottom: 15px;

      .groupMainItem {
        display: flex;
        align-items: center;
        column-gap: 11px;

        p {
          margin: 0;
          padding: 0;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #424242;
        }
      }
    }
    .itemDescription {
      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        word-break: break-word;
      }
    }
  }
  .paginationBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    .pagination {
      display: flex;
      justify-content: center;
    }
    .paginationItem {
      font-family: 'Montserrat' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      padding: 8px 15px !important;
      background: #ffffff !important;
      border: 1px solid #efefef;
      border-radius: 12px !important;
      color: #212121 !important;
    }

    .activePaginationItem {
      border: 1px solid #143471 !important;
      border-radius: 12px !important;
    }
    .paginationText {
      margin-top: 4px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #757575;
    }
  }
}
.backButton {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #18397a;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 675px) {
  .groupMain {
    flex-direction: column;
  }
}
