@import '../../../../../styles/mixins';

.tabContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 20px;

    .blockWithButton {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .leftSide {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      @include for-desktop-up-max_1200 {
        flex-direction: column;
        gap: 12px;
      }

      @include for-tablet-landscape-max_1024 {
        flex-direction: row;
        justify-content: space-between;
      }
      @include for-tablet-landscape-max_900 {
        flex-direction: column;
        gap: 12px;
      }
    }

    .parameters {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;

      span {
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 0;
        margin-left: 12px;
      }
    }

    .label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #212121;
    }

    .description {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }

    .milestoneItem {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
