.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;

  a {
    color: #18397A;
  }
}

.imgWrapper {
  width: 100%;
  max-width: 176px;
  padding: 0 20px;
}

.text {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
