@import '../../../styles/mixins';

.backBtn {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #212121;
  padding: 11px 18px 11px 14px;
  border-radius: 12px;
  background-color: #fff;
  @media screen and (max-width: 460px) {
    width: 100%;
    margin-right: 8px;
  }
}

.pageContentBox {
  display: flex;
  flex-direction: column;
  max-width: 1020px;
  width: 100%;
  margin-bottom: 106px;
  position: relative;

  .slider {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex-wrap: nowrap;

    .sliderImage {
      width: 90px;
      height: 90px;
      min-width: 90px;
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .filterContent {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 4px;

    .backBtn {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #212121;
      @media screen and (max-width: 460px) {
        display: none;
      }
    }
    .filterBar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 16px;

      @media screen and (max-width: 460px) {
        flex-direction: column;
      }

      .mobileFilterBar {
        display: none;
        @media screen and (max-width: 460px) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          .backBtn {
            display: flex !important;
          }
          .shoppingCart {
            display: flex !important;
          }
        }
      }

      .searchFilter,
      .shoppingCart {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
      }
      .searchFilter {
        width: 100%;
        gap: 12px;
        padding: 7px 20px;

        div {
          border-radius: 0 !important;
          border: 0 !important;
        }
        div:hover {
          box-shadow: none !important;
        }
      }
      .shoppingCart {
        width: fit-content;
        cursor: pointer;
        padding: 11px 17.4px;
        gap: 4px;
        @media screen and (max-width: 460px) {
          display: none;
        }
      }
    }
  }

  .statusBlock {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 10px;
    margin-top: 4px;
  }

  .serviceStatus {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
    border-radius: 12px;
    background-color: #ffffff;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-transform: capitalize;
  }

  .serviceContent {
    padding: 24px 60px;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    position: relative;

    @media screen and (max-width: 1024px) {
      padding: 24px 40px;
    }

    @media screen and (max-width: 900px) {
      border-radius: 12px;
    }

    @media screen and (max-width: 500px) {
      padding: 24px 20px;
    }

    .serviceHeader {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;

      .companyInfo {
        display: flex;
        align-items: center;
        column-gap: 12px;

        .companyName {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #212121;
        }
      }

      .headerActions {
        display: flex;
        align-items: center;
        gap: 20px;

        .actionBtn {
          padding: 12px 20px !important;
          width: 64px;
          height: 48px;
        }
        @media screen and (max-width: 440px) {
          .actionBtn {
            min-width: 50px !important;
            padding: 12px 16px !important;
          }
        }
      }
    }

    .serviceInfoContainer {
      display: flex;
      column-gap: 20px;

      @media screen and (max-width: 800px) {
        flex-direction: column;
        row-gap: 20px;
      }

      .serviceImages {
        display: flex !important;
        flex-direction: column;
        row-gap: 12px;
        max-width: 428px;
        width: 100%;

        .selectImage {
          width: 100%;
          max-height: 426px;
          border-radius: 20px;
          position: relative;
          overflow: hidden;

          @media screen and (max-width: 800px) {
            max-height: none;
          }
          .serviceImage {
            width: 100%;
          }

          .favorites {
            position: absolute;
            top: 20px;
            right: 20px;
            background: rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(10px);
            border-radius: 12px;
            padding: 10px;
            max-height: 40px;
            cursor: pointer;
          }
        }
      }

      .serviceInfo {
        @media screen and (max-width: 800px) {
          max-height: unset;
        }
        .companyBlock {
          display: flex;
          align-items: center;
          width: 100%;
          padding-bottom: 20px;
          border-bottom: 1px solid #efefef;
          .companyBlockTitle {
            font-size: 15px;
            font-weight: 500;
          }
          .companyInfo {
            display: flex;
            align-items: center;
            margin-left: 12px;
            a {
              margin-left: 4px;
            }
          }
        }
        .serviceName {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #000000;
          width: 100%;
          overflow: hidden;
          word-break: break-all;
        }
        .viewLink {
          color: #143471;
          text-decoration: underline;
          cursor: pointer;
        }

        .reviewsInfo {
          display: flex;
          border-bottom: 1px solid #efefef;
          padding-top: 16px;
          padding-bottom: 20px;
          column-gap: 20px;
          margin-bottom: 20px;
        }

        .review {
          display: flex;
          align-items: center;
          column-gap: 8px;

          .stars {
            display: flex;
            align-items: center;
            column-gap: 3px;
            img {
              width: 24px;
            }
          }

          .reviewInfo {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
          }
        }

        .orderedCount {
        }

        .servicePriceTitle {
          font-size: 12px;
        }

        .servicePrice {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: #212121;
          margin-bottom: 16px;

          span {
            color: #757575;
            font-weight: 600;
            font-size: 16px;
          }
        }

        .greenFlag {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjc5IiBoZWlnaHQ9IjgwIiB2aWV3Qm94PSIwIDAgMjc5IDgwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMCAyQzAgMC44OTU0MjkgMC44OTU0MzEgMCAyIDBIMjQ3VjgwSDJDMC44OTU0MzIgODAgMCA3OS4xMDQ2IDAgNzhWMloiIGZpbGw9IiM0Q0FGNTAiIGZpbGwtb3BhY2l0eT0iMC4yIi8+CjxwYXRoIGQ9Ik0wIDU2SDI1OC41NDlDMjU5LjQ2NSA1NiAyNjAuMzMyIDU2LjQxOTEgMjYwLjkwMSA1Ny4xMzc5TDI3NS4xNTEgNzUuMTM3OUMyNzYuNzA4IDc3LjEwNDYgMjc1LjMwNyA4MCAyNzIuNzk5IDgwSDNDMS4zNDMxNCA4MCAwIDc4LjY1NjkgMCA3N1Y1NloiIGZpbGw9IiM0Q0FGNTAiLz4KPHBhdGggZD0iTTI0NyAwSDI3Mi43NThDMjc1LjI3NCAwIDI3Ni42NzIgMi45MDk4IDI3NS4xMDEgNC44NzQwOUwyNDguNDk5IDM4LjEyNTlDMjQ3LjYyMyAzOS4yMjE2IDI0Ny42MjMgNDAuNzc4NCAyNDguNDk5IDQxLjg3NDFMMjc1LjEwMSA3NS4xMjU5QzI3Ni42NzIgNzcuMDkwMiAyNzUuMjc0IDgwIDI3Mi43NTggODBIMjQ3VjBaIiBmaWxsPSIjNENBRjUwIiBmaWxsLW9wYWNpdHk9IjAuMiIvPgo8L3N2Zz4K');
          background-size: contain;
          min-width: 303px;
          background-repeat: no-repeat;
          padding: 4px 12px;
          margin-top: 24px;

          .offerText {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          .offerPrice {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #212121;

            span {
              color: #757575;
              font-weight: 600;
              font-size: 14px;
            }
          }
          .offerDiscountPrice {
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: white;
          }
        }

        .flagEmpty {
          background-image: unset;
          min-height: 160px;
        }

        .serviceCountContainer {
          display: flex;
          align-items: center;
          column-gap: 29px;
          row-gap: 20px;
          flex-wrap: wrap;
          margin: 20px 0;

          .serviceChangeCountBlock {
            display: flex;
            align-items: center;
            column-gap: 13px;

            .serviceCountInput input {
              text-align: center;
              padding: 8px 12px;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #212121;
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }

          .serviceAvailableCount {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
          }
        }

        .serviceButtonGroups {
          display: flex;
          align-items: center;
          column-gap: 12px;
          row-gap: 12px;
          padding-top: 24px;
          padding-bottom: 20px;
          flex-wrap: wrap;

          .greenBtn {
            background-color: #4caf50 !important;
            border: none !important;
            color: white;
          }

          .serviceButton {
            .serviceButtonText {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 48px;
              text-transform: uppercase;
              color: #ffffff;
              white-space: nowrap;
            }
          }
          .serviceButtonOutline {
            display: flex;
            align-items: center;
            column-gap: 15px;
            padding: 0 20px;
            border: none;
            background-color: #4caf50;
            border-radius: 4px;

            .serviceButtonOutlineText {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 48px;
              text-transform: uppercase;
              color: white;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .tabsContainer {
    width: 100%;
    background: #ffffff;
    padding: 12px 60px;

    @media screen and (max-width: 1024px) {
      padding: 12px 40px;
    }

    @media screen and (max-width: 899px) {
      border-radius: 16px;
      margin: 12px 0;
      padding: 0;
    }

    .topMenuItem {
      font-family: 'Montserrat' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      text-transform: capitalize;
    }

    .topSelect {
      line-height: 24px !important;
      margin-top: 0 !important;
      background: #ffffff !important;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
      border-radius: 16px !important;
      width: 100% !important;
      height: 48px !important;
      border: none !important;
      span {
        font-family: 'Montserrat' !important;
        color: #18397a !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
      }
    }

    .tabs {
      width: 100%;
      border-bottom: 1px solid #c4c4c4;
      justify-content: space-between;

      .tabItem {
        @media screen and (max-width: 1340px) {
          margin: 0 10px;
        }

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
        text-transform: none;
        padding: 0;
      }
      .activeItem {
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: #143471 !important;
        text-transform: none;
      }
    }
  }

  .serviceInformationContainer {
    width: 100%;
    background: #ffffff;
    padding: 8px 60px 24px 60px;
    border-radius: 0 0 12px 12px;

    @media screen and (max-width: 1024px) {
      padding: 8px 40px 24px 40px;
    }

    @media screen and (max-width: 899px) {
      padding: 24px 40px;
      border-radius: 12px;
    }

    @media screen and (max-width: 500px) {
      padding: 24px 20px;
    }

    .comingSoonBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 60px;
    }
  }

  .discountPriceBlock {
    margin: 24px 0;

    .oldPriceBlock {
      position: relative;
      width: fit-content;

      .oldPrice {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #757575;
      }

      .oldPriceLine {
        width: 100%;
        height: 2px;
        background-color: #d32f2f;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .discountPrice {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #d32f2f;

      span {
        color: #757575;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
