.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.btnCancel {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 100px;
  height: 48px;
  border: 1px solid #18397a;
  border-radius: 5px;
  color: #18397a;
  background: transparent;
}
.btnSave {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  width: 179px;
  height: 48px;
  border-radius: 6px;
  background-color: #18397a;
  color: #ffffff;
}
