@import '/src/styles/mixins';

.container {
  width: 100%;
  height: 2%;
  display: flex;
  align-content: center;
  padding-left: 100px;
  background: #ffffff;
  opacity: 0.6;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  background-size: contain;
  @include for-mobile-max_767 {
    padding-left: 30px;
  }
}
.breadcrumb {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
}
