.menuItemWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 142px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.delete {
  color: #d32f2f;
}

.shareBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}
