@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }
  .content {
    margin-top: 12px;
  }
  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
  }
  .subTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 12px;

    @include for-mobile-max_620 {
      font-size: 14px;
    }
  }
  .departmentBlock {
    display: flex;
    align-items: center;
    column-gap: 53px;

    @include for-mobile-max_620 {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    .departmentBlockItem {
      display: flex;
      align-items: center;
    }

    .departmentBlockText {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #212121;
      margin-left: 10.25px;
    }
  }

  .departmentBlock:not(:last-child) {
    margin-bottom: 24.5px;
  }
  .socialBlock {
    display: flex;
    column-gap: 20px;
    margin-top: 20px;
  }
  .editButton {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 33px;
    right: 65px;
    cursor: pointer;

    @include for-mobile-max_620 {
      top: 25px;
      right: 25px;
    }

    .editing {
      display: flex;
      align-items: center;
      column-gap: 16.8px;

      button {
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        outline: none;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        text-align: right;
        text-transform: uppercase;
        color: #18397a;
        cursor: pointer;
      }
    }
  }
}
.socialButton {
  cursor: pointer;
}

.contactsColumn {
  .socialAccountLeft {
    display: flex;
    flex-direction: column;

    > input {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      width: 440px;
      height: 48px;
      padding-left: 12px;
      background: white;
      border-radius: 12px;
      border: 1px solid #efefef;
    }
  }

  .errorsText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    max-width: 440px;
    color: #d32f2f;
    margin-top: 10px;
  }

  .socialAccountRight {
    display: flex;
    width: 100%;
    margin-top: 20px;
    column-gap: 20px;

    @media (max-width: 1024px) {
      flex-direction: column;
      row-gap: 20px;
    }

    .form_input {
      max-width: 440px;
      width: 100%;

      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }

    > input {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      max-width: 440px;
      width: 100%;
      height: 48px;
      padding-left: 12px;
      background: white;
      border-radius: 12px;
      border: 1px solid #efefef;

      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }
  }

  .phone {
    width: 100%;
  }

  .phoneInput {
    height: 48px !important;
    background: #ffffff !important;
    border: 1px solid #efefef;
    border-radius: 12px;
    padding-left: 120px !important;
    max-width: 440px !important;
    width: 100% !important;

    @media (max-width: 1024px) {
      max-width: 100% !important;
    }
  }

  .removeContactButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin-top: 16px;
    cursor: pointer;
    padding: 0 20px 0 24px;
    border: none;

    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 48px;
      color: #18397a;
      text-transform: uppercase;
      margin-left: 16px;
      margin-bottom: 0;
      margin-top: 0;
    }

    @media (max-width: 500px) {
      margin-top: 12px;
    }
  }
}

.addContactButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 16px;
  border: 1px solid #18397a;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 20px 0 24px;

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    color: #18397a;
    text-transform: uppercase;
    margin-left: 17px;
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 500px) {
    margin-top: 12px;
  }
}

.errorMessage {
  color: #d32f2f;
  font-size: 14px;
}
