@import '/src/styles/mixins';

.accountColumn {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: stretch;
  @include for-tablet-landscape-max_900 {
    flex-direction: column;
  }
  .accountLogoInputContainer {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 15px 0 0 0;

    @media (max-width: 900px) {
      width: 100%;
    }

    .accountInputsSmall {
      margin-top: 0;
    }
  }

  .accountInputContainer {
    margin: 15px 0 0 0;
  }

  .accountInputsSmall {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 48px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
      border: 1px solid #212121;
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }
}

.errorsText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d32f2f;
  margin-top: 5px;
}

.accountSelect {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 48px;

  @media (max-width: 1025px) {
    width: 100%;
  }
  @media (max-width: 980px) {
    width: 100%;
  }
}

.underline {
  color: #84b432;
  text-decoration: underline;
  //border-bottom: 1px solid #84b432 rgba(0, 0, 0, 5);
}

.radioGroup {
  display: flex;
  align-items: center;
  column-gap: 34px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.radioGroupTitle {
  margin-bottom: 12px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #212121;
}

.center {
  max-width: 100%;
  margin-top: 20px;
}

.modal_input {
  position: relative;
  width: 100%;

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(20px, 14px) scale(1) !important;
  }

  & > label {
    font-style: normal;
    font-weight: 400;
    background-color: #ffffff;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.centerInput {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 48px;
  padding-left: 12px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
}

.accountCenterRow {
  display: flex;
  flex-direction: column;
  column-gap: 20px;

  @media (max-width: 200px) {
    display: block;
  }

  @media (max-width: 1440px) {
    display: flex;
  }

  @media (max-width: 1024px) {
    display: block;
  }

  .accountColumn > .accountInputContainer:nth-child(2) {
    @include for-tablet-landscape-max_900 {
      margin-top: -5px;
    }
  }
}

.accountLogo {
  position: relative;
  overflow: hidden;
  width: 108px;
  height: 108px;
  border-radius: 50%;

  @media (max-width: 900px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 1024px) {
    margin: 0 auto;
  }

  &:hover {
    .accountLogoHover {
      transition: opacity 0.3s ease-out;
      opacity: 1;
    }
  }

  .accountLogoHover {
    background-color: #05050598;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    width: 100%;
    opacity: 0;

    img {
      width: 25px;
      height: 23px;
      margin-left: 34px;

      @media (max-width: 1024px) {
        width: 18px;
        height: 16px;
        margin-left: 28px;
      }
    }

    @media (max-width: 1024px) {
      padding: 2px;
    }
  }
}

.accountLogoImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.accountLogoOverlay {
  width: 128px;
  height: 128px;
  background: #757575;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  img {
    width: 25px;
    height: 23px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;

    @media (max-width: 900px) {
      width: 18px;
      height: 16px;
    }
  }

  @media (max-width: 900px) {
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }
}

.accountInputsRow {
  margin-top: 10px;

  & > div {
    display: flex;
    column-gap: 20px;

    @media (max-width: 900px) {
      flex-direction: column;
      //margin-bottom: 20px;
    }
  }
}

.accountInputsMedium {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  padding-left: 12px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  margin-right: 10px;

  @media (max-width: 980px) {
    width: 100%;
  }
}

.datePicker {
  width: 100%;
  color: #757575;
  height: 48px;
  border-radius: 12px;
}

.donationContainer {
  margin: 10px 0 0 0;
}

.input {
  position: relative;
  width: 100%;

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-style: normal;
    font-weight: 400;
    background-color: #ffffff;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
    border-radius: 12px;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}

.greenBlock {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0;
  gap: 20px;

  @include for-tablet-landscape-max_900 {
    flex-direction: column;
    gap: 15px;
    margin: 15px 0 0 0;
  }

  .greenContent {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    .greenContentText {
      align-items: center;
      height: 60px;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 500;
      color: #84b432;
      line-height: 20px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @include for-tablet-landscape-max_900 {
        -webkit-line-clamp: 4;
      }
    }
  }
}
