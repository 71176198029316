@import '/src/styles/mixins';

.container {
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1024px) {
    padding: 24px 40px;
  }
  @include for-mobile-max_767 {
    padding: 12px 20px;
  }

  .chip {
    background: #efefef !important;
    border-radius: 12px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    border: none;
    color: #212121;
  }

  .pageTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .imagesBlock {
    border: 1px solid #efefef;
    border-radius: 12px;
    padding: 20px;

    .imagesDropContainer {
      display: flex;
      align-items: center;
      cursor: pointer;

      @include for-mobile-max_620 {
        flex-direction: column;
        align-items: flex-start;
      }

      .iconContainer {
        padding: 20px 28px 20px 20px;
        border-right: 1px solid #efefef;

        @include for-mobile-max_620 {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 20px 20px 28px 20px;
          border-right: none;
          border-bottom: 1px solid #efefef;
        }
      }

      .imagesBlockText {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        margin-left: 8px;

        @include for-mobile-max_620 {
          margin-left: 0;
          margin-top: 8px;
        }

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212121;

        .imagesBlockTitle {
          font-weight: 600;
        }

        .imagesBlockDescription {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    .imagesList {
      display: flex;
      row-gap: 16px;
      column-gap: 16px;
      flex-wrap: wrap;
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid #efefef;

      .image {
        width: 156px;
        height: 156px;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        .imageLoader {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(6px);
          display: flex;
          justify-content: center;
          align-items: center;
          .reloadBtn {
            width: 30px;
            height: 30px;
            cursor: pointer;
            svg > path {
              fill: #ffffff;
            }
          }

          .lineProgress {
            border-radius: 2px;
            width: 80px;
            height: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            span {
              background-color: #ffffff;
            }
          }
        }

        .removeImage {
          position: absolute;
          display: flex;
          background: rgba(0, 0, 0, 0.4);
          backdrop-filter: blur(2px);
          justify-content: center;
          align-items: center;
          cursor: pointer;
          top: 6px;
          width: 24px;
          height: 24px;
          right: 6px;
          border-radius: 2px;
          z-index: 1;
        }
        @include for-mobile-max_767 {
          width: 110px;
          height: 110px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .form_input {
    position: relative;

    input {
      border-radius: 12px;
    }
    label {
      line-height: normal;
    }
    & > div {
      height: 49px;
    }
  }

  .selectInputMuiContainer {
    width: calc(50% - 8px);
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  .langInput {
    width: 100%;
    & > div {
      height: fit-content;
      min-height: 57px;
    }
  }

  .inputsColumns {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .inputsRows {
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 12px;
      width: 100%;
    }
    .selectInput {
      width: calc(50% - 8px);
      @media (max-width: 800px) {
        width: 100%;
      }
    }

    .selectInputMui {
      width: 100%;
      height: 49px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }

  .textarea {
    width: 100%;
    min-height: 72px;

    textarea {
      min-height: 72px !important;
    }
  }

  .inputWithError {
    display: flex;
    flex-direction: column;
    .tagsInput {
      label:not(.Mui-focused) {
        max-width: calc(100% - 120px) !important;
      }
    }
  }
}
.menuItemContent {
  gap: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 12px 0 12px 0;

  .categoryName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.tooltipContent {
  padding-left: 20px;
}
.addBtn {
  display: flex;
  border: none !important;
  color: #18397a !important;
  background-color: transparent !important;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  span:not(.addTextMobile) > span {
    display: none;
  }

  .addText {
    font-size: 16px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px;
    text-transform: none;

    .addTextMobile {
      @include for-mobile-max_767 {
        display: none;
      }
    }
  }
}

.addSuggestionBlock {
  margin-top: 12px;
  border-top: 1px solid #efefef;

  .addSuggestionBtn {
    width: 100%;
    max-width: unset;
    justify-content: flex-start;
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: none;
  }
}

.categoryControl {
  @media (max-width: 800px) {
    width: 100%;
  }
}
