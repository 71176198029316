@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../assets/fonts/montserrat/montserrat-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../assets/fonts/montserrat/montserrat-semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../assets/fonts/montserrat/montserrat-bold.ttf') format('truetype');
}

html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#root {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  height: 100vh;
}

.MuiAlert-message {
  max-width: 95vw !important;
  word-break: break-all !important;
}

.main-button {
  margin: 0 auto;
  max-width: max-content;
  background: none;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 15px 76px;
  color: #ffffff;
  cursor: pointer;
}

.main-button:disabled {
  background: lightgray;
  color: grey;
  border: 1px solid grey;
}

.main-button:hover {
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.swiper-pagination {
  bottom: -4px !important;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 7px !important;
  height: 7px !important;
  background: #efefef !important;
  opacity: 1 !important;
}

.swiper-pagination .swiper-pagination-bullet-active {
  width: 15px !important;
  background: #c4c4c4 !important;
  border-radius: 12px !important;
}

.MuiModal-root {
  z-index: 1000 !important;
}
.MuiOutlinedInput-notchedOutline:not(.Mui-error) {
  border: 2px solid #efefef !important;
}

.MuiInputBase-root:not(.Mui-disabled):hover {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.04) !important;
}

.MuiInputBase-root.Mui-disabled {
  background-color: #efefef !important;
  border-radius: 12px !important;
}

.MuiInputBase-root {
  border-radius: 12px !important;
}

.MuiInputBase-root.Mui-error > fieldset {
  border-radius: 12px !important;
  border: 1px solid red !important;
}

.MuiInputAdornment-root .MuiInputBase-root:first-of-type {
  height: 100% !important;
  border: none !important;
}

.MuiInputAdornment-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiFormLabel-root.Mui-focused:not(.Mui-error) {
  color: #757575;
  background-color: #ffffff;
}
.MuiFormLabel-root.MuiInputLabel-shrink.Mui-error {
  color: #d32f2f;
  background-color: #ffffff;
}
.unpaycss-xe70ge-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #ffffff;
}
.MuiFormLabel-root.Mui-focused.Mui-error {
  background-color: #ffffff;
}
.icon-blue-fill svg > path {
  fill: #18397a !important;
}

.icon-blue-stroke svg > path {
  stroke: #18397a !important;
}

.icon-red-fill svg > path {
  fill: #d32f2f !important;
}

.icon-red-stroke svg > path {
  stroke: #d32f2f !important;
}

.icon-green-fill svg > path {
  fill: #4caf50 !important;
}

.icon-green-stroke svg > path {
  stroke: #4caf50 !important;
}

.icon-white-fill svg > path {
  fill: #ffffff !important;
}

.icon-white-stroke svg > path {
  stroke: #ffffff !important;
}

.MuiList-root::-webkit-scrollbar {
  width: 4px;
}

.MuiList-root::-webkit-scrollbar-track {
  border-radius: 4px;
}

.MuiList-root::-webkit-scrollbar-thumb {
  background: rgba(168, 168, 168, 0.78);
  border-radius: 2px;
}

.MuiList-root::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

input[type='number'] {
  -moz-appearance: textfield;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(168, 168, 168, 0.78) #efefef; /* цвет скроллбара и трека */
}
