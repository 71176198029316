@import '../../../../styles/_mixins.scss';

.cardContainerStyle {
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.loaderBox {
  display: flex;
  align-items: center;
  height: 41px;
}

.container {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 188px;
    min-height: 188px;
    object-fit: cover;
  }

  .btnFavorite,
  .btnEdit {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    top: 12px;
    right: 12px;
    color: #212121;

    :hover {
      color: #d32f2f;
    }

    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .btnEdit {
    :hover {
      color: #212121;
    }
  }
}
.cardBody {
  padding: 0 14px 6px 14px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .text {
    flex-direction: column;
    display: flex;
    gap: 4px;

    .availability {
      font-size: 12px;
      font-weight: 400;
      text-transform: capitalize;
    }

    & > p {
      display: inline-block;
      width: 154px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }

  .availableBlock {
    font-size: 12px;
    font-weight: 400;

    > span {
      color: #4caf50;
    }
  }

  .productRatingBar {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .ratingBarItems {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }
  .inProgressText {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 55px;

    .priceBlock {
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    .price,
    .discountPrice {
      font-weight: 600;
      font-size: 15px;
      color: #000000;
    }

    .discountPrice {
      color: #d32f2f;
    }

    .oldPrice {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #757575;
      text-decoration: line-through;
      text-decoration-color: #d32f2f;
    }

    .btn {
      padding: 0 20px !important;
      line-height: 40px !important;
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .btn {
    max-width: 64px !important;
  }
  .price,
  .discountPrice {
    font-size: 13px !important;
    white-space: nowrap;
  }
  .cardBody {
    padding: 0 12px 14px 12px;
  }
}
