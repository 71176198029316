@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }
}
.tabsItem {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  color: #212121;
}
.tabsItemActive {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #143471 !important;
}
.kybItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  .kybTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }
  & > button {
    padding: 0;
    :hover {
      background-color: transparent;
      border: none;
    }
  }
}
