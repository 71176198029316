.platformText {
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  margin: 0px 60px;
  margin-bottom: 20px;
  padding-top: 30px;

  @media (max-width: 880px) {
    margin: 0px 30px;
    margin-bottom: 20px;
  }
}

.platformDiv {
  max-width: 1020px;
  margin-top: 20px;
  padding-bottom: 30px;
  border-radius: 12px;
  background: white;
}

.platformColumnLeft {
  margin-left: 60px;
}

.platformColumnRight {
  margin-left: 20px;
}

.circleText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  max-width: 368px;
  font-family: 'Montserrat';
  padding-top: 16px;
}

.blockRow {
  display: flex;
  align-items: center;
  column-gap: 20px;

  @media (max-width: 880px) {
    margin-bottom: 20px;
  }
}

.row {
  display: flex;
  align-items: center;
  margin: 0 60px;
  justify-content: space-between;
  column-gap: 15px;

  @media (max-width: 1411px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 880px) {
    flex-direction: column;
    margin: 0 30px;
  }
}

.platformGoals {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #18397a;
  margin-top: 16px;
  border: 1px solid #18397a;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 20px 0 24px;
  text-transform: uppercase;
  white-space: nowrap;
}
