@import '/src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .postItem {
    background: #ffffff;
    padding: 20px 32px 14px 32px;
    position: relative;
    border-radius: 12px;
    background: var(--color-black-black-0-ffffff, #fff);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

    @include for-mobile-max_620 {
      padding: 16px 20px;
      margin-top: 12px;
    }

    .postHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .optionsButton {
        padding: 10px;
        border-radius: 50%;
        width: 35px;
        height: 35px;
      }
      .avatarInfo {
        display: flex;
        align-items: center;
        gap: 8px;

        .nameBlock {
          display: flex;
          flex-direction: column;

          .name {
            font-family: 'Montserrat';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
          }

          .activity {
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .postContent {
      margin: 16px 0 28px 0;
    }
    .postInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      @include for-tablet-landscape-max_1024 {
        border-bottom: 1px solid #efefef;
      }

      .emojiBlock {
        display: flex;
        align-items: center;
        gap: 12px;

        .reactedText {
          margin-left: -7px;
          @include for-mobile-max_767 {
            display: none;
          }
        }
      }
      .commentsCountBlock {
        display: flex;
        align-items: center;
        gap: 60px;
      }
    }

    .postActions {
      padding: 6px 0 6px 30px;
      display: flex;
      margin-top: 16px;
      align-items: center;
      justify-content: space-between;

      .actionItem {
        display: flex;
        align-items: center;
        gap: 8px;

        .actionName {
          @include for-mobile-max_620 {
            display: none;
          }
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}

.inputOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ::before,
  ::after {
    border: none !important;
  }

  *:focus {
    background: transparent !important;
  }
}

.select {
  > div > div {
    font-size: 16px;
    font-weight: 500;

    > img {
      height: 26px;
    }

    @media only screen and (max-width: 676px) {
      font-size: 14px;
    }
  }
}

.selectBody {
  box-shadow: 0 4px 32px 0 #00000014 !important;
  border-radius: 12px !important;

  li {
    margin: 5px 0;
    background-color: transparent !important;

    &:hover {
      background-color: inherit !important;
      color: #143471 !important;
    }

    & > div > span {
      display: block !important;
    }
  }
}

.selectItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  > img {
    width: 24px !important;
    height: 24px !important;
    margin-right: 9px;
  }
}

.avatar {
  width: 30px !important;
  height: 30px !important;
  font-size: 12px !important;
  margin-right: 10px;
}

.menuItem__title {
  display: none;
}
