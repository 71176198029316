@import '/src/styles/mixins';

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.filterBar {
  display: flex;
  gap: 20px;

  .filterSelect {
    width: 40%;
    @include for-mobile-max_767 {
      width: unset;
    }
    & > div {
      padding: 12px 14px;
    }
  }

  @include for-mobile-max_767 {
    flex-direction: column;
    gap: 12px;
  }

  .datePicker {
    width: 45%;
    @include for-mobile-max_767 {
      width: unset;
    }
    label {
      line-height: unset;
      max-width: calc(100% - 50px);
    }
    & > div > input {
      padding: 12.5px 16px;
    }
  }
}

.loadingBox {
  display: flex;
  justify-content: center;
  padding: 64px 0;
  width: 100%;
}

.searchInput {
  position: relative;
  width: 70%;
  @include for-mobile-max_767 {
    width: unset;
  }

  span[role='img'] {
    display: inline-grid !important;

    svg {
      height: 24px;
      width: 24px;

      g > path {
        fill: #555555 !important;
      }

      > path {
        fill: #555555 !important;
      }
    }
  }

  .mapIcon {
    height: 22px !important;
    width: 26px !important;
  }

  &[data-type='search'] {
    cursor: pointer !important;
  }

  &[data-type='search'] > label[data-shrink='false'] {
    transform: translate(20px, 14px) scale(1) !important;
  }

  & > label {
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}

.tabsContainer,
.orderTabContent {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include for-mobile-max_620 {
    gap: 12px;
  }
  .topMenuItem {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-transform: capitalize;
  }

  .topSelect {
    line-height: 24px !important;
    margin-top: 0 !important;
    background: #ffffff !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 16px !important;
    width: 100% !important;
    height: 48px !important;
    border: none !important;
    span {
      color: #18397a !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 16px !important;
    }
  }

  .tabs {
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
    justify-content: space-between;

    .tabItem {
      @media screen and (max-width: 1340px) {
        margin: 0 10px;
      }

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      text-transform: none;
      padding: 0;
    }
    .activeItem {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #143471 !important;
      text-transform: none;
    }

    .disableTab {
      opacity: 0.4;
    }
  }

  .paginationBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    .pagination {
      display: flex;
      justify-content: center;
    }
    .paginationItem {
      font-family: 'Montserrat' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      padding: 8px 15px !important;
      background: #ffffff !important;
      border: 1px solid #efefef;
      border-radius: 12px !important;
      color: #212121 !important;
    }

    .activePaginationItem {
      border: 1px solid #143471 !important;
      border-radius: 12px !important;
    }
    .paginationText {
      margin-top: 4px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #757575;
    }
  }
}
