@import '/src/styles/mixins';

.modalContainer {
  max-width: 604px;
  .content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .inputsRow {
      display: flex;
      gap: 20px;
    }

    .inputsRowAddress {
      display: flex;
      gap: 12px;
    }

    .textField {
      position: relative;
      width: 100%;
      @include for-mobile-max_767 {
        // width: 90% !important ;
      }
      &[data-type='location'] {
        cursor: pointer !important;
      }
      &[data-type='location'] > label[data-shrink='false'] {
        transform: translate(56px, 14px) scale(1) !important;
      }
      & > label {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        background-color: #ffffff;

        &[data-shrink='false'] {
          transform: translate(14px, 14px) scale(1);
        }
      }
      & > div {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #212121;
      }
      & > div > input {
        padding: 12.5px 16px;
      }
      & > div > fieldset {
        border: 1px solid #efefef;
        & > legend {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .switchBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .privateHouseText {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
}
