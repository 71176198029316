@import '../../../../styles/mixins';

.container,
.orderContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;

  .deleteItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .deleteBtn {
      display: flex;
      gap: 2px;
      text-transform: none;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 0 !important;
      span {
        display: none;
      }
    }
  }
  .product {
    position: relative;
    width: 100%;
    display: flex;
    gap: 20px;

    @include for-tablet-landscape-max_1024 {
      gap: 16px;
    }

    .productImage {
      border-radius: 12px;
      position: relative;
      cursor: pointer;

      @media only screen and (max-width: 460px) {
        max-width: 100%;
      }

      > img {
        object-fit: cover;
        background-size: cover;
        width: 148px;
        height: 148px;
        border-radius: 12px;
      }

      .favorites {
        position: absolute;
        top: 11.5px;
        right: 11.5px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        padding: 10px;
        max-height: 40px;
        cursor: pointer;
      }
    }

    .productInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      column-gap: 40px;
      row-gap: 20px;
      @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .orderStatus {
        padding: 8px 12px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        white-space: nowrap;
        width: fit-content;
      }

      .productName {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 115px;
        cursor: pointer;

        .mobileDelete {
          position: absolute;
          right: 20px;
        }

        .title {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          word-wrap: anywhere;
        }

        .parameter {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .productPriceOptions {
        margin-right: 30px;
        display: flex;
        row-gap: 10px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 500px;
        flex-wrap: wrap;
        column-gap: 40px;
        @include for-mobile-max_620 {
          margin-right: 0;
          column-gap: 20px;
        }
      }
    }
  }
  .warningBlock {
    display: flex;
    align-items: center;
    gap: 8px;

    .warningText {
      font-family: Montserrat;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #eb793f;
    }
  }
  .productItemActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    @include for-mobile-max_480 {
      flex-direction: column;
      width: 100% !important;
      justify-content: unset;
    }

    .buttonItem {
      @include for-mobile-max_480 {
        min-width: 100% !important;
      }
    }
  }
}
.orderContainer {
  gap: 16px;
  background-color: #ffffff;
  border-radius: 12px;

  .product {
    align-items: flex-start;
  }

  .companyInfo {
    display: flex;
    align-items: center;
    gap: 8px;

    .companyName {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
  }
}
.menuButton {
  position: absolute;
  top: 20px;
  right: -20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px !important;
  @include for-mobile-max_620 {
    right: 0;
  }
}
.optionsMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  ul {
    padding: 0;
  }
  .menuItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.removeItemIcon {
  position: absolute;
  top: 20px;
  right: -20px;
  @include for-mobile-max_620 {
    right: 0;
  }
}

.editModeInputs {
  display: flex;
  gap: 24px;
  align-items: center;
  @include for-mobile-max_620 {
    flex-wrap: wrap;
  }

  .inputSelect {
    width: 100%;
  }
}

.optionItem {
  display: flex  !important;
  flex-direction: column  !important;
  row-gap: 2px  !important;
  justify-content: center  !important;

  .discountPrice {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    gap: 5px !important;

    .optionValueOldPrice {
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: red !important;
      text-decoration: line-through !important;
      text-decoration-color: red !important;
    }
  }

  @include for-mobile-max_620 {
    flex-direction: row !important;
    column-gap: 8px !important;
    align-items: center !important;
  }

  .optionName {
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }

  .optionValue {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
}

.warningInfoBlock {
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background-color: #efefef;
  color: #212121;
  margin: 6px 0;
  width: fit-content;

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
  }
}
.disputeIcon {
  padding: 4px;
  border: 1px solid rgba(211, 47, 47, 0.5);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: absolute;
  background-color: #FFFFFF;
  top: 4px;
  left: 4px;
}