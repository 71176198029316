@import '../../../../styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .emptyMessage {
    width: 100%;
    display: flex;
    padding: 24px 60px;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

    @include for-mobile-max_620 {
      padding: 24px 20px;
    }

    .title {
      color: #212121;
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }

    .description {
      color: #212121;
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      .link {
        color: #143471;
        font-weight: 600;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .recommendProducts {
    display: flex;
    flex-direction: column;

    .title {
      color: #212121;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }

    .productList {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;

      @media screen and (max-width: 650px) {
        justify-content: center;
      }

      @include for-tablet-landscape-max_1024 {
        gap: 16px;
      }
    }
  }
}
