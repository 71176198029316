@import '/src/styles/mixins';

.container {
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }
  @include for-mobile-max_620 {
    padding: 24px 20px;
  }

  .pageTitle {
    color: #212121;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    margin-bottom: 20px;
  }

  .topBlockContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .topBlockFormContainer {
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 12px;
      width: 100%;
    }
  }

  .form_input {
    position: relative;
    width: calc(50% - 8px);
    top: -5px;

    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }
    input {
      border-radius: 12px;
      padding: 14px 16px !important;
    }

    fieldset {
      top: 0;
    }
  }

  .modal_input {
    position: relative;
    width: 100%;

    @include for-mobile-max_767 {
      // width: 90% !important ;
    }

    &[data-type='location'] {
      cursor: pointer !important;
    }

    &[data-type='location'] > label[data-shrink='false'] {
      transform: translate(56px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .locationInput {
    cursor: pointer;
    width: calc(73% - 8px);

    input {
      cursor: pointer;
      padding: 12px 16px !important;
    }

    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .costInput {
    width: calc(50% - 8px);
    label {
      line-height: 16px !important;
    }
    input {
      padding: 12px 16px !important;
    }

    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .textareaContainer {
    width: 100%;
    .textarea {
      width: 100%;
      max-width: 100%;
    }
  }

  .selectInputContainer {
    width: calc(50% - 8px);

    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }

    .selectInput {
      width: 100%;
    }
  }

  .regionSelectInputContainer {
    width: calc(27% - 8px);

    .selectInput {
      width: 100%;
    }

    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .formContainer {
    display: flex;
    align-items: start;
    column-gap: 16px;
    row-gap: 12px;
    width: 100%;
    margin-top: 12px;
    flex-wrap: wrap;

    @media (max-width: 800px) {
      margin-top: 12px;
    }
  }

  .inputsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 12px;
    margin-top: 20px;
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      margin-top: 12px;
    }
    .form_input {
      width: 100%;
    }
  }

  .checkboxRows {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
  }

  .discountInputsGroup {
    display: flex;
    align-items: start;
    column-gap: 16px;
    row-gap: 12px;
    width: 100%;
    margin-top: 12px;
    flex-wrap: wrap;
  }

  .formCalendarContainer {
    width: calc(50% - 8px);
    display: flex;
    align-items: start;
    column-gap: 16px;

    .datePicker {
      width: 100%;
    }

    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
      flex-direction: column;
      row-gap: 12px;
    }
  }

  .datePickerContainer {
    width: calc(50% - 8px);

    .datePickerInput {
      width: 100%;
    }

    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .buttonOutline {
    margin-top: 20px;
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #18397a;
    border-radius: 4px;
    display: flex;
    align-items: center;
    column-gap: 12px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
  }

  .goalsContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 12px;
    margin-top: 20px;
    flex-wrap: wrap;

    .goalsList {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;

      img {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    .buttonNonBorder {
      border-color: transparent;
    }

    button {
      margin-top: 0;
    }

    @media (max-width: 800px) {
      .buttonOutline {
        div {
          display: none;
        }

        img {
          padding: 14px 0;
        }
      }
    }
  }
}
.datepicker {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
    border-radius: 12px;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #dcdfe2;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.errorsText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d32f2f;
  margin-top: 5px;
}
.inputUnitText {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #212121;
}
