@import '/src/styles/mixins';

.buttonCancel {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  border: none;
  color: #18397a;
  background-color: #e5e5e5;
  cursor: pointer;
  padding: 0 20px;
}

.buttonNextCommunity {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  border-radius: 6px;
  border: none;
  background-color: #18397a;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 20px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
}

.communityDiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 24px 60px;
  border-radius: 12px;
  background: #ffffff;

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }
  @include for-mobile-max_620 {
    padding: 20px;
  }
}

.communityTitle {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.communityText {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.communityContent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 0 110px;
}

.communityBlock {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 23px;
  margin-bottom: 40px;
  max-width: 42%;
  img {
    width: 128px;
    height: 128px;
  }
  @include for-mobile-max_480 {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.sdgItemInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 176px;

  @media only screen and (max-width: 430px) {
    min-width: auto;
  }
}
.communityBlockRight {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  gap: 23px;
  img {
    width: 128px;
    height: 128px;
  }
}

.communityCheckbox {
  display: flex;
}

.communityTextarea {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
  resize: none;
  height: 100px;
  margin-top: 8px;
  padding: 12px;

  @include for-mobile-max_480 {
    width: 100% !important;
  }

  &::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
  }
}

.communityDialogTextarea {
  min-height: 72px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 20px 12px;
  width: 100%;
  &:focus {
    outline: none;
    border: 2px solid #18397a;
  }
  &::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
  }
}

.errorText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d32f2f;
  margin-top: 5px;
}
