@import '/src/styles/mixins';

.requestCustomizationModal {
  max-width: 604px;

  @include for-mobile-max_620 {
    padding: 24px 20px !important;
    font-size: 16px !important;
  }

  .description {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #212121;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    .addInfoBtn {
      display: flex;
      gap: 12px;
      padding: 0 !important;

      span {
        display: none;
      }
      .addInfoText {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
      }
    }

    @include for-mobile-max_620 {
      gap: 20px;
      margin-top: 20px;
    }

    .formData {
      display: flex;
      flex-direction: column;

      .forDataTitle {
        display: flex;
        flex-wrap: nowrap;

        .startDate {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #212121;
        }

        .dateOfPayment {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #212121;
        }
      }
    }

    .perfomanceOfServiceBlock {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 23px;
      margin: 0;

      @include for-mobile-max_620 {
        flex-direction: column;
        gap: 12px;
        align-items: start;
      }

      .perfomanceOfService {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        color: #212121;

        .periodValue {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          text-align: right;
        }

        .dateItems {
          font-size: 12px !important;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }
    }

    .inputUnitText {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
    }
  }

  .inputSelect,
  .datePicker {
    width: 100%;
    margin: 0;

    @media (max-width: 900px) {
      max-width: 100%;
      width: 100%;
    }

    &[data-type='cost'] {
      cursor: pointer !important;
    }

    &[data-type='cost'] > label[data-shrink='false'] {
      transform: translate(20px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
        max-width: calc(100% - 100px);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .milestoneFields {
    display: flex;
    align-items: baseline;
    gap: 12px;

    .removeBtn {
      padding: 3px !important;
      min-width: unset;
      svg > path {
        fill: #18397a;
      }
    }

    @include for-mobile-max_620 {
      flex-direction: column;
      align-items: start;
      gap: 12px;
    }

    .milestoneLabel {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      color: #212121;
    }
  }

  .formButtons {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include for-mobile-max_620 {
      .formButton {
        font-size: 12px !important;
        font-weight: 600 !important;
      }
    }
  }

  .differentNotice {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

.textarea {
  width: 100%;
  min-height: 72px;
  margin: 0 !important;

  textarea {
    min-height: 72px !important;
  }
}
