@import '/src/styles/mixins';
* {
  font-family: 'Montserrat', sans-serif !important;
}

.container {
  height: fit-content;
  background: #ffffff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 24px;
  row-gap: 32px;
  border-radius: 12px !important;

  @include for-tablet-landscape-max_1024 {
    width: 100%;
    padding: 36px 24px 72px 24px !important;
    row-gap: 24px;
  }

  .imgRow {
    .mainImg {
      width: 100%;

      @include for-tablet-landscape-max_1024 {
        width: 266.47px;
        height: 268px;
      }
    }
  }

  .textRow {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      max-width: 632px;
      width: 100%;
      margin: 0 auto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      text-align: left;
    }
  }

  .inputBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    row-gap: 12px;

    button {
      cursor: pointer;
      padding: 0 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 48px;
      color: #18397a;
      border: 1px solid #18397a;
      border-radius: 4px;
      background-color: transparent;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;

      &:hover {
        color: #1f4a9d;
        border-color: #1f4a9d;
      }

      &:active {
        color: #18397a;
        border-color: #18397a;
      }
    }

    input {
      width: 315px;
      border-width: 1px;
      border-style: solid;
      border-radius: 12px;
      padding: 12px 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #757575;
    }
  }

  .span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #212121;
    margin-top: 20px;
  }
}

.modalContainer {
  z-index: 1000000;
  position: absolute;
  top: 110px;
  margin-right: 170px;
  background: #2d8342;
  backdrop-filter: blur(16px);
  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  column-gap: 14px;

  span {
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 580px) {
  .container {
    margin: 0 0 70px 0 !important;
    row-gap: 12px;
    padding: 30px 24px !important;

    .imgRow {
      .mainImg {
        width: 190px;
        height: 191.09px;
      }
    }

    .textRow {
      p {
        font-size: 14px;
      }
    }
  }
}
