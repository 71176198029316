@import '../../../../../styles/mixins';

.tabContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .block {
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;

    .label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #212121;
      margin-bottom: 12px;
    }

    .description {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      word-break: break-all;
    }

    .flexGroup {
      display: flex;
      align-items: center;
      column-gap: 12px;
      margin-top: 16px;

      .label {
        margin-bottom: 0;
      }
    }

    .chipsContainer {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 12px;
      column-gap: 12px;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #efefef;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #d0d0d0;
        border-radius: 4px;
      }

      .chip {
        background: #efefef !important;
        border-radius: 12px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        border: none;
        color: #212121;
      }
    }

    .columnGroup {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    .filesContainer {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .file {
        padding-top: 13px;
        border-top: 1px solid #efefef;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name,
        .size,
        .type {
          font-family: 'Montserrat';
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
        }

        .name {
          font-weight: 500;
          color: #212121;
        }

        .size,
        .type {
          font-weight: 400;
          color: #555555;
        }

        .button {
          padding: 0 20px;

          .buttonText {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 48px;
            text-transform: uppercase;
            color: #18397a;

            @media screen and (max-width: 600px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
