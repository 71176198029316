@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 20px 60px;
  margin-top: 20px;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }

  .containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 900px) {
      margin-bottom: 20px;
    }
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
    margin-bottom: 12px;
  }

  .createJob {
    background-color: transparent;
    border: 1px solid #18397a;
    border-radius: 4px;
    padding: 0 20px;
    cursor: pointer;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
  }

  .tabs {
    border-bottom: 1px solid #efefef;
  }

  .tabsItem {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    color: #212121;
    padding: 0 !important;
    margin-right: 20px;
  }

  .tabsItemActive {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #18397a !important;
  }

  .tabSelect {
    width: 100%;
    height: 48px;
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #18397a;
    fieldset {
      border: 0;
    }
  }
  .paginationBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    .pagination {
      display: flex;
      justify-content: center;
    }
    .paginationItem {
      font-family: 'Montserrat' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      padding: 8px 15px !important;
      background: #ffffff !important;
      border: 1px solid #efefef;
      border-radius: 12px !important;
      color: #212121 !important;
    }

    .activePaginationItem {
      border: 1px solid #143471 !important;
      border-radius: 12px !important;
    }
    .paginationText {
      margin-top: 4px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #757575;
    }
  }
}
