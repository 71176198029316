@import '/src/styles/mixins';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.08);
  padding: 24px 40px;
  border-radius: 12px;
  min-width: 604px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include for-tablet-landscape-max_1024 {
    min-width: 480px;
  }

  @include for-mobile-max_767 {
    padding: 24px 20px;
    min-width: 327px;
  }

  .modalTitle {
    width: 100%;
    justify-content: space-between;
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #212121;
    gap: 14px;

    @include for-mobile-max_767 {
      font-size: 20px;
      line-height: 32px;
    }

    .closeIcon {
      height: fit-content;
      padding: 0;
    }
  }

  .reports {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .checkboxLabel {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: #143471 !important;
    }
    .formControlLabel {
      padding-left: 0;
      margin-left: 0;
      display: flex;
      align-items: flex-start;
      gap: 8px;

      span:first-child {
        padding: 0;
      }
    }

    .reportContainer {
      display: flex;
      gap: 8px;
      flex-direction: column;
      padding-left: 24px;
    }

    .accordionContainer {
      width: 100%;
      background-color: #ffffff;
      overflow: hidden !important;
      border-radius: 0 !important;
      box-shadow: unset !important;
      margin: 0 !important;

      &:before {
        display: none;
      }
    }
  }

  .buttonBlock {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
