@import '/src/styles/mixins';

.container {
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    padding-bottom: 90px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 800px) {
    align-items: flex-end;
  }

  .defaultButton,
  .defaultButtonService {
    background-color: transparent !important;
  }

  .defaultButtonService,
  .defaultButtonSave {
    @include for-mobile-max_480 {
      font-size: 12px !important;
    }
  }

  .carbonBtnGroup {
    display: flex;
    align-items: center;
    column-gap: 20px;
    flex-wrap: wrap;
    margin-left: 10px;
    justify-content: flex-end;

    @media (max-width: 800px) {
      gap: 12px;
    }

    .buttonDelete {
      background-color: transparent !important;
      @media (max-width: 899px) {
        padding: 12px 16px !important;
      }
    }
  }

  .buttonCancel {
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #18397a;
    border-radius: 4px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
    background-color: transparent;
    img {
      display: none;
    }

    @media (max-width: 800px) {
      div {
        display: none;
      }

      img {
        padding: 14px 0;
        display: block;
      }
    }
  }

  .buttonNext {
    background: #18397a;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #18397a;
    padding: 0 20px;
    display: flex;
    align-items: center;
    column-gap: 17.2px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
  }
}
