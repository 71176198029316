.tabsContainer {
  width: 100%;
  min-width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 60px;
    width: 100%;

    .tabsItem {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      color: #212121;
    }
    .tabsItemActive {
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: #143471 !important;
    }
  }

  .parentTabs {
    border-bottom: 1px solid #c4c4c4;
  }
}
