@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
    margin-bottom: 12px;
  }
  .subTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 12px;

    @include for-mobile-max_620 {
      font-size: 14px;
    }
  }
  .departmentBlock {
    display: flex;
    align-items: center;
    column-gap: 53px;
    margin-bottom: 24.5px;

    @include for-mobile-max_620 {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    .departmentBlockItem {
      display: flex;
      align-items: center;
    }

    .departmentBlockText {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #212121;
      margin-left: 10.25px;
    }
  }
  .socialBlock {
    display: flex;
    column-gap: 20px;
  }
  .editButton {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 33px;
    right: 65px;

    @include for-mobile-max_620 {
      top: 25px;
      right: 25px;
    }
  }
}
.socialButton {
  cursor: pointer;
}
