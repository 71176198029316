@import '/src/styles/mixins';

.gridContent {
  margin-top: 20px !important;
  padding-bottom: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include for-mobile-max_620 {
    margin-top: 4px !important;
  }
}
.card {
  display: grid;
  align-content: space-between !important;
  padding: 20px;
  overflow: unset !important;
  width: 100%;
  border-radius: 16px !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  @include for-mobile-max_620 {
    padding: 20px;
  }

  .cardImageBlock {
    min-width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cardImage {
    border-radius: 50%;
    width: 128px;
    height: 128px;
    cursor: pointer;
  }
  .gridCardName {
    margin-top: 8px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    cursor: pointer;
    @include for-mobile-max_620 {
      font-weight: 500;
      font-size: 14px;
    }
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gridCardDescription {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px;
    color: #757575;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cardMoreInfo {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
  .cardActions {
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.optionsWindow {
  position: absolute;
  top: -10px;
  right: 45px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 111;

  .options {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
