@import '../../../../../styles/_mixins.scss';

.paymentText {
  height: 32px;
  display: flex;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.paymentDiv {
  max-width: 1020px;
  padding: 30px 60px;

  @include for-tablet-landscape-max_1024 {
    padding: 20px 40px;
  }

  @include for-mobile-max_620 {
    padding: 20px;
  }
  margin-top: 20px;
  border-radius: 12px;
  background: white;
}

.paymentColumnLeft {
  max-width: 680px;

  .paymentDescription,
  .paymentDescriptionRed {
    font-size: 16px;
    width: 90%;

    font-weight: 400;
    line-height: 24px;
  }
  .paymentDescriptionRed {
    color: red !important;
  }
}

.connectStripeButton {
  white-space: nowrap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #5e56f2;
  border: 1px solid #5e56f2;
  border-radius: 4px;
  padding: 14px 20px 14px 22px;
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 700px) {
    margin-top: 12px;
  }

  .iconWrapper {
    margin: 0 15px 0 0;
    svg {
      fill: currentColor;
      color: #5e56f2;
    }
  }

  &:disabled {
    border: 1px solid #999999;
    color: #666666;
    cursor: no-drop;

    .iconWrapper {
      svg {
        color: #666666;
      }
    }
  }
}

.connectedStripeButton {
  white-space: nowrap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #4caf50;
  border: 1px solid #4caf50;
  border-radius: 4px;
  padding: 14px 20px 14px 22px;
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 700px) {
    margin-top: 12px;
  }

  .iconWrapper {
    margin: 0 15px 0 0;
    svg {
      fill: currentColor;
      color: #4caf50;
    }
  }

  &:disabled {
    border: 1px solid #999999;
    color: #666666;
    cursor: no-drop;

    .iconWrapper {
      svg {
        color: #666666;
      }
    }
  }
}

.paymentRow {
  display: flex;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.paymentControlLabel {
  display: flex;

  &:disabled {
    color: #212121;
  }
}

.paymentColumnRight {
  .paymentButtonContainer {
    margin: 0 0 10px 0;
  }
}
