.tooltipTitle,
.tooltipDescription {
  color: #ffffff;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.tooltipTitle {
  font-weight: 700;
}
