.radioContainer {
  margin-top: 16px;
}

.body {
  position: relative;
}

.form {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  > div {
    width: 100%;
  }
}

.blockRadio {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  @media screen and (max-width: 480px) {
    flex-direction: column-reverse !important;
    gap: 12px;
  }

  button {
    @media screen and (max-width: 480px) {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}
