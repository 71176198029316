.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #efefef;
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  padding: 12px 0px;
}

.fileName {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  font-family: Montserrat;
}

.fileSize {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.fileType {
  font-size: 16px;
  font-weight: 500;
  font-family: Montserrat;
}

.fileIcons {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.dropzone {
  position: relative;
  border: 1px dashed #757575;
  border-radius: 12px;
  width: 393px;
  height: 200px;
  cursor: pointer;
}

.dropzoneTextRow {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  margin-top: 77px;

  p {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    color: #757575;
    margin: 0px;
    margin-bottom: 5px;
  }
}

.dropzoneSubtitle {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0px;
  text-align: center;
}

.dropzoneContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.dropzoneArea {
  display: flex;
  align-items: center;
  column-gap: 60px;

  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 30px;
  }
}

label.dropzoneRow {
  cursor: pointer;
}

.dropzoneRow {
  display: flex;
  align-items: center;
  column-gap: 15px;

  span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #18397a;
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #c4c4c4;
    margin-bottom: 15px;
  }
}
