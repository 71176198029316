.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: end;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  backdrop-filter: blur(2px);
  animation-name: appear;
  animation-duration: 300ms;
}

.modal_dialog {
  right: 0;
  width: 293px;
  padding-bottom: 20px;
  background: white;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
  padding: 0px 40px;
}
.modal_header,
.modal_footer {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.modal-header {
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}
.modal_footer {
  border-top: 2px solid #dbdbdb;
  justify-content: flex-end;
}
.modal_close {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal_body {
  overflow: auto;
}
.modal_content {
  padding: 1rem;
  margin: 1rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.user {
  padding-top: 32px;
  display: flex;
  align-items: center;
  column-gap: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757575;
  margin-bottom: 20px;
}

.userInfo {
}

.avatarRow {
  position: relative;
}

.role {
  position: absolute;
  bottom: -8px;
  right: 5.5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #d8913c;
  background: #fcfcfc;
  border-radius: 6px;
  padding: 1.5px 6.5px 5px 7.5px;
}

.ceoRow {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.name {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkMark {
  position: absolute;
  top: -5px;
  right: -10px;
}

.linksRow {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  margin-bottom: 12px;
  cursor: pointer;
  color: black;
}
.LinkRowBusiness {
  display: flex;
  align-items: flex-start;
  column-gap: 6px;
}

.businessImg {
  margin-top: 3px;
  width: 16px;
  height: 16px;
}

.parent {
  &:hover {
    color: #143471;

    .nestedLink {
      display: block;
    }
  }
}

.nestedLink {
  color: #212121;
  display: none;
  margin-left: 14px;
  margin-bottom: 12px;
}

.ProfileLinks {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #efefef;
}

@media screen and (max-width: 375px) {
  .modal_dialog {
    padding: 0px 25px;
    width: 260px;
  }

  .linksRow {
    font-size: 14px;
    line-height: 24px;
  }
}
