.modalContainer {
  max-width: 604px;

  .radioGroup {
    gap: 20px;
    margin-top: 20px;
    .infoRow {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .formControl {
        display: flex;
        align-items: flex-start;
        margin: 0;

        .label {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-left: 8px;

          .mailAndPhone {
            display: flex;
            align-items: center;
            gap: 12px;

            .infoItem {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
            .activeInfoItem {
              color: #143471;
            }
          }

          .labelTitle {
            color: #212121;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .activeLabelTitle {
            color: #143471;
            font-weight: 500;
          }

          .labelDescription {
            color: #555;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
    .editButton {
      span {
        display: none;
      }
    }
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .buttons {
      display: flex;
      align-items: center;
      gap: 20px;

      .addBtn {
        display: flex;
        align-items: center;
        gap: 12px;
        .addBtnText {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 27px;
          text-transform: uppercase;
        }
      }
    }
  }
}
