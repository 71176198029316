.buttonCancel {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 100px;
  height: 48px;
  border-radius: 6px;
  //border: none;
  color: #18397a;
  background: transparent;
  border: 1px solid #18397a;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
.buttonSend {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  width: 69px;
  height: 48px;
  border-radius: 6px;
  background-color: #18397a;
  color: #ffffff;
  transition: opacity 0.1s ease-in;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.9;
  }
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}

.addManagerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #18397a;
  margin-top: 20px;
  cursor: pointer;
  text-transform: uppercase;

  .plusIcon {
    margin-right: 17px;
    height: 14px;
    width: 14px;
  }

  @media (max-width: 700px) {
    margin-top: 0;
  }
}
