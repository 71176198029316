@import '../../../styles/mixins';

.modal {
  position: relative;
}
.body {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .verifyModalActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    .button {
      @include for-mobile-max_480 {
        min-width: 100% !important;
      }
    }
  }

  .modalAction {
    display: flex;
    justify-content: flex-end;

    .connectStripeButton {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      color: #5e56f2;
      border: 1px solid #5e56f2;
      border-radius: 4px;
      padding: 14px 20px 14px 22px;
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: transparent;
      cursor: pointer;

      svg {
        fill: currentColor;
        color: #5e56f2;
      }

      &:disabled {
        border: 1px solid #999999;
        color: #666666;
        cursor: no-drop;

        .iconWrapper {
          svg {
            color: #666666;
          }
        }
      }
    }
  }
}
