.modalContainer {
  max-width: 604px;

  .mainContent {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }

    .inputsGroup {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .form_input {
        position: relative;
        width: 100%;

        @media (max-width: 800px) {
          max-width: 100%;
          width: 100%;
        }
        input {
          border-radius: 12px;
        }
        label {
          line-height: normal;
        }
        & > div {
          height: 49px;
        }
      }
      .textarea {
        width: 100%;
        min-height: 72px;

        textarea {
          min-height: 72px !important;
        }
      }
    }

    .buttonsGroup {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
