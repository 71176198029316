.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: 20px;
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  border-radius: 12px;
  background: #ffffff;
  padding: 20px 60px;

  @media screen and (max-width: 1100px) {
    padding: 20px 40px;
  }

  @media screen and (max-width: 400px) {
    padding: 20px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: inline;

  a {
    color: #18397a;
    text-decoration-line: underline;
  }

  span {
    display: inline-block;
    cursor: pointer;
    margin-left: 2px;

    svg {
      margin-bottom: -3px;
    }
  }
}

.price {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  span {
    font-size: 14px;
    font-weight: 400;
  }
}

.stepBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;

  @media screen and (max-width: 374px) {
    display: none;
  }
}

.stepBlockResponsive {
  display: none;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  @media screen and (max-width: 374px) {
    display: flex;
  }
}

.calcBlock,
.calcBlockAccounted {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 10px;

  > div {
    width: 100%;
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
}

.calcBlockAccounted {
  > button {
    width: 190px;
    flex-shrink: 0;
  }

  @media screen and (max-width: 867px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.connectBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;

  > button {
    width: 250px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

.accountWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  gap: 10px;
}

.accountBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.accountBoxWallet {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;

  > img {
    margin-top: -1px;
  }
}

.disabledInput {
  > input {
    background: #fff !important;
    color: #1f1f1f !important;
  }
}

.bold {
  font-weight: 600;
}

.balance {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: rgb(24, 57, 122);
  }
}

.modalText {
  padding: 20px 0;
  line-height: 24px;
}
