@import '../../../../../src/styles/mixins';

.filterBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 16px;

  @include for-mobile-max_620 {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .searchFilter,
  .filter,
  .shoppingCart {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #efefef;
    border-radius: 12px;
  }

  .searchFilter {
    width: 100%;
    gap: 12px;
    padding: 7px 20px;

    div {
      border-radius: 0 !important;
      border: 0 !important;
    }
    div:hover {
      box-shadow: none !important;
    }

    @include for-mobile-max_620 {
      min-width: 100%;
    }
  }

  .searchComponent {
    width: 100%;
    position: relative;
    .overlay {
      position: fixed;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .highlightedInput {
      position: relative;
      z-index: 1001;
    }

    .dropdownSearch {
      background-color: #fff;
      position: absolute;
      width: 100%;
      border-radius: 12px;
      top: 52px;
      padding: 12px 0px;
      z-index: 1001;
      gap: 12px;
      .searchRow {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 8px 20px;
        &:hover {
          background-color: rgba(128, 202, 255, 0.1);
        }
        .searchItem {
          display: flex;
          gap: 12px;
          .searchTitle {
            font-size: 18px;
            font-weight: 500;
          }
          .searchSubtitle {
            font-size: 14px;
            font-weight: 400;
            color: rgba(117, 117, 117, 1);
          }
        }
        .arrowAsIcon {
          width: 22px;
          height: 22px;
          svg > path {
            fill: rgba(196, 196, 196, 1) !important;
            stroke: rgba(196, 196, 196, 1) !important;
          }
        }
        .active {
          background-color: rgba(128, 202, 255, 0.1);
        }
      }
    }
    .dropdownSearchEmpty,
    .loading {
      background-color: #fff;
      position: absolute;
      width: 100%;
      height: 190px;
      border-radius: 12px;
      top: 52px;
      padding: 12px 20px;
      z-index: 1001;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .filter {
    width: fit-content;
    cursor: pointer;
    gap: 4px;
    padding: 14px 21.5px;
    justify-content: center;
    position: relative;

    .filerLive {
      position: absolute;
      width: 10px;
      height: 10px;
      background: #18397a;
      border: 1px solid #ffffff;
      border-radius: 50px;
      right: 13px;
      top: 7px;
    }
  }

  .shoppingCart {
    display: none;
    width: fit-content;
    cursor: pointer;
    padding: 11px 17.4px;
    gap: 4px;
    @media (max-width: 1025px) {
      display: block;
    }
  }

  .filterActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .buttonsGroup {
      display: flex !important;
      justify-content: space-between;
      background: #fff;
      height: 100%;
      width: 100%;
      border-radius: 12px;
      padding: 9px 10px;
    }

    @include for-tablet-landscape-max_900 {
      justify-content: space-between;
    }

    .filterIcons,
    .activeIcon {
      width: 20px !important;
      height: 20px !important;
      margin: 5px;
      flex-shrink: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .activeIcon {
      svg > path {
        fill: #18397a !important;
      }
    }
  }
}
