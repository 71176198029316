.documentsBody {
  max-width: 1020px;
  margin-top: 20px;
  padding: 20px 60px;

  border-radius: 12px;
  background: white;

  @media (max-width: 1024px) {
    padding: 20px;
  }
}
.headerTextCreate {
  display: flex;
  max-width: 1020px;
  align-items: flex-end;
  justify-content: center;
  height: 62px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
  background: white;
  border-radius: 12px 12px 0 0;
}
.selectCompany {
  display: flex;
  width: 1020px;
  align-items: center;
  justify-content: center;
  height: 62px;
  font-family: 'Montserrat';
  color: #18397a;
  font-size: 16px;
  font-weight: 500;
  background: white;
  border-radius: 0 0 12px 12px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.btnCancel {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 69px;
  height: 48px;
  border: none;
  color: #18397a;
  background: transparent;
}
.btnNext {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  width: 69px;
  height: 48px;
  border-radius: 6px;
  background-color: #18397a;
  color: #ffffff;
}

.titleBlock {
  display: flex;
  align-items: flex-end;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;

  h2 {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
  }

  .helpIconStyle {
    margin: 0 0 0 10px;
  }
}

.area {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
  height: 73px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #efefef;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 10px;
}
