@import '/src/styles/mixins';

.container {
  padding: 24px 40px;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  @include for-mobile-max_620 {
    padding: 20px;
    gap: 12px;
  }

  .containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      @media screen and (max-width: 820px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .incomingActions {
      display: flex;
      align-items: center;
      gap: 20px;

      @media screen and (max-width: 820px) {
        gap: 8px;
      }

      .actionBtn {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: nowrap;

        span:first-child {
          display: inline-grid !important;

          svg {
            height: 24px;
            width: 24px;

            g > path {
              fill: #18397a !important;
            }

            > path {
              fill: #18397a !important;
            }
          }
        }

        .btnText {
          display: block;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          white-space: nowrap;
          @include for-tablet-landscape-max_1100 {
            display: none;
          }
          @include for-tablet-landscape-max_1024 {
            display: block;
          }
          @media screen and (max-width: 820px) {
            display: none;
          }
        }
      }
    }
  }

  .orderTypes {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .typeItem {
      cursor: pointer;
      padding: 26px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
      svg {
        height: 24px;
        width: 24px;
      }
      .typeName {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }
}
