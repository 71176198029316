@import '/src/styles/mixins';

.containerFull,
.containerEmpty {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .message {
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .subMessage {
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }

  .accordionContainer {
    box-shadow: none;
    width: 100%;
    background-color: #ffffff;
    border-radius: 16px !important;
    overflow: hidden !important;
    .accordionSummary {
      margin-right: 20px;
    }
    .titleOpened,
    .titleExpanded {
      margin: 20px 0 0 20px;
      display: flex;
      gap: 8px;
      align-items: center;
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .titleExpanded {
      margin: 20px;
    }
    &:before {
      display: none;
    }

    .accordionDetails {
      padding: 0;

      .content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .contentItem {
          border-radius: 12px;
          box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
          background-color: white;
          padding: 14px 40px;
          display: flex;
          justify-content: space-between;
          margin: 0px 20px 16px;

          @include for-mobile-max_767 {
            flex-direction: column;
            gap: 12px;
            padding: 12px 20px;
            align-items: end;
          }
          .reportingPeriodBlock {
            display: flex;
            align-items: center;
            gap: 40px;

            @include for-mobile-max_767 {
              justify-content: space-between;
              width: 100%;
            }

            .text {
              font-family: 'Montserrat';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }

            .date {
              font-family: 'Montserrat';
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
          .submitBtn {
            min-width: fit-content;
            border-radius: 4px;
            background: #18397a;
            color: #ffffff;
            font-family: 'Montserrat';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 48px;
            text-transform: uppercase;
            white-space: nowrap;
            padding: 0 20px;
          }
        }
      }
    }
  }
}

.containerFull {
  padding: 0;
  border-radius: 16px;
}

.containerEmpty {
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 12px;
}
