@import '/src/styles/mixins';

.socialAccountContainer {
  margin: 20px 0px;
}

.socialAccountLeft {
  display: flex;
  flex-direction: column;

  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 440px;
    height: 48px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.errorsText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  max-width: 440px;
  color: #d32f2f;
  margin-top: 10px;
}

.socialAccountRight {
  display: flex;
  width: 100%;
  margin-top: 20px;
  column-gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 20px;
  }
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    max-width: 440px;
    width: 100%;
    height: 48px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;

    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }
}
.socialAccountSelect {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  width: 440px;
  height: 48px;
  margin-top: 20px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  color: #757575;
}

.phone {
  width: 100%;
}

.phoneBtn {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
  margin: 0px;
}

.phoneContainer {
}
.phoneInput {
  height: 48px !important;
  background: #ffffff !important;
  border: 1px solid #efefef;
  border-radius: 12px;
  padding-left: 120px !important;
  max-width: 440px !important;
  width: 100% !important;

  @media (max-width: 1024px) {
    max-width: 100% !important;
  }
}

.addManagerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #18397a;
  margin-top: 16px;
  border: 1px solid #18397a;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 20px 0 24px;
  text-transform: uppercase;

  p {
    margin: 0;
  }

  @media (max-width: 500px) {
    margin-top: 12px;
  }
}
.plusIcon {
  margin-right: 17px;
  height: 14px;
  width: 14px;
}

.socialMedia {
  //width: 100%;
  > input {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    width: 380px;
    height: 48px;
    margin-top: 20px;
    padding-left: 12px;
    background: white;
    border-radius: 12px;
    border: 1px solid #efefef;
  }
}

.add {
  cursor: pointer;
}

.removeContactButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #18397a;
  margin-top: 16px;
  cursor: pointer;
  padding: 0 20px 0 24px;
  border: none;

  p {
    text-transform: uppercase;
    margin-left: 17px;
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 500px) {
    margin-top: 12px;
  }
}
.input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}
