@import '../../../../styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  padding: 20px 40px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

  @include for-mobile-max_620 {
    padding: 20px;
    gap: 40px;
  }

  .titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #212121;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;

      @include for-mobile-max_620 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }
  }

  .receiptBlock {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .receiptInfo {
      display: flex;
      gap: 100px;

      .receiptInfoContent {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .receiptInfoText {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #212121;
        }
      }
    }

    .usersInfo {
      display: flex;
      gap: 100px;
      margin-bottom: 60px;
      @include for-mobile-max_620 {
        margin-bottom: 40px;
      }

      @include for-mobile-max_620 {
        gap: 20px;
        flex-direction: column;
      }

      .usersInfoBlock {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .transactionsType {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #212121;
        }

        .transactionsDesc {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .transactionsDescText {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #757575;
          }
        }
      }
    }
  }

  .transactionInfoTable {
    .discountPriceBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .optionValueOldPrice {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: red;
        text-decoration: line-through;
        text-decoration-color: red;
      }

      .optionValue {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    thead > tr > th {
      font-weight: 500;
    }

    @include for-mobile-max_620 {
      thead > tr > th,
      tbody > tr > th,
      tbody > tr > td {
        font-size: 12px;
      }

      thead > tr > th,
      tbody > tr > th,
      tbody > tr > td {
        padding: 6px;
      }

      thead > tr > th:first-of-type,
      thead > tr > th:last-of-type,
      tbody > tr > th:first-of-type,
      tbody > tr > td:last-of-type {
        padding: 6px 0;
      }
    }
  }

  .transactionFooter {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .transactionCommission,
    .totalPaid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0em;
      color: #212121;
    }

    .totalPaid {
      p {
        font-weight: 600;
      }
    }
  }
}
