div.box {
  width: 100%;

  margin: 15px 0;

  border: 0;
  border-radius: 12px;
  background-color: white;

  padding: 20px 60px;
}

div.box:first-child {
  margin-top: 0;
}

div.box:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 865px) {
  div.box {
    padding: 20px 20px;
  }
}
