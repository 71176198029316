@import '/src/styles/mixins';

.menuBtn {
  position: absolute !important;
  top: 20px;
  right: 60px;
  //width: 4px !important;
  //height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  @include for-tablet-landscape-max_1024 {
    top: 16px;
    right: 40px;
  }
  @include for-mobile-max_767 {
    top: 16px;
    right: 20px;
  }
}
