@import '/src/styles/mixins';

.fieldWrapper {
  width: 100%;
  max-width: 1020px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 60px;

  @include for-mobile-max_767 {
    display: flex;
    align-items: center !important;
  }
}
.mainInformation {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #ffffff;
  padding: 24px 60px;
  width: 100%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 20px;
  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }
  @include for-mobile-max_767 {
    padding: 20px;
  }
  @include for-mobile-max_620 {
    grid-template-columns: 1fr;
  }
}
.leftContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  gap: 12px;

  @include for-mobile-max_767 {
    position: relative;
    padding-bottom: 15px;
    width: 100%;
  }
}
.middleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  @include for-mobile-max_767 {
    padding-bottom: 15px;
  }
}
.modal_buttonSend {
  display: flex;
  height: max-content;
  justify-content: space-around;
  width: 140px;
  background: none;
  border: 1px solid #18397a;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  color: #18397a;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;

  @include for-mobile-max_480 {
    width: 80px;
    font-size: 12px;
    line-height: 40px;
  }
}

.password {
  padding-bottom: 12px;
}

.headerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include for-mobile-max_767 {
    width: 100vw !important ;
    display: flex;
    justify-content: space-around;
    align-items: center !important;
  }
}
.language {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.midContent {
  display: flex;
  width: 98%;
  padding-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include for-mobile-max_767 {
    width: 100vw !important ;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  @include for-mobile-max_620 {
    align-items: center;
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editBtn {
  border: 1px solid #18397a;
  border-radius: 4px;
  padding-right: 14.5px;
  padding-left: 14.5px;
  background-color: #ffffff;
  height: fit-content;
  cursor: pointer;
  width: fit-content;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #18397a;
}
