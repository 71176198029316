@import '/src/styles/mixins';

.formContent {
  display: flex;
  flex-direction: column;
  margin: 12px 0 8px 0;
  gap: 20px;

  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .formControl,
    .formControlColumn {
      width: 100%;
      display: flex;
      gap: 12px;
      flex-wrap: nowrap;

      .timePickerWithError {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .schedulesSelect {
        width: 100%;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        height: 48px;
        background: white;
        border-radius: 12px;
        color: #757575;
        padding-left: 10px;
        &:hover {
          border: none;
        }
      }

      .phone {
        height: 48px;
        background: transparent !important;
        border: none !important;
        border-radius: 16px !important;

        & > div {
          padding: 0 0 0 15px !important;

          &:focus,
          &:hover {
            background-color: transparent !important;
          }

          &[aria-expanded='true'] {
            background: transparent !important;
          }
        }
        ul {
          width: 217px;
          @media screen and (max-width: 815px) {
            width: 300px !important;
          }
        }
      }

      .phoneInput {
        width: auto;
        height: 48px !important;
        background: #ffffff !important;
        border: 1px solid #efefef !important;
        border-radius: 16px !important;

        @media screen and (max-width: 815px) {
          width: 100% !important;
        }
      }

      .locationInputSeller,
      .locationInputSellerTime {
        position: relative;
        width: 100%;

        @media (max-width: 900px) {
          margin-right: 0;
          margin-bottom: 12px;
        }

        &[data-type='location'] {
          cursor: pointer !important;
        }

        &[data-type='location'] > label[data-shrink='false'] {
          transform: translate(20px, 14px) scale(1) !important;
        }

        & > label {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          pointer-events: none;

          &[data-shrink='false'] {
            transform: translate(14px, 14px) scale(1);
          }
        }

        & > div {
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #212121;
          border-radius: 12px;
        }

        & > div > input {
          padding: 12.5px 16px;
        }

        & > div > fieldset {
          border-radius: 12px;

          & > legend {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
          }
        }
      }

      .shippingGroup {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: flex-start;

        .priceValue {
          margin-right: 12px;
        }

        .infoIconBtn {
          background-color: transparent !important;
          padding: 0;

          .infoIcon {
            width: 24px;
          }
        }
      }

      .orderStorageInput {
        min-width: 220px;
        width: fit-content !important;

        @media (max-width: 600px) {
          width: 100% !important;
        }
      }
    }

    .formControlColumn {
      @include for-mobile-max_767 {
        flex-direction: column;
      }
    }

    .fieldTitle {
      font-weight: 500;
      font-size: 16px;
      color: #212121;
    }

    .accordionContainer {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-radius: 12px !important;
      border: 1px solid #efefef;
      margin: 0 !important;
      padding: 12px 12px 12px 12px;
      box-shadow: none;

      .deleteSchedule {
        display: flex;
        align-items: center;
        gap: 12px;

        .deleteScheduleBtnText {
          color: #18397a;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .scheduleContent {
        display: flex;
        gap: 12px;
        flex-direction: column;
        padding-top: 12px;

        .excludeSomeDates {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          @media (max-width: 600px) {
            flex-wrap: wrap;
          }

          .scheduleDates {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        }
        .errorText {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #d32f2f;
          margin-left: 5px;
        }
        .scheduleGroup {
          width: 100%;
          display: flex;
          gap: 12px;
          flex-wrap: wrap;

          .weekDayBtn {
            border: #ffffff !important;
            box-shadow: 0px 4px 24px 0px #0000000a;
            border-radius: 12px !important;
            padding: 6px 16px !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            color: #212121 !important;
            text-transform: capitalize;
          }

          .weekDayBtnActive {
            background-color: #bad4e7 !important;
            box-shadow: 0px 4px 24px 0px #0000000a;
            color: #18397a !important;
          }

          .checkboxItem {
            display: flex;
            align-items: center;
            gap: 8px;
            margin: 0 !important;

            .checkBoxLabel {
              color: #212121;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .addSchedule {
      display: flex;
      align-items: center;
      gap: 12px;

      .btnText {
        color: #18397a;
        font-size: 16px;
        line-height: 17px;
        font-weight: 600;
      }
    }
  }

  .modalFooter {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    .buttonGroup {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .formBtn {
        @media (max-width: 600px) {
          font-size: 12px !important;
        }
      }
    }
  }

  .addDateBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 20px !important;
    white-space: nowrap;

    .btnText {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      color: #18397a;
    }
  }
}
