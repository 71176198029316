@import '/src/styles/mixins';
.cardContainer {
  margin-top: 24px;
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .cardHeaderNameBlock {
      display: flex;
      align-items: center;

      .postName {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #212121;

        @include for-mobile-max_620 {
          font-size: 14px;
        }
      }

      .postStatus {
        border: 1px solid #757575;
        border-radius: 20px;
        padding: 4px 12px;
        margin-right: 20px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #757575;

        @include for-mobile-max_620 {
          font-size: 14px;
          margin-right: 13px;
        }
      }
    }

    .cardHeaderButtons {
      display: flex;
      align-items: center;

      .postJob {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        text-transform: uppercase;
        color: #18397a;
        margin-right: 50px;
        padding: 0;

        @include for-mobile-max_620 {
          font-size: 12px;
        }
      }
    }
  }

  .subTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #555555;
    margin-bottom: 12px;
  }

  .description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555555;
    margin-bottom: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .showMore {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #18397a;
  }
}
