@import '../../../../../styles/mixins';

.tabContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 20px;

    .parameters {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;

      span {
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-left: 12px;
      }
    }

    .label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #212121;
    }

    .description {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }

    .tagsBlock {
      display: flex;
      align-items: center;
      gap: 12px;
      overflow-x: auto;
      padding-bottom: 10px;
    }

    .chip {
      background: #efefef !important;
      border-radius: 12px !important;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      border: none;
      color: #212121;
    }
  }
}
