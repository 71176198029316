@import '../../../../styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .titleBlock {
    padding: 12px 20px;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: row;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span > svg {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    .title {
      color: #212121;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;

      @include for-mobile-max_620 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }
  }

  .cartListContainer {
    width: 100%;
    display: flex;
    gap: 20px;

    @media (max-width: 1170px) {
      gap: 16px;
      flex-direction: column;
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.orderAction {
  display: flex;
  gap: 20px;

  @include for-mobile-max_620 {
    width: 100%;
    justify-content: space-between;
  }

  .transparentButton {
    background-color: transparent !important;
    @include for-mobile-max_620 {
      font-size: 12px !important;
    }
  }
}
