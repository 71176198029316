@import '/src/styles/mixins';

.modalContainer {
  max-width: 604px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .reviewsBlock {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .companyInfo {
        display: flex;
        align-items: center;
        column-gap: 12px;

        .companyName {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #212121;
        }
      }

      .productName {
        display: flex;
        align-items: center;
        gap: 5px;

        .name {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }

    .stars {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .errorText {
        color: red;
      }
    }

    .textarea {
      position: relative;
      width: 100%;
      margin-top: 12px;

      @include for-mobile-max_767 {
        // width: 90% !important ;
      }

      &[data-type='location'] {
        cursor: pointer !important;
      }

      &[data-type='location'] > label[data-shrink='false'] {
        transform: translate(56px, 14px) scale(1) !important;
      }

      & > label {
        font-style: normal;
        font-weight: 400;
        background-color: #ffffff;

        &[data-shrink='false'] {
          transform: translate(14px, 14px) scale(1);
        }
      }

      & > div {
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #212121;
      }

      & > div > input {
        padding: 12.5px 16px;
      }

      & > div > fieldset {
        border-radius: 12px;
        border: 1px solid #efefef;

        & > legend {
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .starsBlock {
      display: flex;
      align-items: center;
      gap: 20px;

      .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .imagesBlock {
      border: 1px solid #efefef;
      border-radius: 12px;
      padding: 12px 20px;

      .imagesDropContainer {
        display: flex;
        align-items: center;
        cursor: pointer;

        @include for-mobile-max_620 {
          flex-direction: column;
          align-items: flex-start;
        }

        .iconContainer {
          padding: 20px 28px 20px 20px;
          border-right: 1px solid #efefef;

          @include for-mobile-max_620 {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 20px 20px 28px 20px;
            border-right: none;
            border-bottom: 1px solid #efefef;
          }
        }

        .imagesBlockText {
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          margin-left: 8px;

          @include for-mobile-max_620 {
            margin-left: 0;
            margin-top: 8px;
          }

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #212121;

          .imagesBlockTitle {
            font-weight: 600;
          }

          .imagesBlockDescription {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .button {
    width: 100%;
    max-width: 100%;
  }
}
.modalContainer::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.modalContainer::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.modalContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}

.scrollContainer::-webkit-scrollbar {
  height: 5px;
}
.scrollContainer::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.scrollContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}

.slider {
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: nowrap;
  padding-bottom: 12px;

  .sliderImage,
  .sliderImageUploaded {
    width: 90px;
    height: 90px;
    min-width: 90px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    .imageLoader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(6px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      .reloadBtn {
        width: 30px;
        height: 30px;
        cursor: pointer;
        svg > path {
          fill: #ffffff;
        }
      }

      .lineProgress {
        border-radius: 2px;
        width: 80px;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        span {
          background-color: #ffffff;
        }
      }
    }

    .removeImage {
      position: absolute;
      display: flex;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: 6px;
      width: 24px;
      height: 24px;
      right: 6px;
      border-radius: 2px;
      z-index: 1;
    }

    .image,
    .imageUploaded {
      height: 90px;
      width: 90px;
      border-radius: 12px;
      min-width: 90px;

      img {
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }
  .sliderImageUploaded {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px;
    position: relative;

    .playerIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      .videoPlayBtn {
        path {
          fill: white;
        }
      }
    }
    .imageUploaded {
      width: 100px !important;
      height: 100px !important;
      object-fit: cover;
    }
  }
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Задайте максимальную ширину вашего видео */
}

.video {
  width: 100%;
  height: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
  /* Добавьте стили для вашей иконки воспроизведения */
}
