.body {
  font-family: 'Montserrat';
  margin: 0 auto; /* Center website */
  max-width: 800px; /* Max width */
  padding: 20px;
}

.heading {
  font-size: 25px;
  margin-right: 25px;
}

.fa {
  font-size: 25px;
}

.checked {
  color: orange;
}

/* Three column layout */
.side {
  float: left;
  width: 15%;
  margin-top: 10px;
}

.middle {
  float: left;
  width: 70%;
  margin-top: 18px;
}

/* Place text to the right */
.right {
  text-align: right;
}

/* Clear floats after the columns */
.row {
  width: 500px;
  //content: "";
  display: table;
  clear: both;
}

/* The bar container */
.barContainer {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
}

/* Individual bars */
.bar5 {
  width: 60%;
  height: 10px;
  background-color: #fdd835;
  border-radius: 12px;
}
.bar4 {
  width: 30%;
  height: 10px;
  background-color: #fdd835;
  border-radius: 12px;
}
.bar3 {
  width: 10%;
  height: 10px;
  background-color: #fdd835;
  border-radius: 12px;
}
.bar2 {
  width: 4%;
  height: 10px;
  background-color: #fdd835;
  border-radius: 12px;
}
.bar1 {
  width: 15%;
  height: 10px;
  background-color: #fdd835;
  border-radius: 12px;
}

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 400px) {
  .side,
  .middle {
    width: 100%;
  }
  //   /* Hide the right column on small screens */
  //   .right {
  //     display: none;
  //   }
}
