@import '/src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 60px;
  justify-content: center;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
  margin-top: 20px;
  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }
  @media (max-width: 800px) {
    padding: 20px;
  }
  .optionsButton {
    padding: 10px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }
  .eventItem {
    padding: 20px 24px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #efefef;
    background: #ffffff;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .leftBlock {
      display: flex;
      align-items: center;

      @media (max-width: 800px) {
        width: 100%;
        img {
          width: 100% !important;
          height: fit-content !important;
        }
      }
    }
    .rightBlock {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .eventInfo {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .eventTitle {
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          @media (max-width: 800px) {
            max-width: 184px;
          }
        }
        .eventDate {
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: #2d8342;
        }
        .status {
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}

.paginationBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pagination {
    display: flex;
    justify-content: center;
  }
  .paginationItem {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 15px !important;
    background: #ffffff !important;
    border: 1px solid #efefef;
    border-radius: 12px !important;
    color: #212121 !important;
  }

  .activePaginationItem {
    border: 1px solid #143471 !important;
    border-radius: 12px !important;
  }
  .paginationText {
    margin-top: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
}
