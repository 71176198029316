@import '/src/styles/mixins';

.deliveryEditContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 12px;
  border-top: 1px solid #efefef;
  padding-top: 20px;

  @include for-mobile-max_767 {
    flex-direction: column;
    align-items: flex-start;
  }

  .infoBlock {
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #555555;
    }

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #212121;
    }
  }

  .pickupSelect {
    min-width: 260px;

    @include for-mobile-max_767 {
      min-width: 100%;
    }

    .fullSelect {
      width: 100% !important;
    }
  }

  .deliveryDate {
    max-width: 260px;
    max-height: 48px;

    @include for-mobile-max_767 {
      max-width: 100%;
      width: 100%;
    }

    .datePicker {
      @include for-mobile-max_767 {
        width: 100%;
      }
      & > div > input {
        padding: 12.5px 16px;
      }
    }
  }
}
