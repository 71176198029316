.select {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  background: white;
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.6);
  outline: none;
  width: 100%;
}

.menuItem {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin: 0 16px;
  padding-left: 0;
  display: inline-block;
}
