@import '../../../../styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding: 20px 40px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

  @include for-mobile-max_620 {
    padding: 20px;
  }

  @include for-mobile-max_767 {
    .orderStatusBlock {
      right: 40px;
    }

    .hashSign {
      width: 100%;
      padding-right: 40px;

      .orderId {
        width: 100%;
      }
    }
  }
  .titleBlock {
    display: flex;
    gap: 8px;
    align-items: center;

    .title {
      color: #212121;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;

      @include for-mobile-max_620 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }
  }

  .verifiedStarIconBlock > span > svg > path {
    fill: #2d8342;
  }

  .orderBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .headerBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      .hashSign {
        display: flex;
        gap: 12px;
        color: #143471;
        text-align: left;
        letter-spacing: 0em;
        align-items: center;
        .backBtn {
          cursor: pointer;
        }

        .text {
          font-weight: 600 !important;
          font-size: 16px;
          line-height: 24px;
        }

        .orderIdText {
          font-weight: 600 !important;
          font-size: 24px;
          line-height: 36px;
        }

        .orderId {
          word-break: break-all;
          /* text-overflow: ellipsis;
          overflow: hidden; */
        }
      }

      .orderStatusType {
        display: flex;
        gap: 20px;
        align-items: center;

        .weightInfo {
          padding: 11px 17.5px;
          border-radius: 4px;
          cursor: pointer;
          border: 1px solid red !important;
          span[role='img'] {
            height: 18px !important;
            width: 21px !important;
          }
          svg {
            height: 18px;
            width: 21px;
            path {
              fill: red;
            }
          }
        }

        .menuButton {
          padding: 0;
        }
      }

      .orderStatus {
        padding: 8px 12px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }

      .orderEditBtn {
        span:first-child {
          display: inline-grid !important;

          svg {
            height: 24px;
            width: 24px;

            g > path {
              fill: #18397a !important;
            }

            > path {
              fill: #18397a !important;
            }
          }
        }
      }
    }

    .paymentBlock {
      display: flex;
      gap: 8px;
      flex-direction: column;

      .paymentHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;

        .titleBlock {
          display: flex;
          gap: 12px;
          align-items: center;
        }

        .titleUnderline {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-decoration-line: underline;
          cursor: pointer;
        }

        .warningInfoBlock {
          display: flex;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          background-color: #efefef;
          color: #212121;
          margin: 6px 0;

          .title {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .title {
          color: #000000;
          font-weight: 500;
          font-size: 16px;

          @include for-mobile-max_620 {
            font-size: 14px !important;
          }
        }

        .paymentActions {
          display: flex;
          gap: 20px;

          @include for-tablet-landscape-max_1024 {
            gap: 16px;
          }

          @include for-mobile-max_620 {
            gap: 12px;
          }
        }
      }

      .descriptionBlock {
        width: 100%;
        display: flex;
        gap: 8px;
        flex-direction: column;
        padding-left: 36px;

        @include for-mobile-max_620 {
          padding: 0;
        }

        .descriptionItem {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 24px;
          flex-wrap: wrap;
          row-gap: 10px;

          .itemName {
            color: #555555;
            font-weight: 400;
            font-size: 16px;

            @include for-mobile-max_620 {
              font-size: 14px !important;
            }
          }

          .pickupSelect {
            min-width: 260px;

            .fullSelect {
              width: 100% !important;
            }
          }

          .deliveryDate {
            max-width: 260px;
            max-height: 48px;
          }

          .itemPrice {
            color: #000000;
            font-weight: 500;
            font-size: 16px;

            @include for-mobile-max_620 {
              font-size: 14px !important;
            }
          }
        }

        .verticalCenter {
          justify-content: flex-start;
          gap: 12px;
          padding: 0;
          @include for-mobile-max_620 {
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
          }
        }
      }
    }
  }
}

.optionsMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  ul {
    padding: 0;
  }
  .menuItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.orderDateContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .orderDate {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    @include for-mobile-max_620 {
      font-size: 14px !important;
    }
  }

  .orderDateBlock {
    display: flex;
    gap: 12px;
    align-items: center;

    .orderDateTitle {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      @include for-mobile-max_620 {
        font-size: 14px !important;
      }
    }
  }
}

.timerBlock {
  flex-wrap: wrap;
  display: flex;
  gap: 12px;
  align-items: center;

  @include for-mobile-max_620 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
}

.timerLoader {
  width: 17px !important;
  height: 17px !important;
}

.timer {
  display: flex;
  justify-content: center;
  border-radius: 12px;
  padding: 2px 12px 2px 12px;
  background: #eb793f66;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  width: fit-content;
  white-space: nowrap;
  max-width: 150px;
}
