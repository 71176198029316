@import '../../../styles/_mixins.scss';

.middleContent {
  max-width: 1020px;
  display: flex;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;
  padding: 0 0 60px 0;
  gap: 20px;

  @media only screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
}

.loadingContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  border-radius: 12px;
  background-color: #ffffff;
}

.upButton {
  position: fixed;
  bottom: 100px;
  right: 53px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: 1px solid #efefef;
  z-index: 20;
  @media screen and (max-width: 1025px) {
    bottom: 143px;
    right: 20px;
  }
  @media screen and (max-width: 375px) {
    right: 12px;
  }
}
