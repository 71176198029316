.itemWrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-left: -6px;
  padding: 6px 6px;
  transition: 0.3s;
  transition-timing-function: linear;
  max-width: 200px;
  flex-wrap: wrap;
  gap: 12px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
  }
}

.moreData {
  border: none;
  width: 28px;
  height: 28px;
  background: #efefef;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
}

.tooltipText {
  font-size: 12px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.itemContainer {
  width: 28px;
  height: 28px;

  .sdgImage {
    border-radius: 4px;
  }
}
