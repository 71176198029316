.selectCompanyAccount {
  display: flex;
  width: 1020px;
  align-items: center;
  justify-content: center;
  height: 62px;
  font-family: 'Montserrat';
  color: #18397a;
  font-size: 16px;
  font-weight: 500;
  background: white;
  border-radius: 0 0 12px 12px;
}
.createTextAccount {
  display: flex;
  width: 1020px;
  align-items: flex-end;
  justify-content: center;
  height: 62px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
  background: white;
  border-radius: 12px 12px 0 0;
}
.textInformation {
  display: flex;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  margin-left: 60px;
  padding-top: 20px;
}
.btnCancel {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 69px;
  height: 48px;
  border: none;
  color: #18397a;
  background: transparent;
}
.btnNext {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  width: 69px;
  height: 48px;
  border-radius: 6px;
  background-color: #18397a;
  color: #ffffff;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.bodyProductsCardAccount {
  max-width: 1020px;
  height: 587px;
  margin-top: 20px;
  border-radius: 12px;
  background: white;
}
.btnMassage {
  color: #18397a;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 100px;
  height: 48px;
  border: 1px solid #18397a;
  border-radius: 5px;
  margin-top: 22px;
  margin-right: 20px;
  background: transparent;
}
.btnView {
  color: #18397a;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 200px;
  height: 48px;
  border: 1px solid #18397a;
  border-radius: 5px;
  margin-top: 22px;
  background: transparent;
}
.btnSave {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  width: 179px;
  height: 48px;
  border-radius: 6px;
  background-color: #8c9dbd;
  color: #ffffff;
}
.btn {
  position: absolute;
  top: 10px;
  left: 140px;
  //transform: translate(-50%, -50%);
  //-ms-transform: translate(-50%, -50%);
  background-color: #e5e5e5c7;

  color: #d32f2f;
  :hover {
    color: #8a2be2;
  }
}
.documentsInput {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
  height: 217px;
  width: 900px;
  border-radius: 12px;
  border: 1px solid #efefef;
  margin-left: 60px;
  margin-top: 20px;
  // padding-bottom: 150px;
  padding-top: 20px;
  padding-left: 10px;
}
.documentsInput::-webkit-scrollbar {
  width: 5px;
}
