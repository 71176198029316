@import '../../../styles/_mixins.scss';

.middleContent {
  max-width: 1020px;
  display: flex;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;
  padding: 0 0 60px 0;
  gap: 20px;

  @media only screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
}

.loadingContainer,
.emptyContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  border-radius: 12px;
  background-color: #ffffff;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .subTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.noResult {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 12px;
  background-color: #ffffff;
  padding: 24px;
  .link {
    color: #1f4a9d;
    text-decoration: underline;
  }
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .subTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.emptyContainer {
  font-size: 24px;
  font-weight: 600;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .titleBlock {
    padding: 12px 20px;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

    .title {
      color: #212121;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;

      @include for-mobile-max_620 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }
  }

  .fixedBlock {
    width: 100%;
    background-color: #e5e5e5;
    &::before {
      content: '';
      position: absolute;
      background-color: transparent;
      bottom: -50px;
      height: 50px;
      width: 25px;
      border-top-left-radius: 12px;
      box-shadow: 0 -25px 0 0 #e5e5e5;
      z-index: -1;
    }
    &::after {
      content: '';
      right: 0;
      position: absolute;
      background-color: transparent;
      bottom: -50px;
      height: 50px;
      width: 25px;
      border-top-right-radius: 12px;
      box-shadow: 0 -25px 0 0 #e5e5e5;
      z-index: -1;
    }
    .titleBlock {
      padding: 12px 20px;
      border-radius: 12px;
      background: #ffffff;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

      .title {
        color: #212121;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;

        @include for-mobile-max_620 {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.16px;
        }
      }
    }
  }

  .cartListContainer {
    width: 100%;
    display: flex;
    gap: 20px;

    @media (max-width: 1170px) {
      gap: 16px;
      flex-direction: column;
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.orderActionGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  .orderAction {
    display: flex;
    gap: 20px;

    .buttonItem {
      display: flex;
      gap: 12px;
      align-items: center;
      white-space: nowrap;
      img {
        filter: brightness(0) saturate(100%) invert(15%) sepia(64%) saturate(2517%)
          hue-rotate(210deg) brightness(90%) contrast(91%);
      }
    }

    .payButton {
      padding: 10px 110px !important;
      @include for-mobile-max_620 {
        padding: 10px 43px !important;
      }
    }

    @include for-mobile-max_620 {
      width: 100%;
      justify-content: space-between;
    }

    .transparentButton {
      background-color: transparent !important;
      @include for-mobile-max_620 {
        font-size: 12px !important;
      }
    }
  }
}
