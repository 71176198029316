@import '/src/styles/mixins';

.itemContainer {
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    cursor: pointer;

    .menuButton {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      padding: 0;
      width: 24px;
      height: 24px;
    }
    .orderNumber {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: #143471;
      word-wrap: break-word;
      margin-right: 15px;
    }

    .statusAndOptions {
      display: flex;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;
      row-gap: 8px;

      .statusInfoBtn {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        text-decoration-line: underline;
        padding: 0 !important;

        span {
          display: none;
        }
      }

      .status {
        padding: 5px 12px;
        border-radius: 12px;
        color: #fff;
        margin-right: 40px;
        line-height: 24px;
      }
    }
  }

  .orderOptions,
  .orderServiceOptions {
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
    row-gap: 8px;
    flex-wrap: wrap;

    .optionColumn {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .optionRow {
        display: flex;
        align-items: center;
        gap: 8px;

        .optionTitle,
        .optionTitleGreen,
        .optionTitleBold {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          word-wrap: break-word;
          margin-right: 15px;
        }
        .optionTitleGreen {
          color: #4caf50;
        }

        .optionTitleBold,
        .optionValueBold {
          font-weight: 600 !important;
        }

        .optionValue,
        .optionValueGreen,
        .optionValueBold {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .optionValueGreen {
          color: #4caf50;
        }

        .timerBlock {
          flex-wrap: wrap;
          display: flex;
          gap: 12px;
          align-items: center;

          @include for-mobile-max_620 {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
          }
        }

        .warningInfoBlock {
          display: flex;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          background-color: #efefef;
          color: #212121;
          margin: 6px 0;

          .title {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }

  .orderServiceOptions {
    justify-content: space-between;
    align-items: center;
    .columns {
      display: flex;
      column-gap: 40px;
      flex-wrap: wrap;
    }
  }
  .scrollContainer::-webkit-scrollbar {
    height: 5px;
  }
  .scrollContainer::-webkit-scrollbar-track {
    background-color: #d4d4d4;
  }
  .scrollContainer::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 8px;
  }

  .slider,
  .sliderService {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex-wrap: nowrap;
    padding-bottom: 12px;

    .sliderImage {
      width: 90px;
      height: 90px;
      min-width: 90px;
      border-radius: 12px;
      overflow: hidden;

      .image {
        height: 90px;
        width: 90px;
        border-radius: 12px;
        min-width: 90px;

        img {
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }
  }
  .sliderService {
    padding-bottom: unset;
  }
  .productImages {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 110px;
    overflow-x: auto;
  }

  .productImages::-webkit-scrollbar {
    height: 5px;
  }
  .productImages::-webkit-scrollbar-track {
    background-color: #d4d4d4;
  }
  .productImages::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 8px;
  }

  .orderProductData,
  .orderServiceData {
    width: 100%;
    border-top: 1px solid #efefef;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .orderServiceData {
    width: unset;
    border-top: unset;
    padding-top: unset;
  }

  .companyProductTotal {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #efefef;
    padding-top: 20px;

    @include for-mobile-max_767 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .totalItem {
      max-width: 158px;

      @include for-mobile-max_767 {
        max-width: 100% !important;
        width: 100%;

        div {
          min-width: 100%;
        }
      }

      .endAdornment {
        min-width: fit-content;
        z-index: 9;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #212121;
        padding-right: 5px;
        right: 5px;
        position: absolute;
      }
    }

    .feeItem {
      max-width: 84px !important;

      @include for-mobile-max_767 {
        max-width: 100% !important;
      }
    }
  }
}

.optionsMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  ul {
    padding: 0;
  }
  .menuItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
