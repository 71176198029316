.container {
  display: flex;
  width: 880px;
  height: 630px;
  background-color: white;
  align-content: center;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  text-align: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dropdown {
  position: absolute;
  z-index: 3231;
  background-color: white;
  border: 1px solid grey;
}
.image {
  cursor: pointer;
}
