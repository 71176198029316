.goalBlock {
  max-width: 350px;
  .goalTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  .goalName {
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
  }
  .goalText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 10px;
  }
  .description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    word-break: break-all;
  }
}

@media screen and (max-width: 630px) {
  .goalBlock {
    max-width: 100%;
  }
}
