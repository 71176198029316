.createBusinessAccountText {
  display: flex;
  width: 1020px;
  align-items: flex-end;
  justify-content: center;
  height: 62px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
  background: white;
  border-radius: 12px 12px 0 0;
}
.selectCompany {
  display: flex;
  width: 1020px;
  align-items: center;
  justify-content: center;
  height: 62px;
  font-family: 'Montserrat';
  color: #18397a;
  font-size: 16px;
  font-weight: 500;
  background: white;
  border-radius: 0 0 12px 12px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.btnCancel {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 69px;
  height: 48px;
  border: none;
  color: #18397a;
  background: transparent;
}
.btnNext {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  width: 69px;
  height: 48px;
  border-radius: 6px;
  background-color: #18397a;
  color: #ffffff;
}
.bodyText {
  display: flex;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  margin-left: 60px;
  padding-top: 20px;
  margin-top: 10px;
}
.adminsText {
  display: flex;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  margin-left: 60px;
  padding-top: 10px;
}
.adminsBody {
  position: absolute;
  max-width: 1020px;
  height: 100%;
  margin-top: 20px;
  border-radius: 12px;
  background: white;
}
.adminsBtn {
  display: flex;
  margin-top: 20px;
  border: 1px solid #1976d2;
  width: 116px;
  padding-top: 10px;
  padding-left: 10px;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat';
  color: #1976d2;
}
.btnAddManager {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat';
  margin-left: 10px;
}
.imgPng {
  padding-top: 10px;
  padding-left: 20px;
  color: #1976d2;
}
.bigBtnAdd {
  display: flex;
  margin-top: 20px;
  border: 1px solid #1976d2;
  width: 200px;
  margin-right: 62px;
}
