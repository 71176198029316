.searchTestBox {
  height: 40px;
  width: 700px;
  background-color: #f9f9f9;
  //margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 12px;

  //   @media (max-width: 760px) {
  //     width: 118px;
  //     margin-right: 15px;
  //   }
}
.searchTest {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  height: 50px;
  width: 672px;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 16px;
  padding-left: 12px;
}
.searchTestInput {
  width: 256px;
  height: 48px;
  padding-left: 60px;
  border-radius: 12px;
  font-family: 'Montserrat';
  background-color: #f9f9f9;
  border: #f9f9f9;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}
