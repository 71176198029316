@import '../account.module';

.container {
  .accountLogoContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 900px) {
      display: block;
    }

    @media (max-width: 1024px) {
      column-gap: 30px;
    }
  }
}
.accountLogoInputMini {
  margin-top: 0 !important;
  @media (max-width: 900px) {
    margin-top: 15px !important;
  }
}
.accountColumnButtomBlock {
  margin-bottom: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.accountColumn {
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 20px;

  @media (max-width: 900px) {
    gap: 0;
  }

  .accountLogoInputContainer {
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);

    @media (max-width: 900px) {
      gap: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

.accountInputsRow {
  width: 100%;
}
