.btn {
  position: absolute;
  bottom: 0px;
  left: 13px;
  border-radius: 10px;
  //margin-top: 50px;
  //margin-left: 17px;
  font-size: 12px;
  border: none;
  color: #d15f1f;
  z-index: 1234;
}
