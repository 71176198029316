@import '/src/styles/mixins';

.requestCustomizationModal {
  position: relative;
  width: 100%;

  h3 {
    @include for-mobile-max_620 {
      font-size: 16px !important;
    }
  }

  .resizeIcon {
    position: absolute;
    right: 80px;
    top: 23px;
    @include for-mobile-max_620 {
      right: 50px;
      top: 17px;
    }
    svg {
      @include for-mobile-max_620 {
        height: 16px !important;
        width: 16px !important;
      }
    }
  }

  .editIcon {
    position: absolute;
    right: 120px;
    top: 23px;
    @include for-mobile-max_620 {
      right: 50px;
      top: 17px;
    }
    svg {
      @include for-mobile-max_620 {
        height: 16px !important;
        width: 16px !important;
      }
    }
  }

  @include for-mobile-max_620 {
    padding: 24px 20px !important;
    font-size: 16px !important;
  }

  .description {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #212121;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    .addInfoBtn {
      display: flex;
      gap: 12px;
      padding: 0 !important;

      span {
        display: none;
      }
      .addInfoText {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
      }
    }

    @include for-mobile-max_620 {
      gap: 20px;
      margin-top: 20px;
    }

    .formData {
      display: flex;
      flex-direction: column;

      .forDataTitle {
        display: flex;
        flex-wrap: nowrap;

        .startDate {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #212121;
        }

        .dateOfPayment {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #212121;
        }
      }
    }

    .perfomanceOfServiceBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 23px;
      margin: 0;

      @include for-mobile-max_620 {
        flex-direction: column;
        gap: 12px;
        align-items: start;
      }

      .perfomanceOfService {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        color: #212121;

        .periodValue {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          text-align: right;
        }

        .dateItems {
          font-size: 12px !important;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }
    }

    .inputUnitText {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
    }
  }

  .inputSelect,
  .inputSelectEdit,
  .datePicker {
    width: 100%;
    margin: 0;

    @media (max-width: 900px) {
      max-width: 100%;
      width: 100%;
    }

    &[data-type='cost'] {
      cursor: pointer !important;
    }

    &[data-type='cost'] > label[data-shrink='false'] {
      transform: translate(20px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
        max-width: calc(100% - 100px);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .inputSelectEdit {
    width: unset !important;
  }

  .milestoneFields {
    display: flex;
    align-items: center;
    gap: 20px;

    @include for-mobile-max_620 {
      flex-direction: column;
      align-items: start;
      gap: 12px;
    }

    .milestoneLabel {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      color: #212121;
    }
  }

  .formButtons {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include for-mobile-max_620 {
      .formButton {
        font-size: 12px !important;
        font-weight: 600 !important;
      }
    }
  }

  .differentNotice {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}
.fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
}

.textarea {
  width: 100%;
  min-height: 72px;
  margin: 0 !important;

  textarea {
    min-height: 72px !important;
  }
}
:has(.fullscreen) {
  max-width: 100% !important;
  margin: 20px;
}
.infoText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.changesContent {
  overflow-y: auto;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  gap: 24px;
  border: 1px solid #eee0e0;
  border-radius: 12px;
  padding: 8px 12px;

  .contentTitle {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .subTitle {
    font-size: 10px;
    font-weight: 500;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .textBold {
    font-weight: 500;
  }
  .textWithBg,
  .textWithBgRed {
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000;
    background-color: #ffd54233;

    .blockWithoutBg {
      background-color: unset !important;
    }
  }
  .textWithBgRed {
    background-color: #ff584233 !important;
  }
}
.arrowRight {
  transform: rotate(90deg);
}
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #efefef;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}
.editModeText {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.editModeNameBox {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
