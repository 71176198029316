@import '/src/styles/mixins';

.modalContainer {
  width: 100% !important;
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .dataRow {
      display: flex;
      align-items: flex-start;
      gap: 24px;

      .title {
        min-width: 120px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .info {
        display: flex;
        gap: 8px;

        span {
          display: inline-grid !important;
          cursor: pointer;

          svg {
            height: 24px;
            width: 24px;

            g > path {
              fill: #000000 !important;
            }

            > path {
              fill: #000000 !important;
            }
          }
        }

        .text {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          word-break: break-word;
        }
      }
    }
  }

  .buttonBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.modalContainer::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.modalContainer::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.modalContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}
