@import '../../../../styles/mixins';

.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 12px;
  background: #ffffff;

  .paymentTypes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    .paymentType {
      display: flex;
      align-items: center;
      padding: 0 20px;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid #5e56f2;
      cursor: pointer;

      .name {
        color: #5e56f2;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
      }
    }

    .paymentTypeDisabled {
      border: 1px solid #efefef;
      cursor: not-allowed;

      .name {
        color: #efefef;
      }
    }
  }

  .description {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .cardsBlock {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-column-gap: 6px;
    grid-row-gap: 6px;

    .addCardBtn {
      white-space: nowrap;
      display: flex;
      gap: 12px;
      align-items: center;

      svg > path {
        fill: #143471;
      }
    }
  }
  .addCardBtn {
    white-space: nowrap;
    display: flex;
    gap: 12px;
    align-items: center;

    svg > path {
      fill: #ffffff;
    }
  }
}
