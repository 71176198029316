.accordionContainer {
  padding: 12px 20px;
  border-radius: 12px;
  background: #ffffff;
  border: none;
  box-shadow: none;

  &:before {
    opacity: 0;
  }

  .tableCell {
    border: none;
    padding: 6px;

    .date {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    .ownerInfo {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 !important;
      position: relative;

      .ownerName {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .kybFlag {
        border-radius: 8px;
        background-color: #4caf50;
        padding: 2.5px;
        font-family: 'Montserrat';
        font-size: 6px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #ffffff;
        width: fit-content;
        margin-bottom: 26px;
        margin-top: 0;
      }
    }

    .viewButton {
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-decoration-line: underline;
      color: #143471 !important;
      white-space: nowrap;
      padding: 0 !important;
    }
  }

  .accordionTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #212121;
  }
}
