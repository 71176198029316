@import '/src/styles/mixins';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.08);
  padding: 24px 40px;
  border-radius: 12px;

  @include for-tablet-landscape-max_1024 {
    width: 70%;
  }

  @include for-mobile-max_767 {
    width: 90% !important ;
  }

  .modalTitle {
    margin-bottom: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #424242;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .input {
      width: 100%;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      height: 48px;
      padding-left: 12px;
      background: white;
      border-radius: 12px;
      border: 1px solid #efefef;
    }

    .modal_input {
      position: relative;
      width: 100%;

      @include for-mobile-max_767 {
        // width: 90% !important ;
      }

      &[data-type='location'] {
        cursor: pointer !important;
      }

      &[data-type='location'] > label[data-shrink='false'] {
        transform: translate(56px, 14px) scale(1) !important;
      }

      & > label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;

        &[data-shrink='false'] {
          transform: translate(14px, 14px) scale(1);
        }
      }

      & > div {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #212121;
      }

      & > div > input {
        padding: 12.5px 12px;
      }

      & > div > fieldset {
        border-radius: 12px;
        border: 1px solid #efefef;

        & > legend {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .textarea {
      width: 100%;
      max-width: 100%;
      height: 72px;
      padding-left: 20px;
      padding-top: 12px;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      background: white;
      border-radius: 12px;
      border: 1px solid #efefef;
    }
  }

  .buttonBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    button {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 48px;
      text-transform: uppercase;
      color: #18397a;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .backgroundButton {
      background-color: #18397a;
      border-radius: 4px;
      padding: 0 20px;
      color: #ffffff;
    }
  }
}
