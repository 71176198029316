@import '/src/styles/mixins';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.08);
  padding: 24px 40px;
  border-radius: 12px;
  min-width: 327px;
  max-width: 610px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .modalTitle {
    width: 100%;
    justify-content: space-between;
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #424242;
    gap: 14px;

    @include for-mobile-max_767 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    .closeIcon {
      height: fit-content;
      padding: 0;
    }
  }

  .contentTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #212121;

    @include for-mobile-max_767 {
      font-weight: 400;
    }
  }

  .radioItem {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin: 0;
  }

  .buttonBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anotherInput {
    margin-top: 16px;
    width: 100%;
  }
}
