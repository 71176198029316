.createText {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  padding: 24px 0px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  max-width: 1020px;

  h3.title {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #555555;
  }

  h4.subTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #18397a;
    text-align: center;
    padding: 10px 0 0 0;
  }

  @media (max-width: 1024px) {
    h3.title {
      font-size: 20px;
      line-height: 32px;
    }
  }

  @media (max-width: 700px) {
    padding: 12px 0px;

    h3.title {
      font-size: 16px;
      line-height: 24px;
    }

    h4.subTitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.buttonCancel {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  width: 69px;
  height: 48px;
  border: none;
  color: #18397a;
  background: transparent;
  transition: opacity 0.1s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:disabled {
    opacity: 0.5;
  }
}

.buttonNext,
.buttonNextLast,
.buttonNextLastMobile {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: #18397a;
  color: #ffffff;
  transition: opacity 0.1s ease-in;
  cursor: pointer;
  border: none;
  text-transform: uppercase;

  @media (max-width: 460px) {
    padding: 0 7.5px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 40px;
  }

  &:hover {
    opacity: 0.9;
  }
}

.buttonNextLast {
  @media (max-width: 710px) {
    display: none;
  }
}

.buttonNextLastMobile {
  display: none;
  @media (max-width: 710px) {
    display: block;
  }
}

.buttonExit {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 1px #18397a;
  color: #18397a;
  transition: opacity 0.1s ease-in;
  cursor: pointer;
  text-transform: uppercase;

  @media (max-width: 460px) {
    padding: 0 7.5px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 40px;
  }

  &:hover {
    opacity: 0.9;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin-top: 4px;

  .lastStepButtons {
    display: flex;
    align-items: center;
    gap: 20px;

    .buttonDraft {
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid #18397a;
      padding: 0 20px;
      color: #18397a;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      text-transform: uppercase;
      cursor: pointer;

      @media (max-width: 460px) {
        padding: 0 7.5px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 40px;
      }
    }
  }
}

.form {
  max-width: 1020px;
}
