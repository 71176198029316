.dropdownContainer {
  position: relative;

  .dropdownInput {
    background: #ffffff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px !important;
    cursor: pointer;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #18397a;

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;

      @media screen and (max-width: 390px) {
        width: 280px;
        font-size: 14px;
      }
      @media screen and (max-width: 345px) {
        font-size: 13px;
      }
    }
  }

  .dropdownOptions {
    width: 100%;
    position: absolute;
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    top: 48px;
    z-index: 1000;

    .dropdownOptionBlock {
      padding: 8px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dropdownOptionName {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }
    .dropdownChildOptionName {
      padding: 8px 20px 8px 40px;
      cursor: pointer;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }
  }
}
