@import '/src/styles/mixins';

.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin-top: 20px;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
    margin-top: 12px;
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }

  .viewAllBlock {
    display: flex;
    align-items: center;
    column-gap: 8.15px;
    justify-content: flex-end;
    cursor: pointer;
    color: #143471;

    .viewAllText {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.scrollContainer {
  .productContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    padding-bottom: 14px;

    .productItem {
      min-width: 200px;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .rightSlide,
  .leftSlide {
    height: 40px;
    width: 40px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: 0;
    margin: 0 auto;
  }

  .leftSlide {
    left: 40px;
    display: none;
  }
  .rightSlide {
    right: 40px;
    display: none;
  }
}
