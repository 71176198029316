@import '../../../styles/mixins';

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;

  @include for-mobile-max_767 {
    gap: 12px;
  }

  .filterBlock {
    display: flex;
    align-items: center;
    gap: 20px;

    @include for-mobile-max_767 {
      flex-direction: column;
      gap: 12px;
    }

    .searchFilter {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
      width: 100%;
      gap: 12px;
      padding: 7px 20px;
      div {
        border-radius: 0 !important;
        border: 0 !important;
      }
      div:hover {
        box-shadow: none !important;
      }
    }

    .addBtn {
      white-space: nowrap;
      width: 100%;
      padding: 8px 20px !important;
      @include for-mobile-max_767 {
        min-width: 100%;
        font-size: 12px !important;
      }
    }
  }

  .companyActions {
    display: flex;
    align-items: center;
    gap: 20px;
    @include for-mobile-max_767 {
      flex-direction: column;
      min-width: 100% !important;
    }

    .joinBtn {
      white-space: nowrap;
      width: 100%;
      padding: 8px 20px !important;
      @include for-mobile-max_767 {
        min-width: 100%;
      }
    }

    .connectStripeButton {
      white-space: nowrap;
      width: 100%;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      color: #5e56f2;
      border: 1px solid #5e56f2;
      border-radius: 4px;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: transparent;
      cursor: pointer;

      @media (max-width: 700px) {
        margin-top: 12px;
      }

      svg {
        fill: currentColor;
        color: #5e56f2;
      }

      &:disabled {
        border: 1px solid #999999;
        color: #666666;
        cursor: no-drop;

        .iconWrapper {
          svg {
            color: #666666;
          }
        }
      }
    }

    .connectedStripeButton {
      white-space: nowrap;
      width: 100%;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      color: #4caf50;
      border: 1px solid #4caf50;
      border-radius: 4px;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: transparent;
      cursor: pointer;

      @media (max-width: 700px) {
        margin-top: 12px;
      }

      svg {
        fill: currentColor;
        color: #4caf50;
      }

      &:disabled {
        border: 1px solid #999999;
        color: #666666;
        cursor: no-drop;

        .iconWrapper {
          svg {
            color: #666666;
          }
        }
      }
    }
  }

  .list {
    width: 100%;
    flex: 1;
    margin: 0;

    .listItem {
      width: 100%;
      background: #ffffff;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      max-width: none;
      display: flex;
      flex-basis: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      margin-bottom: 12px;
      cursor: pointer;
      gap: 24px;

      @include for-desktop-up-max_1200 {
        padding: 16px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
    }

    .listItemBtn {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      border-color: #18397a;
      color: #18397a;

      @include for-mobile-max_480 {
        font-size: 12px;
        width: 90px;
        flex-shrink: 0;
        padding: 5px !important;
      }
    }

    .listItemNameContent {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .listItemName {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #212121;

      @include for-mobile-max_480 {
        line-height: 20px;
      }
    }
  }

  .paginationBlock {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pagination {
      display: flex;
      justify-content: center;
    }

    .paginationItem {
      font-family: 'Montserrat' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      padding: 8px 15px !important;
      background: #ffffff !important;
      border: 1px solid #efefef;
      border-radius: 12px !important;
      color: #212121 !important;
    }

    .activePaginationItem {
      border: 1px solid #143471 !important;
      border-radius: 12px !important;
    }

    .paginationText {
      margin-top: 4px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #757575;
    }
  }
}

.loadingBox {
  display: flex;
  justify-content: center;
  width: 100%;
}
