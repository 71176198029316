@import '/src/styles/mixins';

.container {
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    padding: 24px 40px;
  }
  .subtitle {
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .pageTitle {
    color: #212121;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    margin-bottom: 20px;
  }

  .imagesBlock {
    border: 1px solid #efefef;
    border-radius: 12px;
    padding: 20px;

    .imagesDropContainer {
      display: flex;
      align-items: center;
      cursor: pointer;

      @include for-mobile-max_620 {
        flex-direction: column;
        align-items: flex-start;
      }

      .iconContainer {
        padding: 20px 28px 20px 20px;
        border-right: 1px solid #efefef;

        @include for-mobile-max_620 {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 20px 20px 28px 20px;
          border-right: none;
          border-bottom: 1px solid #efefef;
        }
      }

      .imagesBlockText {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        margin-left: 8px;

        @include for-mobile-max_620 {
          margin-left: 0;
          margin-top: 8px;
        }

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212121;

        .imagesBlockTitle {
          font-weight: 600;
        }

        .imagesBlockDescription {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    .imagesList {
      display: flex;
      row-gap: 16px;
      column-gap: 16px;
      flex-wrap: wrap;
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid #efefef;

      .image {
        width: 156px;
        height: 156px;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        .imageLoader {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(6px);
          display: flex;
          justify-content: center;
          align-items: center;
          .reloadBtn {
            width: 30px;
            height: 30px;
            cursor: pointer;
            svg > path {
              fill: #ffffff;
            }
          }

          .lineProgress {
            border-radius: 2px;
            width: 80px;
            height: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            span {
              background-color: #ffffff;
            }
          }
        }

        .removeImage {
          position: absolute;
          display: flex;
          background: rgba(0, 0, 0, 0.4);
          backdrop-filter: blur(2px);
          justify-content: center;
          align-items: center;
          cursor: pointer;
          top: 6px;
          width: 24px;
          height: 24px;
          right: 6px;
          border-radius: 2px;
          z-index: 1;
        }
        @include for-mobile-max_767 {
          width: 110px;
          height: 110px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.title {
  display: flex;
  column-gap: 9.87px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #212121;
}

.input {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  padding-left: 12px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  margin-top: 12px;
}

.range {
  width: calc(50% - 10px);

  @media (max-width: 900px) {
    width: 100%;
  }
}

.modal_input,
.supplierInput {
  position: relative;
  width: 100%;
  margin-top: 12px;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.supplierInput {
  margin-top: 0 !important;
}

.textarea {
  width: 100% !important;
  height: 72px !important;
  padding-left: 20px !important;
  padding-top: 12px !important;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  background: white;
  border-radius: 12px;
  border: 1px solid #efefef;
  margin-top: 12px;
  outline: none;
}

.selectContainer {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  margin-top: 12px;

  .select {
    width: 100%;
    display: flex;
    column-gap: 9.87px;
  }

  @include for-mobile-max_767 {
    flex-direction: column;
    margin-top: 0;

    .select {
      margin-top: 12px;
    }
  }
}
.buttonGroups {
  display: flex;
  column-gap: 13.87px;
  align-items: center;
  margin-top: 20px;

  @include for-mobile-max_620 {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    margin-top: 16px;
  }
}
.buttonContainer {
  display: flex;
  column-gap: 9.87px;
}
.manageGroups {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #143471;
  cursor: pointer;

  @include for-mobile-max_620 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.suppliersList {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 20px;
  margin-top: 12px;

  .suppliersContainer {
    border: 1px solid #efefef;
    border-radius: 12px;
    padding: 12px 20px;

    .supplierItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .supplierItemLeftBlock {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .avatar {
          border-radius: 24px;
          overflow: hidden;
        }

        .supplierInfo {
          display: flex;
          flex-direction: column;

          .supplierName {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
          }

          .supplierEmail {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
          }
        }
      }
      .buttonsBlock {
        display: flex;
        align-items: center;
        column-gap: 20px;

        .editButton {
          padding: 16.77px 24.77px 17.57px 25.56px;
          border: 1px solid #18397a;
          border-radius: 4px;
        }
        .deleteButton {
          padding: 13.5px 23px 15px 23px;
          border: 1px solid #18397a;
          border-radius: 4px;
        }
      }
    }
  }
}

.outlineButton {
  background-color: transparent;
  border: 1px solid #18397a;
  border-radius: 4px;
  padding: 0 20px;
  cursor: pointer;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #18397a;

  @media (max-width: 900px) {
    font-size: 12px;
  }
}

.deleteOutlineButton {
  background-color: transparent;
  border: 1px solid #18397a;
  border-radius: 4px;
  padding: 0 20px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  p {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;

    @media (max-width: 900px) {
      font-size: 12px;
    }
  }
}

.checkboxesList {
  display: flex;
  flex-direction: row;
  margin-top: 22px;

  @include for-mobile-max_767 {
    flex-direction: column;
  }

  label {
    width: 50%;
    margin-right: 0;

    @include for-mobile-max_767 {
      width: 100%;
    }
  }

  .checkboxBlock {
    display: flex;
    column-gap: 9.87px;

    .checkboxLabel {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #212121;
    }
  }
}

.addSupplierBlock {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 40px;

  @include for-mobile-max_767 {
    flex-direction: column;
    align-items: flex-start;
  }

  .button {
    border: none;
    background-color: transparent;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
    white-space: nowrap;
    cursor: pointer;

    @include for-mobile-max_767 {
      margin-top: 12px;
    }
  }
}

.dropZoneBlock {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid #efefef;
}

.docViewBlock {
  padding-top: 12px;
  border-top: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .docItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .docNameBlock {
      display: flex;
      gap: 8px;
      align-items: center;

      .docImg {
        display: flex;
        align-items: center;
        svg > path {
          fill: #4caf50;
        }
      }

      .docName {
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        max-width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 900px) {
          width: 134px;
        }
      }
    }

    .docActions {
      display: flex;
      gap: 12px;
      align-items: center;

      .fileSize {
        font-family: 'Montserrat';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #757575;
      }
      .lockOpenIcon {
        margin-left: 12px;
        display: flex;
        align-items: center;
      }
      .removeFile {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}

.radioBlock {
  display: flex;
  align-items: center;
  column-gap: 10.25px;
}

.accordionContainer {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px !important;
  padding: 12px 20px;
  margin-top: 20px !important;
  overflow: hidden !important;

  &:before {
    display: none;
  }

  .selectInputGroups {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 12px;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;
    margin-top: 20px;
    .topInputsAndWarning {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;
    }
    .warningBlock {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .warningText {
      font-family: Montserrat;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .optionSelect,
    .optionCheckbox {
      width: calc(50% - 10px);

      @media (max-width: 900px) {
        max-width: 100%;
        width: 100%;
      }

      input {
        margin-top: 0;
      }
    }

    .datePickerContainer {
      width: calc(50% - 10px);

      > div {
        width: 100% !important;
        padding: 0 !important;
      }

      @media (max-width: 900px) {
        max-width: 100%;
        width: 100%;
      }
    }

    .inputRows {
      display: flex;
      column-gap: 20px;
      row-gap: 12px;
      align-items: stretch;
      @media (max-width: 900px) {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .costInput {
    width: calc(50% - 10px);

    @media (max-width: 900px) {
      max-width: 100%;
      width: 100%;
    }

    &[data-type='cost'] {
      cursor: pointer !important;
    }

    &[data-type='cost'] > label[data-shrink='false'] {
      transform: translate(20px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
        max-width: calc(100% - 115px);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #efefef;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .productCarbonGroups {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #efefef;

    .subTitle {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #212121;
    }

    .productCarbonItem {
      display: flex;
      align-items: center;
      column-gap: 20px;

      @media (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 12px;
      }

      input,
      .costInput,
      .modal_input {
        margin: 0;
        max-width: 273px;

        @media (max-width: 900px) {
          max-width: 100%;
        }
      }
    }
  }

  .packingSizeContainer {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .packingSizeBLock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;

      @media (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 12px;
      }

      input,
      .costInput {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.deliveryCheckboxesList {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #efefef;
  padding-bottom: 20px;
  margin-bottom: 20px;
  row-gap: 10px;
  margin-top: 15px;

  .checkboxBlock {
    display: flex;
    margin-left: 9.87px;

    .checkboxLabel {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #212121;
    }
  }
}

.errorText {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
}

.switchLabel {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.costsContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-direction: column;

  .costBlock {
    display: flex;
    align-items: center;
    column-gap: 48px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .costBlockItem {
      display: flex;
      flex-direction: column;

      @media (max-width: 1600px) and (min-width: 1025px) {
        width: 100%;
      }

      @media (max-width: 950px) {
        width: 100%;
      }

      .costItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;

        @media (max-width: 1600px) and (min-width: 1025px) {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          row-gap: 8px;
        }

        @media (max-width: 950px) {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          row-gap: 8px;
        }

        .costLabel {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #212121;
          white-space: nowrap;
        }

        .costInput {
          max-width: 193px;
          min-width: 168px;
          @media (max-width: 1600px) and (min-width: 1025px) {
            max-width: 100%;
            width: 100%;
          }

          @media (max-width: 950px) {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

.carbonCalculate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding-bottom: 20px;
  margin-bottom: 20px;
  .text {
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    span {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.buttonAddOption,
.calcBtn {
  background: #18397a;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  padding: 0 20px;
  margin-top: 20px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 1050px) {
    margin-top: 16px;
    font-size: 12px;
  }
}

.calcBtn {
  display: flex;
  align-items: center;
  margin-top: 0;
  gap: 12px;
}
.inputUnitText {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #212121;
}
.disabledLabel {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #c4c4c4 !important;
}
