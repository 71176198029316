.content {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px;
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #555555;
  @media screen and (max-width: 470px) {
    text-align: start;
  }
}

.type {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.typesArea {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 65px;
}

.rightButton {
  margin-top: 20px;
  padding: 0px 20px;
  background: #18397a;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #ffffff;
}

.optionsList {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  .helpIcon {
    display: inline-block;
    height: 16.3px;
    width: 16.3px;
    cursor: pointer;
  }

  .listItem {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    .typesExplanationContentTypeName {
      font-weight: 600;
    }
  }
  .listItem:before {
    content: '•';
    font-size: 10pt;
    margin: 0 10px 0 0;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
