@import '/src/styles/mixins';

.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  overflow-y: scroll;
  overflow-x: hidden;
  //scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }

  @media only screen and (max-height: 740px) {
    overflow: scroll !important;
  }
}

.headerWrapper {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.breadcrumb {
  background-color: #e5e5e5;
}

.pageContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  padding-top: 16px;
  width: 100%;
  //height: fit-content !important;
  height: 100%;
  margin: 0 auto;
  //overflow: hidden;
  position: relative;
  max-width: 1650px;

  @media screen and (max-width: 1690px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: 1150px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @include for-mobile-max_767 {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }

  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}

.middleContent {
  max-width: 1020px;
  width: 100%;
  min-width: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px !important;
  margin-right: 16px !important;
  overflow-y: hidden;

  @media only screen and (max-width: 1025px) {
    margin: 0 !important;
  }

  @media only screen and (min-width: 1025px) {
    min-width: 515px;
  }

  @include for-tablet-landscape-max_1024 {
    width: 100%;
    padding-bottom: 40px;
  }
}

.wrapperContent {
  max-width: 1020px;
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.leftColumn {
  position: sticky;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.rightColumn {
  position: sticky;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.mobileNavigation {
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}
