@import '../../../../../styles/mixins';

.dataForm {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  gap: 16px;
}

.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .addInfoBtn {
    display: flex;
    gap: 12px;
    padding: 0 !important;

    span {
      display: none;
    }
    .addInfoText {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      text-transform: uppercase;
    }
  }

  .contactTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  > h2 {
    font-size: 16px;
    font-weight: 600;
  }
}

.dataFormFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dataFormRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;

  @include for-mobile-max_480 {
    flex-direction: column;
  }
  .input {
    width: 100%;
  }
}
.phoneInput {
  width: calc(50% - 5px);
  @include for-mobile-max_480 {
    width: 100% !important;
  }
}

.textarea {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-style: normal;
    font-weight: 400;
    background-color: #ffffff;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 12px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.infos {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  .infoText {
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
