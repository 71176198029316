.contactsBlock {
  border: 1px solid #efefef;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .contactsHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 8px;

    .contactInfos,
    .contactInfosExpanded {
      display: flex;
      column-gap: 20px;
      row-gap: 8px;
      align-items: center;
      flex-wrap: wrap;

      .title {
        display: flex;
        gap: 12px;
        align-items: center;

        .text {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: #000000;
        }
        svg {
          height: 24px;
          width: 24px;
        }
      }

      .userInfos {
        display: flex;
        gap: 20px;
        row-gap: 8px;
        align-items: center;
        flex-wrap: wrap;

        .infoItem {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .contactInfosExpanded {
      flex-direction: column;
      column-gap: 20px;
      row-gap: 8px;
      align-items: flex-start;
      .userInfos {
        @media screen and (min-width: 1100px) {
          margin-left: 34px;
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 12px;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;

        span:first-child {
          display: inline-grid !important;

          svg {
            height: 24px;
            width: 24px;

            g > path {
              fill: #18397a !important;
            }

            > path {
              fill: #18397a !important;
            }
          }
        }
      }
    }
  }

  .accordionContainer {
    width: 100%;
    background-color: #ffffff;
    overflow: hidden !important;
    border-radius: 0 !important;
    box-shadow: unset !important;
    margin: 0 !important;

    &:before {
      display: none;
    }

    .accordionSummary {
      max-width: 120px;

      .accordionTitle {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
